import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, Pipe, PipeTransform, Injector } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import sortBy from 'lodash-es/sortBy';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { DateAdapter, MAT_DATE_FORMATS, MatDialog } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../issue-search/date.adapter';
import { IssueTimerAddComponent } from './issue-timer-add/issue-timer-add.component';
import { fadeOutAnimation } from '../../../../../@fury/animations/route.animation';
import { AppService } from '../../../../services/app.service';
import { IssueService } from '../../../../services/issue.service';



@Component({
  selector: 'flowi-issue-timer',
  templateUrl: './issue-timer.component.html',
  styleUrls: ['./issue-timer.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ],
  animations: [fadeOutAnimation],
  host: { '[@fadeOutAnimation]': 'true' }
})
export class IssueTimerComponent implements OnInit, AfterViewInit {
  formtimer: FormGroup;
  countUp: any;
  timer: any;
  counter = 90427;
  tick = 1000;
  lbltimer: any;
  interval: any;
  started: boolean;
  

  constructor(private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private fb: FormBuilder,
      private injector: Injector,
    private appService: AppService,
    private readonly issueService: IssueService) {
  }

  ngOnInit() {
    this.formtimer = this.fb.group({
    });
   
      }

  start() {
    this.interval = setInterval(() => {
      ++this.counter; this.calc(); this.started = true;
    }, 1000);
  }
  stop() {
    clearInterval(this.interval);
    this.started = false;
  }

  calc() {
    const day: number = Math.floor(this.counter / 86400);
    let daysonuc = day * 86400;
    let daysonuc2 = this.counter - daysonuc;
    const hours: number = Math.floor(daysonuc2 / 3600);
    let hoursonuc = hours * 3600;
    let hoursonuc2 = daysonuc2 - hoursonuc;
    const minutes: number = Math.floor(hoursonuc2 / 60);
    let minutsonuc = minutes * 60;
    const second: number = Math.floor(hoursonuc2 - minutsonuc);

    let dayDesc = '';
    if (day > 0) {
      dayDesc = day + 'g ';
    }

    let hourDesc = '';
    if (hours > 0) {
      hourDesc = hours + 's ';
    }

    let minuteDesc = '';
    if (minutes > 0) {
      minuteDesc = minutes + 'd ';
    }

    let secondDesc = '';
    if (second > 0) {
      secondDesc = second + 'sn';
    }
    
    this.lbltimer = dayDesc + hourDesc + minuteDesc + secondDesc;
  }

  ngAfterViewInit() {

  }

  kayitEkle() {
    this.dialog.open(IssueTimerAddComponent);
  }

}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor(value / 60);
    return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value -( minutes * 60))).slice(-2);
  }

}
