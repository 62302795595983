import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';
import { MaterialModule } from '../../../../../@fury/shared/material-components.module';
import { AppSharedModule } from '../../../../appshared';
import { CompanyCreateUpdateComponent } from './company-create-update.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
        MatSelectModule,
        AppSharedModule
  ],
  declarations: [CompanyCreateUpdateComponent],
  entryComponents: [CompanyCreateUpdateComponent],
  exports: [CompanyCreateUpdateComponent]
})
export class CompanyCreateUpdateModule {
}
