import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import escape from 'lodash-es/escape';
import { DialogModel } from './dialog.model';
import { CommonService } from '../../../services/common.service';

@Component({
    selector: 'fury-dialogs',
    templateUrl: './dialogs.component.html',
    styleUrls: ['./dialogs.component.scss']
})
export class DialogsComponent {

    result: string;

    dialogHTML =
        escape(`<button mat-raised-button type="button" (click)="openDialog()" color="primary">Open Dialog</button>
<p *ngIf="result">You chose: {{ result }}</p>
`);

    constructor(private dialog: MatDialog) {
    }

    openDialog() {
        this.dialog.open(DemoDialogComponent, {
            disableClose: false
        }).afterClosed().subscribe(result => {
            this.result = result;
        });
    }
}

@Component({
    selector: 'fury-demo-dialog',
    template: `
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
      <div>{{ data.header }}</div>
      <button type="button" mat-icon-button (click)="close('No answer')" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content>
      <p>{{ data.content}}</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
      <button mat-button (click)="close('No')">{{ data.nobtn }}</button>
      <button mat-button color="primary" (click)="close('Yes')">{{ data.yesbtn }}</button>
  </mat-dialog-actions>
  `
})
export class DemoDialogComponent {

    data: DialogModel;

    constructor(private dialogRef: MatDialogRef<DemoDialogComponent>,
        private commonService: CommonService) {

        this.commonService.dialogdata.subscribe(dataDialog => {
            this.data = dataDialog;
        });

    }

    close(answer: string) {
        this.dialogRef.close(answer);
    }
}
