import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatDatepickerModule
} from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IssueTimerAddComponent } from './issue-timer-add.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppSharedModule } from '../../../../../appshared';
import { MaterialModule } from '../../../../../../@fury/shared/material-components.module';
import { ListModule } from '../../../../../../@fury/shared/list/list.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    ListModule,
        NgxMaterialTimepickerModule,
        AppSharedModule
  ],
  declarations: [IssueTimerAddComponent],
  entryComponents: [IssueTimerAddComponent],
  exports: [IssueTimerAddComponent]
})
export class IssueTimerAddModule {
}
