import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../../models/user-models/user.model';
import { Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { ProjectService } from '../../../services/project.service';
import { CommonService } from '../../../services/common.service';
import { AppService } from '../../../services';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

   isOpen: boolean;
  existingImage: any;
  selectedImage: any;
  user: UserModel;
  name: any;
  updatepassword: string;
  profil: string;
    logoutmenu: string;
    notificationsettings: string;

  constructor(private router: Router,
    private readonly accountService: AccountService,
    private readonly projectService: ProjectService,
    private commonService: CommonService,
    private appService: AppService) { 

this.commonService.name.subscribe(name => {
      this.name = name;
    });}

  ngOnInit() {
  this.existingImage = '../../../../assets/img/avatars/defaultProfile.jpg';
    this.getImage();

    this.projectService.getuserprofile()
      .subscribe((response: UserModel) => {
        this.user = response;
        this.name = this.user.firstName + " " + this.user.lastName;
      });

    this.updatepassword = this.appService.appData.content['app_menu_update_password'] || 'app_menu_update_password';
    this.profil = this.appService.appData.content['app_menu_profile'] || 'app_menu_profile';
      this.logoutmenu = this.appService.appData.content['app_menu_logout'] || 'app_menu_logout';
      this.notificationsettings = this.appService.appData.content['app_menu_notification_settings'] || 'app_menu_notification_settings';
      
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

 logout() {
    //email, password clearing
    localStorage.removeItem('8PXYZ@L');
    localStorage.removeItem('7LXYZ@L');

    this.accountService.logout();
  }

  profile() {
    this.onClickOutside();
    this.router.navigate(['/profile']);

  }

  updatePassword() {
    this.onClickOutside();
    this.router.navigate(['/update-password']);

    }

    notificationSettings() {
        this.onClickOutside();
        this.router.navigate(['/notificationsettings']);
    }

  private getImage() {
    this.projectService.getImage()
      .subscribe(base64String => {
        this.selectedImage = 'data:image/png;base64,' + base64String;
      });
  }

}
