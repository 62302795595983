import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, Injector } from '@angular/core';
import { CompanyCreateUpdateComponent } from './company-create-update/company-create-update.component';
import { ProjectService } from '../../../services/project.service';
import { CommonService } from '../../../services/common.service';
import { fadeOutAnimation } from '../../../../@fury/animations/route.animation';
import { ReplaySubject, Observable } from 'rxjs';
import { AppService } from '../../../services/app.service';
import { ListColumn } from '../../../../@fury/shared/list/list-column.model';
import { filter } from 'rxjs/operators';
import { DialogModel } from '../../../models/dialog.model';
import { DemoDialogComponent } from '../../components/dialogs/dialogs.component';
import { ToastrService } from '../../../toastr';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInRightAnimation } from '../../../../@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../../../@fury/animations/fade-in-up.animation';
import { Companyy } from '../../../models/companyy.model';


@Component({
  selector: 'fury-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
    animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class CompanyComponent implements OnInit, AfterViewInit, OnDestroy {


  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Companyy[]> = new ReplaySubject<Companyy[]>(1);
  data$: Observable<Companyy[]> = this.subject$.asObservable();
  company: Companyy[];

  @Input()

  pageSize = 10;
  dataSource: MatTableDataSource<Companyy> | null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


  currenCompany: Companyy;

  private _deleteresult: string;
  get deleteresult(): string {
    return this._deleteresult;
  }
  set deleteresult(val: string) {
    this._deleteresult = val;
  }

  constructor(private dialog: MatDialog,
    private readonly projectService: ProjectService,
      private injector: Injector,
    private commonService: CommonService,
    private appService: AppService) {
  }

   _applicationName  = this.appService.appData.content['app_company_company_name'] || 'app_company_company_name';
   _description = this.appService.appData.content['app_company_company_description'] || 'app_company_company_description';
   _useOrganizationScheme = this.appService.appData.content['app_company_company_useorg_scheme'] || 'app_company_company_useorg_scheme';
  _maxUserCount = this.appService.appData.content['app_company_company_maxuser_count'] || 'app_company_company_maxuser_count';
  _zoneTime = this.appService.appData.content['app_company_zone_time'] || 'app_company_zone_time';

  

  columns: ListColumn[] = [
    { name: 'Id', property: 'id', visible: true, isModelProperty: true },
    { name: this._applicationName, property: 'applicationName', visible: true, isModelProperty: true },
    { name: this._description, property: 'description', visible: true, isModelProperty: true },
    { name: this._useOrganizationScheme, property: 'useOrganizationScheme', visible: true, isClosedState:true, isModelProperty: false  },
    { name: this._maxUserCount, property: 'maxUserCount', visible: true, isModelProperty: true },
    { name: this._zoneTime, property: 'zoneId', visible: true, isModelProperty: true },
    { name: 'Actions', property: 'actions', visible: true },
  ] as ListColumn[];

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    //return of(COMPANY_ALL_DATA.map(company => new Company_(company)));

    //const REGISTER_URL = `/api/project/CompanyList`;
    //return this.dataService.get<Company_[]>(REGISTER_URL);
    return this.projectService.getcompanylist();
  }

  ngOnInit() {
    this.getData().subscribe(company => {
      this.subject$.next(company);
    });

    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
      filter(Boolean)
    ).subscribe((company) => {
      this.company = company;
      this.dataSource.data = company;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createCustomer() {
    this.dialog.open(CompanyCreateUpdateComponent).afterClosed().subscribe((company: Companyy) => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (company) {
        /**
         * Here we are updating our local array.
         * You would probably make an HTTP request here.
         */

        this.projectService.addcompany(company)
          .subscribe((response) => {
            const message = this.appService.appData.content['app_company_add_success'] || 'app_company_add_success';
              this.injector.get(ToastrService).success(message);

            this.company.unshift(new Companyy(response));
            this.subject$.next(this.company);
          });
            //if (response) {
              
            //}
            //else {
            //  const message = this.appService.appData.content['app_company_add_failed'] || 'app_company_add_failed';
            //  this.ns.error(message);
            //}
      }
    });
  }

  updateCustomer(company) {
    this.dialog.open(CompanyCreateUpdateComponent, {
      data: company
    }).afterClosed().subscribe((company) => {
      /**
       * Customer is the updated customer (if the user pressed Save - otherwise it's null)
       */
      if (company) {
        /**
         * Here we are updating our local array.
         * You would probably make an HTTP request here.
         */
        this.projectService.updatecompany(company)
          .subscribe(response => {
            if (response) {
              const message = this.appService.appData.content['app_company_update_success'] || 'app_company_update_success';
                this.injector.get(ToastrService).success(message);

              const index = this.company.findIndex((existingCompany) => existingCompany.id === company.id);
              this.company[index] = new Companyy(company);
              this.subject$.next(this.company);
            }
            else {
              const message = this.appService.appData.content['app_company_update_failed'] || 'app_company_update_failed';
                this.injector.get(ToastrService).error(message);
            }
          });
      }
    });
  }

  deleteCustomer(company) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    this.projectService.deletecompany(company)
      .subscribe(response => {
        if (response) {
          const message = this.appService.appData.content['app_company_delete_success'] || 'app_company_delete_success';
            this.injector.get(ToastrService).success(message);

          this.company.splice(this.company.findIndex((existingCompany) => existingCompany.id === company.id), 1);
          this.subject$.next(this.company);
        }
        else {
          const message = this.appService.appData.content['app_company_delete_failed'] || 'app_company_delete_failed';
            this.injector.get(ToastrService).error(message);
        }
      });

  }

  onFilterChange(value) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  ngOnDestroy() {
  }

  beforedeleteCompany(company) {
    this.currenCompany = company;

    const messageHeader = this.appService.appData.content['app_warning_dialog_header'] || 'app_warning_dialog_header';
    const messageContent = this.appService.appData.content['app_warning_dialog_delete_account'] || 'app_warning_dialog_delete_account';
    const messageYes = this.appService.appData.content['app_warning_dialog_yes'] || 'app_warning_dialog_yes';
    const messageNo = this.appService.appData.content['app_warning_dialog_no'] || 'app_warning_dialog_no';
    const dialogData: DialogModel = { header: messageHeader, content: messageContent, nobtn: messageNo, yesbtn: messageYes };

    this.commonService.dialogdata.next(dialogData);

    this.openDialog();
  }

  openDialog() {
    this.dialog.open(DemoDialogComponent, {
      disableClose: false
    }).afterClosed().subscribe(result => {
      this.deleteresult = result;
      if (this.deleteresult === 'Yes') {
        this.deleteCustomer(this.currenCompany);
      }
    });
  }

}
