import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
    selector: 'app-form-checkbox',
    styleUrls: ['form-checkbox.component.scss'],
    templateUrl: 'form-checkbox.component.html'
})
export class FormCheckboxComponent extends FieldBaseComponent { }
