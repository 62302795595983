import { Component, Inject, OnInit, ChangeDetectorRef, AfterViewInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { CategoryModel } from '../issue-models/category.model';
import { IssueTypeModel } from '../issue-models/issuetype.model';
import { IssueCreateTypeModel } from '../issue-models/issuecreate.model';
import { AppDateAdapter, APP_DATE_FORMATS } from '../issue-search/date.adapter';
import { GroupModel } from '../../../models/user-models/group.model';
import { UserModel } from '../../../models/user-models/user.model';
import { ProjectListModel } from '../../../models/project-models/projectlist.model';
import { AppService } from '../../../services/app.service';
import { ProjectService } from '../../../services/project.service';
import { IssueService } from '../../../services/issue.service';
import { CommonService } from '../../../services/common.service';
import { ToastrService } from '../../../toastr';
import { Customer } from '../../../models/customermodels/customer.model';


@Component({
  selector: 'fury-issue-create',
  templateUrl: './issue-create.component.html',
  styleUrls: ['./issue-create.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class IssueCreateComponent implements OnInit, AfterViewInit {
  static id = 0;
  form: FormGroup;

  groupsModel: GroupModel[];
  selectedGroup: string;
  userModel: UserModel[];
  selectedUser: string;
  categoryModel: CategoryModel[];
  tempcategoryModel: CategoryModel;
  selectedCategory: number;
  issueTypeModel: IssueTypeModel[];
  tempissueTypeModel: IssueTypeModel;
  selectedIssueType: number;

  customerTypeModel: Customer[];
  filteredcustomerTypeModel: Customer[];
  selectedCustomerData: number;
  selectedCustomer: string;
  showCustomer: boolean = false;

  projectTypeModel: ProjectListModel[];
  filteredprojectTypeModel: ProjectListModel[];
  selectedProjectData: number;
  selectedProject: string;

  selectedCustomDate: Date = new Date();
  customDueDate: boolean = false;
  selectedStartDate: Date = new Date();
  nowDate: Date = new Date();


  selectedFile: any;
  fileToUpload: any;

  normal: boolean;
  urgent: boolean;
  critical: boolean;

  _listFilter: string;
  _projectlistFilter: string;

  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredcustomerTypeModel = this.listFilter ? this.performFilter(this.listFilter) : this.customerTypeModel;
  }

  get projectlistFilter(): string {
    return this._projectlistFilter;
  }

  set projectlistFilter(value: string) {
    this._projectlistFilter = value;
    this.filteredprojectTypeModel = this.projectlistFilter ? this.projectperformFilter(this.projectlistFilter) : this.projectTypeModel;
  }


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<IssueCreateComponent>,
    private fb: FormBuilder,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private injector: Injector,
    private readonly projectService: ProjectService,
    private readonly issueService: IssueService,
    private commonService: CommonService) {
  }

  ngOnInit() {

    this.defaults = {} as IssueCreateTypeModel;


    this.form = this.fb.group({
      id: [IssueCreateComponent.id],
      issueTitle: [this.defaults.issueTitle || ''],
      issueDescription: [this.defaults.issueDescription || ''],
      issueAssignedGroupId: [this.defaults.issueAssignedGroupId || ''],
      issueAssignedUserId: [this.defaults.issueAssignedUserId || ''],
      issueTypeId: [this.defaults.issueTypeId || ''],
      issueCategoryId: [this.defaults.issueCategoryId || ''],
      issueDueDate: [this.defaults.issueDueDate || ''],
      issueStartDate: [this.defaults.issueStartDate || ''],
      issueCustomerId: [this.defaults.issueCustomerId || ''],
      issueProjectId: [this.defaults.issueProjectId || ''],
    });

    this.projectService.getallgroups()
      .subscribe(response => {
        if (response) {
          this.groupsModel = response;
        }
        else {
        }
      });


    this.projectService.getcustomerlist2()
      .subscribe(response => {
        if (response) {

          this.customerTypeModel = response.filter((type: Customer) =>
            (type.isEnable === true));

          this.filteredcustomerTypeModel = this.customerTypeModel;
        }
        else {
        }
      });

      this.projectService.getprojectlistforauth()
      .subscribe(response => {
        if (response) {

          this.projectTypeModel = response.filter((type: ProjectListModel) =>
            (type.isEnable === true));

          this.filteredprojectTypeModel = this.projectTypeModel;

        }
        else {
        }
      });


   this.projectService.getuserlistsimple()
  .subscribe(response => {
    if (response) {
      this.userModel = response.filter((type: UserModel) =>
        (type.isEnabled === true));
    }
    else {
    }
      });

    this.issueService.getallcategories()
      .subscribe(response => {
        if (response) {
  
          this.categoryModel = response.filter((type: CategoryModel) =>
            (type.isEnable === true));

          this.tempcategoryModel = (this.categoryModel.filter((type: CategoryModel) =>
            (type.defaultSelected === true)))[0];

          if (this.tempcategoryModel) {
            this.selectedCategory = this.tempcategoryModel.id;
          }

          if (this.selectedCategory) {
            this.selectCategoryCustomDueDate(this.selectedCategory);
          }
        }
        else {
        }
      });

    this.issueService.getallissuetypes()
      .subscribe(response => {
        if (response) {

          this.issueTypeModel = response.filter((type: IssueTypeModel) =>
            (type.isEnable === true));

          this.tempissueTypeModel = (this.issueTypeModel.filter((type: IssueTypeModel) =>
            (type.defaultSelected === true)))[0];

          if (this.tempissueTypeModel) {
            this.selectedIssueType = this.tempissueTypeModel.id;
          }
        }
        else {
        }
      });

    this.selectNormal();
  }

  ngAfterViewInit() {
    this.selectCategoryCustomDueDate(+this.selectedCategory);
  }

  performFilter(filterBy: string): Customer[] {
    try {
      //this.filterReset();
      const orjFilterBy = filterBy;
      filterBy = filterBy.toLocaleLowerCase();
      return this.customerTypeModel.filter((customer: Customer) =>
        (customer.customerName.toLocaleLowerCase().indexOf(filterBy) !== -1));
    }
  catch(e) {
      return this.customerTypeModel;
  }
    
  }

  projectperformFilter(filterBy: string): ProjectListModel[] {
    try {
      //this.filterReset();
      const orjFilterBy = filterBy;
      filterBy = filterBy.toLocaleLowerCase();
      return this.projectTypeModel.filter((project: ProjectListModel) =>
        (project.projectCode.toLocaleLowerCase().indexOf(filterBy) !== -1));
    }
    catch (e) {
      return this.projectTypeModel;
    }

  }

  save() {
    let controlfile: boolean = true;

    //if (this.fileToUpload) {
    //  let filename: string = this.fileToUpload.name;
    //  filename = filename.toLocaleLowerCase();
    //  if ((filename.search('.doc') === -1) && (filename.search('.docx') === -1) &&
    //    (filename.search('.xls') === -1) && (filename.search('.xlsx') === -1) &&
    //    (filename.search('.pdf') === -1) && (filename.search('.txt') === -1) &&
    //    (filename.search('.rtf') === -1) && (filename.search('.pts') === -1) &&
    //    (filename.search('.ptsx') === -1) && (filename.search('.zip') === -1) &&
    //    (filename.search('.rar') === -1) && (filename.search('.7z') === -1) &&
    //    (filename.search('.jpeg') === -1) && (filename.search('.jpg') === -1) &&
    //    (filename.search('.png') === -1) && (filename.search('.gif') === -1) && (filename.search('.msg') === -1)) {

    //    const message = this.appService.appData.content['app_issue_create_file_type_invalid'] || 'app_issue_create_file_type_invalid';
    //      this.injector.get(ToastrService).error(message);
    //    controlfile = false;
    //  }
    //}

    const startdeger: Date = this.form.controls.issueStartDate.value;
    var mm = startdeger.getMonth() + 1;
    var dd = startdeger.getDate();
    var yyyy = startdeger.getFullYear();
    const formattedDateBas: Date = new Date(yyyy, startdeger.getMonth() , dd, 23, 59, 59);

    let formattedDateBitis: Date;

    if (this.customDueDate === true) {
      const degerbitis: Date = this.form.controls.issueDueDate.value;

      var mm = degerbitis.getMonth() + 1;
      var dd = degerbitis.getDate();
      var yyyy = degerbitis.getFullYear();
      formattedDateBitis = new Date(yyyy, degerbitis.getMonth(), dd, 23, 59, 59);
    }

    if ((this.nowDate > formattedDateBas) || ((this.customDueDate === true) && (this.nowDate > formattedDateBitis))) {
      const message = this.appService.appData.content['app_issue_create_date_not_past'] || 'app_issue_create_date_not_past';
        this.injector.get(ToastrService).error(message);
      controlfile = false;
    }

    if ((this.customDueDate === true) && (formattedDateBas > formattedDateBitis)) {
      const message = this.appService.appData.content['app_issue_create_start_date_not_bigger_than_due_date'] || 'app_issue_create_start_date_not_bigger_than_due_date';
        this.injector.get(ToastrService).error(message);
      controlfile = false;
    }

    if (controlfile) {
      const issue = this.form.value;
      issue.id = 0;
      issue.issueAssignedGroupId = this.form.controls.issueAssignedGroupId.value;
      issue.issueAssignedUserId = this.form.controls.issueAssignedUserId.value;
      issue.issueTypeId = this.form.controls.issueTypeId.value;
      issue.issueCategoryId = this.form.controls.issueCategoryId.value;

      if (this.normal) {
        issue.issuePriorityId = 1;
      }

      if (this.urgent) {
        issue.issuePriorityId = 2;
      }

      if (this.critical) {
        issue.issuePriorityId = 3;
      }

      if ((this.form.controls.issueDueDate.value === undefined) || (this.form.controls.issueDueDate.value === null)) {
        issue.issueDueDate = undefined;
      }
      else {
        if (this.customDueDate === true) {
          const deger: Date = this.form.controls.issueDueDate.value;

          var mm = deger.getMonth() + 1;
          var dd = deger.getDate();
          var yyyy = deger.getFullYear();
          var formattedDate = yyyy + '-' + mm + '-' + dd + ' ' + '00:00:00';

          issue.issueDueDate = formattedDate;
        }
        else {
          issue.issueDueDate = undefined;
        }
      }

      if ((this.form.controls.issueStartDate.value === undefined) || (this.form.controls.issueStartDate.value === null)) {
        issue.issueStartDate = undefined;
      }
      else {
        const deger: Date = this.form.controls.issueStartDate.value;

          var mm = deger.getMonth() + 1;
          var dd = deger.getDate();
        var yyyy = deger.getFullYear();
        var hoursdata = '00:00:00';
        if ((deger.getFullYear() === this.nowDate.getFullYear()) && (deger.getMonth() === this.nowDate.getMonth()) && (this.nowDate.getDate() === deger.getDate())) {
          hoursdata = deger.getHours() + ':' + deger.getMinutes() + ':' + deger.getSeconds();
        }
        var formattedDate = yyyy + '-' + mm + '-' + dd + ' ' + hoursdata;

        issue.issueStartDate = formattedDate;

      }

      issue.issueCustomerId = this.selectedCustomerData;

      issue.issueProjectId = this.selectedProjectData;
   
      this.issueService.addissue(issue)
        .subscribe(response => {
          if (response) {
            const data = new UserModel(response);
            if (this.fileToUpload) {
              this.upload(data);
            }
            else {
              const message = this.appService.appData.content['app_issue_create_success'] || 'app_issue_create_success';
               this.injector.get(ToastrService).success(message);
                //this.injector.get(ToastrService).success(message, 'Success', { onActivateTick: true });
              this.commonService.newissue.next(true);
            }
          }
          else {
            const message = this.appService.appData.content['app_issue_create_failed'] || 'app_issue_create_failed';
              this.injector.get(ToastrService).error(message);
          }
        });

        this.dialogRef.close();
    }
  }

  upload(data) {
    const file = new FormData();
    file.append('file', this.fileToUpload);

    const options = {
      issueid: data.id
    };

    if (this.fileToUpload) {
      this.issueService.uploadissuefile(file, options)
        .subscribe(response => {
          if (response) {
            const message = this.appService.appData.content['app_issue_create_with_file_success'] || 'app_issue_create_with_file_success';
              this.injector.get(ToastrService).success(message);
          }
          else {
            const message = this.appService.appData.content['app_issue_create_failed'] || 'app_issue_create_failed';
              this.injector.get(ToastrService).error(message);
          }
        });

    }
    else {
      const message = this.appService.appData.content['app_profile_update_success'] || 'app_profile_update_success';
        this.injector.get(ToastrService).success(message);
      this.commonService.newissue.next(true);
    }

  }


  openInput() {
    // your can use ElementRef for this later
    document.getElementById("fileissue").click();
  }

  clearAttach() {
    this.selectedFile = "";
    this.fileToUpload = "";
  }

  getUserList(groupid: number) {

    this.selectedUser = undefined;

    this.projectService.getuserlistsimplebygroupid(groupid)
      .subscribe(response => {
        if (response) {

          this.userModel = response.filter((type: UserModel) =>
            (type.isEnabled === true));

          //this.userModel = response;
        }
        else {

        }

      });
  }

  selectCategoryCustomDueDate(categoryid: number) {

    try {
      const index = this.categoryModel.findIndex((category) => category.id === categoryid);
      const cat = this.categoryModel[index];
      if (cat.isCustomDueDate === true) {
        this.customDueDate = true;
      }
      else {
        this.customDueDate = false;
      }

      if (cat.customerRelated === true) {
        this.showCustomer = true;
      }
      else {
        this.showCustomer = false;
      }


      
    }
    catch (e) {

    }

    this.cd.markForCheck();
  }

  selectProject(project) {
    this.projectlistFilter = project.projectCode;
    this.selectedProjectData = project.id;
    this.selectedProject = project.projectCode;

    this.selectedUser = undefined;
    this.selectedGroup = undefined;

    this.getuserGroupsForProject(project);
  }

  projectReset() {
    this.selectedProjectData = undefined;
    this.selectedProject = undefined;
    this.projectlistFilter = undefined;

    this.selectedUser = undefined;
    this.selectedGroup = undefined;

    this.getUserAndGroups();

  }

  projectfilterReset() {
    this.selectedProjectData = undefined;
    this.selectedProject = undefined;

    this.selectedUser = undefined;
    this.selectedGroup = undefined;

    this.getUserAndGroups();
  }

  selectCustomer(customer) {
    this.listFilter = customer.customerName;
    this.selectedCustomerData = customer.id;
    this.selectedCustomer = customer.customerName;
  }

  customerReset() {
    this.selectedCustomerData = undefined;
    this.selectedCustomer = undefined;
    this.listFilter = undefined;
  }

  filterReset() {
    this.selectedCustomerData = undefined;
    this.selectedCustomer = undefined;
  }

  fileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.fileToUpload = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFile = {
          mimetype: e.target.result.split(',')[0].split(':')[1].split(';')[0],
          url: e.target.result
        };
      };
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  isSystemAdmin() {
    let fullRoles = atob(localStorage.getItem('1ZXYL@M'));

    if (fullRoles === null) {
      fullRoles = "User";
    }
    if (fullRoles.search("SystemAdmin") !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  close() {
    this.dialogRef.close();
  }

  selectNormal() {
    this.normal = true;
    this.urgent = false;
    this.critical = false;
  }

  selectUrgent() {
    this.normal = false;
    this.urgent = true;
    this.critical = false;
  }

  selectCritical() {
    this.normal = false;
    this.urgent = false;
    this.critical = true;
  }

  getuserGroupsForProject(project) {
    this.projectService.getallgroupsforproject(project)
      .subscribe(response => {
        if (response) {
          this.groupsModel = response;
        }
        else {
        }
      });


    this.projectService.getallusersforproject(project)
      .subscribe(response => {
        if (response) {
          this.userModel = response.filter((type: UserModel) =>
            (type.isEnabled === true));
        }
        else {
        }
      });

  }

  getUserAndGroups() {
    this.projectService.getallgroups()
      .subscribe(response => {
        if (response) {
          this.groupsModel = response;
        }
        else {
        }
      });


    this.projectService.getuserlistsimple()
      .subscribe(response => {
        if (response) {
          this.userModel = response.filter((type: UserModel) =>
            (type.isEnabled === true));
        }
        else {
        }
      });
  }
}
