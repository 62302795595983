import { Component, Inject, OnInit, Input, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, DateAdapter, MAT_DATE_FORMATS, MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../issue-search/date.adapter';
import { IssueTimesheetsModel } from '../../../issue-models/timesheets.model';
import { TimeSheetsWorkingModel } from '../../../issue-models/timesheetsworking.model';
import { ReplaySubject, Observable } from 'rxjs';
import { AppService } from '../../../../../services/app.service';
import { ProjectService } from '../../../../../services/project.service';
import { IssueService } from '../../../../../services/issue.service';
import { CommonService } from '../../../../../services/common.service';
import { ListColumn } from '../../../../../../@fury/shared/list/list-column.model';
import { filter } from 'rxjs/operators';
import { DemoDialogComponent } from '../../../../components/dialogs/dialogs.component';
import { DialogModel } from '../../../../../models/dialog.model';
import { ToastrService } from '../../../../../toastr';


@Component({
  selector: 'fury-issue-timer-add',
  templateUrl: './issue-timer-add.componentv191003.html',
  styleUrls: ['./issue-timer-add.componentv191003.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class IssueTimerAddComponent implements OnInit, AfterViewInit {

  subject$: ReplaySubject<IssueTimesheetsModel[]> = new ReplaySubject<IssueTimesheetsModel[]>(1);
  data$: Observable<IssueTimesheetsModel[]> = this.subject$.asObservable();
  timesheetsList: IssueTimesheetsModel[];
  @Input()

  pageSize = 3;
  dataSource: MatTableDataSource<IssueTimesheetsModel> | null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

  form: FormGroup;
  currenttimesheet: IssueTimesheetsModel;
  workingListModel: TimeSheetsWorkingModel[];
  noadded: boolean = false;
  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  endHour: string;
  startHour: string;

  private _deleteresult: string;
  get deleteresult(): string {
    return this._deleteresult;
  }
  set deleteresult(val: string) {
    this._deleteresult = val;
  }

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<IssueTimerAddComponent>,
    private fb: FormBuilder,
    private appService: AppService,
    private injector: Injector,
    private readonly projectService: ProjectService,
    private readonly issueService: IssueService,
    private commonService: CommonService) {
  }

  _startDate = this.appService.appData.content['app_timesheet_startdate'] || 'app_timesheet_startdate';
  _endDate = this.appService.appData.content['app_timesheet_enddate'] || 'app_timesheet_enddate';
  _workingTime = this.appService.appData.content['app_timesheet_working_time'] || 'app_timesheet_working_time';



  columns: ListColumn[] = [
    { name: 'Id', property: 'id', visible: false, isModelProperty: true },
    { name: 'IssueId', property: 'issueId', visible: false, isModelProperty: true },
    { name: this._startDate, property: 'startDate', visible: true, isDate: true, isModelProperty: false},
    { name: this._endDate, property: 'endDate', visible: true, isDate: true, isModelProperty: false },
    { name: this._workingTime, property: 'workingTime', visible: true, isModelProperty: true },
    { name: 'Actions', property: 'actions', visible: true },
  ] as ListColumn[];

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  getData() {

    const data: IssueTimesheetsModel = {
      id: 0,
      issueId: this.defaults.issueId
      , userId: undefined
      , applicationId: undefined
      , startDate: undefined
      , endDate: undefined
      , workingTime: undefined
    };

    return this.issueService.gettimesheetslist(data);

  }

  ngOnInit() {

    //this.defaults = {} as IssueTimesheetsModel;

    this.form = this.fb.group({
      workingStartDate: [null, Validators.nullValidator],
      workingHourStartDate: [null, Validators.nullValidator],
      workingHourEndDate: [null, Validators.nullValidator],
      workingEndDate: [null, Validators.nullValidator],
    });

    this.getTimeSheetList();

    this.startHour = '09:00';
    this.endHour = '18:00';
  }

  getTimeSheetList() {
    this.getData().subscribe(timesheetsList => {
      this.subject$.next(timesheetsList);
    });

    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
      filter(Boolean)
    ).subscribe((timesheets) => {
      this.timesheetsList = timesheets;
      this.dataSource.data = timesheets;
      });

    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  close() {
    this.dialogRef.close(null);
  }

  deletetimesheet(timesheet) {

    this.issueService.deletetimesheets(timesheet)
      .subscribe(response => {
        if (response) {
          this.getTimeSheetList();
        }
        else {
          const message = this.appService.appData.content['app_timesheet_delete_failed'] || 'app_timesheet_delete_failed';
            this.injector.get(ToastrService).error(message);
        }
      });
  }

  beforedeleteTimesheet(timesheet) {
    this.currenttimesheet = timesheet;

    const messageHeader = this.appService.appData.content['app_warning_dialog_header'] || 'app_warning_dialog_header';
    const messageContent = this.appService.appData.content['app_warning_dialog_delete_account'] || 'app_warning_dialog_delete_account';
    const messageYes = this.appService.appData.content['app_warning_dialog_yes'] || 'app_warning_dialog_yes';
    const messageNo = this.appService.appData.content['app_warning_dialog_no'] || 'app_warning_dialog_no';
    const dialogData: DialogModel = { header: messageHeader, content: messageContent, nobtn: messageNo, yesbtn: messageYes };

    this.commonService.dialogdata.next(dialogData);

    this.openDialog();
  }

  openDialog() {
    this.dialog.open(DemoDialogComponent, {
      disableClose: false
    }).afterClosed().subscribe(result => {
      this.deleteresult = result;
      if (this.deleteresult === 'Yes') {
        this.deletetimesheet(this.currenttimesheet);
      }
    });
  }

  addWorking() {
    this.noadded = false;

    const startdeger: Date = this.form.controls.workingStartDate.value;
    var mm = startdeger.getMonth() + 1;
    var dd = startdeger.getDate();
    var yyyy = startdeger.getFullYear();
    var formattedDateBas = yyyy + '-' + mm + '-' + dd + ' ' + this.startHour + ':00';

    const enddeger: Date = this.form.controls.workingEndDate.value;
    var mm2 = enddeger.getMonth() + 1;
    var dd2 = enddeger.getDate();
    var yyyy2 = enddeger.getFullYear();
    var formattedDateBit = yyyy2 + '-' + mm2 + '-' + dd2 + ' ' + this.endHour + ':00' ;

    const data: IssueTimesheetsModel = {
      id: 0,
      issueId: this.defaults.issueId
      , userId: 0
      , applicationId: 0
      , startDate: formattedDateBas
      , endDate: formattedDateBit
      , workingTime: undefined
    };

    this.issueService.getTimeSheetAvailability(data)
      .subscribe(response => {
        if (response) {
          this.workingListModel = response;
          if (this.workingListModel && this.workingListModel.length > 0) {
            this.noadded = true;
          }
          else {
            this.saveTimeSheet(data);
          }
        }
        else {
          
        }
      });
  }

  saveTimeSheet(data) {
    this.issueService.saveTimeSheet(data)
      .subscribe(response => {
        if (response) {
          this.getTimeSheetList();
        }
        else {
          const message = this.appService.appData.content['app_timesheet_save_failed'] || 'app_timesheet_save_failed';
            this.injector.get(ToastrService).error(message);
        }
      });
  }
}
