import { Component, Inject, OnInit, Input, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, DateAdapter, MAT_DATE_FORMATS, MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../issue-search/date.adapter';


@Component({
  selector: 'fury-issue-attach-display',
  templateUrl: './issue-attach-display.component.html',
  styleUrls: ['./issue-attach-display.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class IssueAttachDisplayComponent implements OnInit, AfterViewInit {

  imageurl: string;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<string>,
    private fb: FormBuilder) {
  }


  ngOnInit() {

    this.imageurl = this.defaults;
  }

  ngAfterViewInit() {
    
  }

  close() {
    this.dialogRef.close(null);
  }


}
