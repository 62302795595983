import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, Injector } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IssueListModel } from '../issue-models/issuelist.model';

import { IssueSearchComponent } from '../issue-search/issue-search.component';
import { IssueSearchTypeModel } from '../issue-models/issuesearch.model';
import { fadeOutAnimation } from '../../../../@fury/animations/route.animation';
import { IssueService } from '../../../services/issue.service';
import { CommonService } from '../../../services/common.service';
import { ListColumn } from '../../../../@fury/shared/list/list-column.model';
import { filter } from 'rxjs/operators';
import { AppService } from '../../../services/app.service';
import {  fadeInUpAnimation } from '../../../../@fury/animations/fade-in-up.animation';
import { fadeInRightAnimation } from '../../../../@fury/animations/fade-in-right.animation';

@Component({
  selector: 'fury-issue-list',
  templateUrl: './issue-list.componentv191003.html',
  styleUrls: ['./issue-list.componentv191003.scss'],
    animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class IssueListComponent implements OnInit, AfterViewInit, OnDestroy {


  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<IssueListModel[]> = new ReplaySubject<IssueListModel[]>(1);
  data$: Observable<IssueListModel[]> = this.subject$.asObservable();
  issuelist: IssueListModel[];

  @Input()

  pageSize = 50;
  isLoading: boolean;
  dataSource: MatTableDataSource<IssueListModel> | null;

  filtered: boolean;
  autoRefresh: boolean;
  onlyActive: boolean = undefined;
  searchData: IssueSearchTypeModel;
  overCss: boolean = true;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialog: MatDialog,
    private readonly issueService: IssueService,
      private injector: Injector,
    private appService: AppService,
    private commonService: CommonService,
    private _route: ActivatedRoute,
    private _router: Router) {

    this.commonService.newissue.subscribe(newissue => {
      if (newissue) {
        if (this.autoRefresh) {
          this.reload();
        }
      }
    });
  }

  _issueTitle = this.appService.appData.content['app_issue_list_issuetitle'] || 'app_issue_list_issuetitle';
  _issueStatusName = this.appService.appData.content['app_issue_list_issuestatus_name'] || 'app_issue_list_issuestatus_name';
  _priorityName = this.appService.appData.content['app_issue_list_priority_name'] || 'app_issue_list_priority_name';
  _issueTypeName = this.appService.appData.content['app_issue_list_issue_type_name'] || 'app_issue_list_issue_type_name';
  _issueResolutionName = this.appService.appData.content['app_issue_create_issue_resolution'] || 'app_issue_create_issue_resolution';
  _createdDate = this.appService.appData.content['app_issue_list_created_date'] || 'app_issue_list_created_date';
  _createdUserName = this.appService.appData.content['app_issue_list_created_user'] || 'app_issue_list_created_user';
  _assignedGroupName = this.appService.appData.content['app_issue_list_assigned_group'] || 'app_issue_list_assigned_group';
  _assignedUserName = this.appService.appData.content['app_issue_list_assigned_user_name'] || 'app_issue_list_assigned_user_name';
  _categoryName = this.appService.appData.content['app_issue_list_category'] || 'app_issue_list_category';
  _overTimeForDays = this.appService.appData.content['app_issue_list_over_time_day'] || 'app_issue_list_over_time_day';
  _issueDueDate = this.appService.appData.content['app_issue_create_due_date'] || 'app_issue_create_due_date';
  _overTimeForMinutes = this.appService.appData.content['app_issue_list_over_time'] || 'app_issue_list_over_time';
  _id = this.appService.appData.content['app_issue_list_detail_issueno'] || 'app_issue_list_detail_issueno';
  _companyIdReference = this.appService.appData.content['app_issue_list_detail_issueno'] || 'app_issue_list_detail_issueno';
  _startDate = this.appService.appData.content['app_issue_create_issue_start_date'] || 'app_issue_create_issue_start_date';
  _customerName = this.appService.appData.content['app_customer_name'] || 'app_customer_name';
  _projectId = this.appService.appData.content['app_project_id'] || 'app_project_id';
  _projectCode = this.appService.appData.content['app_project_code'] || 'app_project_code';
  _projectTitle = this.appService.appData.content['app_project_title'] || 'app_project_title';

  columns: ListColumn[] = [
    { name: this._id, property: 'companyIdReference', visible: true,  isModelProperty: true },
    { name: this._issueStatusName, property: 'issueStatusName', visible: true, isHasColor: true, isModelProperty: false },
    { name: this._issueTitle, property: 'issueTitle', visible: true, isModelProperty: true },
    { name: this._categoryName, property: 'categoryName', visible: false, isModelProperty: true },
    { name: this._priorityName, property: 'priorityName', visible: true, isModelProperty: true },
    { name: this._issueResolutionName, property: 'resolutionName', visible: true, isModelProperty: true },
    { name: this._createdDate, property: 'createdDate', visible: false, isDate: true, isModelProperty: false },
    { name: this._startDate, property: 'issueStartDate', visible: true, isDate: true, isModelProperty: false },
    { name: this._createdUserName, property: 'createdUserName', visible: false, isModelProperty: true },
    { name: this._assignedGroupName, property: 'groupName', visible: false, isModelProperty: true },
    { name: this._assignedUserName, property: 'assignedUser', visible: false, isModelProperty: true },
    { name: this._issueDueDate, property: 'issueDueDate', visible: true, isDate: true, isModelProperty: false },
    { name: this._projectCode, property: 'projectCode', visible: false, isModelProperty: true },
    { name: this._projectTitle, property: 'projectTitle', visible: false, isModelProperty: true },
    { name: this._customerName, property: 'customerName', visible: false, isModelProperty: true },
    { name: this._overTimeForMinutes, property: 'overTimeForMinutes', visible: false, isModelProperty: true },
    { name: this._overTimeForDays, property: 'overTimeForDays', visible: false, isModelProperty: true }
    //{ name: 'Actions', property: 'actions', visible: false },
  ] as ListColumn[];

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  getData() {
    const dataint: number[] = [0];
    if (!this.searchData) {
      const data: IssueSearchTypeModel = { issueId : undefined
         , issueTitle : undefined
         , issueAssignedGroupId : undefined
         , issueAssignedUserId : undefined
          , issueAssigningUserId : undefined
         , issueTypeId : undefined
        , issueStatusId: dataint
        , issueResolutionId: dataint
         , issueCategoryId : undefined
         , startDate : undefined
         , endDate : undefined
        , issuePriorityId: undefined,
        isActive: undefined
        , isOverDue: undefined,
        topCount: undefined,
        issueCustomerId: undefined,
        companyIdReference: undefined,
        issueProjectId: undefined
      };
      this.searchData = data;
    }
    this.searchData.isActive = this.onlyActive;
    return this.issueService.getissuelist(this.searchData);
  }

  ngOnInit() {

    const param = this._route.snapshot.paramMap.get('id');
    if (param) {
      if (param === 'active') {
        this.onlyActive = true;
      }
      else if (param === 'closed') {
        this.onlyActive = false;
      }
      else {
        this.onlyActive = undefined;
      }
    }
    else {
      this.onlyActive = undefined;
    }

    this.getGridHeaderCookie();

    this.getSearchCookie();

    this.reload();

    var id = setInterval(() => {
      //clearInterval(id);
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);

    var id2 = setInterval(() => {
      this.overCss = !this.overCss;
    }, 1000);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setGridHeaderCookie() {
    if (this.columns) {
      localStorage.setItem('898&XYZ@L', JSON.stringify(this.columns));
    }
  }

  getGridHeaderCookie() {
    if (localStorage.getItem('898&XYZ@L') !== null) {
      if (this.columns !== null) {

        this.columns = JSON.parse(localStorage.getItem('898&XYZ@L')) as ListColumn[];
      }
    }
  }

  getSearchCookie() {
    if (localStorage.getItem('797&XYZ@L') !== null) {
      const searchDataCookie = JSON.parse(localStorage.getItem('797&XYZ@L')) as IssueSearchTypeModel;
      this.searchData = searchDataCookie;
      this.searchDataControl();
    }
  }

  reload() {

    this.getData().subscribe(issuelist => {
      this.subject$.next(issuelist);
    });

    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
      filter(Boolean)
    ).subscribe((issuelist) => {
      this.issuelist = issuelist;
      this.dataSource.data = issuelist;
      });

    this.ngAfterViewInit();

  }

  excel() {

    if (!this.searchData) {
      const dataint: number[] = [0];
      const data: IssueSearchTypeModel = {
        issueId: undefined
        , issueTitle: undefined
        , issueAssignedGroupId: undefined
        , issueAssignedUserId: undefined
        , issueAssigningUserId: undefined
        , issueTypeId: undefined
        , issueStatusId: dataint
        , issueResolutionId: dataint
        , issueCategoryId: undefined
        , startDate: undefined
        , endDate: undefined
        , issuePriorityId: undefined,
        isActive: undefined
        , isOverDue: undefined,
        topCount: undefined,
        issueCustomerId: undefined,
        companyIdReference: undefined,
        issueProjectId: undefined
      };
      this.searchData = data;
    }

    const deger: Date = new Date();

    var mm = deger.getMonth() + 1;
    var dd = deger.getDate();
    var yyyy = deger.getFullYear();

    const fileName = this.appService.appData.content['app_issue_list_excel_report'] || 'app_issue_list_excel_report';

    var formattedFileName = fileName + '_'+ yyyy + mm  + dd +'.xlsx';


    this.issueService.getissueexcel(this.searchData).subscribe(result => {
      this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', formattedFileName);
    });
  }

  downloadFile(blob: any, type: string, filename: string) {

    var binaryData = [];
    binaryData.push(blob);

    const url = window.URL.createObjectURL(new Blob(binaryData, { type: type })); // <-- work with blob directly

    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
  }

  issueDetail(issue) {
    if (issue.companyId) {
      this.setGridHeaderCookie();
      this._router.navigate(['/issuedetail/' + btoa(issue.id)]);
    }
  }


  onFilterChange(value) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  ngOnDestroy() {
  }

  filter() {
    this.dialog.open(IssueSearchComponent).afterClosed().subscribe((search: IssueSearchTypeModel) => {
      if (search) {
        search.isActive = undefined;
        this.onlyActive = undefined;
        this.searchData = search;
        localStorage.setItem('797&XYZ@L', JSON.stringify(search));

        this.reload();
      }

      this.searchDataControl();
    });
  }

  searchDataControl() {
    if (!this.searchData) {
      this.filtered = false;
    }
    else if (this.searchData.startDate !== undefined || this.searchData.endDate !== undefined ||
      this.searchData.issueProjectId !== undefined ||
      this.searchData.issueAssignedGroupId !== undefined || this.searchData.issueId !== undefined || this.searchData.companyIdReference !== undefined ||
      (this.searchData.issueStatusId !== undefined && this.searchData.issueStatusId.length > 0 && this.searchData.issueStatusId[0] !== 0) ||
      (this.searchData.issueResolutionId !== undefined && this.searchData.issueResolutionId.length > 0 && this.searchData.issueResolutionId[0] !== 0) ||
        this.searchData.issueAssignedUserId !== undefined || this.searchData.issueAssigningUserId !== undefined || this.searchData.issueCategoryId !== undefined ||
      this.searchData.issuePriorityId !== undefined || this.searchData.issueTypeId !== undefined ||
      this.searchData.issueTitle !== undefined || this.searchData.isOverDue === true || this.searchData.issueCustomerId !== undefined) {
      this.filtered = true;
    }
    else {
      localStorage.removeItem('797&XYZ@L');
      this.filtered = false;
    }
  }

}
