import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule, MatInputModule, MatButtonModule, MatIconModule, MatRadioModule, MatSelectModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { IssueTimerComponent, FormatTimePipe } from './issue-timer.component';
import { IssueTimerAddModule } from './issue-timer-add/issue-timer-add.module';
import { IssueTimerAddComponent } from './issue-timer-add/issue-timer-add.component';
import { AppSharedModule } from '../../../../appshared';
import { MaterialModule } from '../../../../../@fury/shared/material-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    // Core
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    RoundProgressModule,
        IssueTimerAddModule,
        AppSharedModule
  ],
  declarations: [IssueTimerComponent, FormatTimePipe],
  exports: [IssueTimerComponent]
})
export class IssueTimerModule {
}
