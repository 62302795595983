import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
    selector: 'app-form-radio-list',
    styleUrls: ['form-radio-list.component.scss'],
    templateUrl: 'form-radio-list.component.html'
})
export class FormRadioListComponent extends FieldBaseComponent { }
