import { Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { TokenService } from "./token.service";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { DashBoardPeriodModel } from "../models/dashboard-models/dashboardperod.model";
import { DashBoardQuickInfoModel } from "../models/dashboard-models/dashboard-quickinfo.model";
import { DashBoardStatusModel } from "../models/dashboard-models/dashboardstatus.model";
import { DashBoardCategoryModel } from "../models/dashboard-models/dashboardcategory.model";
import { DashBoardTypeModel } from "../models/dashboard-models/dashboardtype.model";
import { DashBoardIssueCountOverDueModel } from "../models/dashboard-models/dashboardissuecountoverdue.model";
import 'rxjs/Rx';
import { Observable } from "rxjs";


@Injectable()
export class DashBoardService {

  constructor(private http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    public dataService: DataService,
    private inj: Injector) {

  }

  public getperiods() {
    const REGISTER_URL = `/api/DashBoard/DashBoardPeriodlist`;
    return this.dataService.get<DashBoardPeriodModel[]>(REGISTER_URL);
  }

  public quickinfodata(period): Observable<DashBoardQuickInfoModel> {
    const REGISTER_URL = `/api/DashBoard/DashBoardQuickInfo`;
    return this.dataService.post<DashBoardQuickInfoModel>(REGISTER_URL, period);
  }

  public statusdata(period): Observable<DashBoardStatusModel[]> {
    const REGISTER_URL = `/api/DashBoard/DashBoardStatusData`;
    return this.dataService.post<DashBoardStatusModel[]>(REGISTER_URL, period);
  }

  public categorydata(period): Observable<DashBoardCategoryModel[]> {
    const REGISTER_URL = `/api/DashBoard/DashBoardCategoryData`;
    return this.dataService.post<DashBoardCategoryModel[]>(REGISTER_URL, period);
  }

  public typedata(period): Observable<DashBoardTypeModel[]> {
    const REGISTER_URL = `/api/DashBoard/DashBoardTypeData`;
    return this.dataService.post<DashBoardTypeModel[]>(REGISTER_URL, period);
  }

  public issuecountoverduedata(period): Observable<DashBoardIssueCountOverDueModel[]> {
    const REGISTER_URL = `/api/DashBoard/DashBoardIssueCountOverDueData`;
    return this.dataService.post<DashBoardIssueCountOverDueModel[]>(REGISTER_URL, period);
  } 

}
