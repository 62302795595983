import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material";


export class AppDateAdapter extends NativeDateAdapter {

  parse(value: any): Date | null {
      if ((typeof value === 'string') && ((value.indexOf('-') > -1) || (value.indexOf('/') > -1) || (value.indexOf('.') > -1))) {
      const deg1 = value.replace("/", "-");
      const deg2 = deg1.replace("/", "-");
      const deg3 = deg2.replace(".", "-");
      const deg4 = deg3.replace(".", "-");
      const str = deg4.split('-');
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const date = Number(str[2]);
      return new Date(date, month, year);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date, displayFormat: string): string {
      if (displayFormat == "input") {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year  ;
    } else if (displayFormat == "inputMonth") {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return year + '-' + this._to2digit(month);
    } else {
      return date.toDateString();
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
  {
    parse: {
      dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
      dateInput: 'input',
      monthYearLabel: 'inputMonth',
      dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
      monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
  }
