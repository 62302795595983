import { Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { TokenService } from "./token.service";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { CategoryModel } from "../models/issue-models/category.model";
import { SettingCategoryModel } from "../models/issue-models/settingcategory.model";
import { IssueTypeModel } from "../models/issue-models/issuetype.model";
import { IssueStatusModel } from "../models/issue-models/issuestatus.model";
import { IssueResolutionModel } from "../models/issue-models/issueresolution.model";
import { IssueCreateTypeModel } from "../models/issue-models/issuecreate.model";
import { IssueListModel } from "../models/issue-models/issuelist.model";
import { IssueSearchTypeModel } from "../models/issue-models/issuesearch.model";
import { IssueAttachmentModel } from "../models/issue-models/issueattachment.model";
import { IssueHistoryModel } from "../models/issue-models/issuehistory.model";
import { UserModel } from "../models/user-models/user.model";
import { IssueCommentModel } from "../models/issue-models/issuecomments.model";
import { IssueTimesheetsModel } from "../models/issue-models/timesheets.model";
import { WorkingTimeListModel } from "../models/workingmodel/workingtime.model";
import { TimeSheetsWorkingModel } from "../models/issue-models/timesheetsworking.model";
import { WorkingGroupTimeListModel } from "../models/workingmodel/workinggrouptime.model";
import { WorkingGroupByDayTimeListModel } from "../models/workingmodel/workinggroupbydaytime.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IssueAttachmentUrlModel } from "../pages/issue/issue-models/issueattachmenturl.model";
import { ReportProjectIssues } from "../pages/issue/issue-models/reportprojectissues.model";
import { ReportProjectIssuesSearch } from "../pages/issue/issue-models/reportprojectissuessearch.model";
import { ReportProjectIssuesSummary } from "../pages/issue/issue-models/reportprojectissuessummary.model";

import { ReportProjectIssuesAssignedSummary } from "../pages/issue/issue-models/reportprojectissuesassignedsummary.model";
import { ReportAssignedProjectIssuesSearch } from "../pages/issue/issue-models/reportassignedprojectissuessearch.model";

import { ReportProjectIssuesAssignedUserSummary } from "../pages/issue/issue-models/reportprojectissuesassignedusersummary.model";
import { ReportAssignedUserProjectIssuesSearch } from "../pages/issue/issue-models/reportassigneduserprojectissuessearch.model";



@Injectable()
export class IssueService {

  constructor(private http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    public dataService: DataService,
    private inj: Injector) {

  }

  public getallcategories() {
    const REGISTER_URL = `/api/Issue/CategoryList`;
    return this.dataService.get<CategoryModel[]>(REGISTER_URL);
  }

  public getallsettingcategories() {
    const REGISTER_URL = `/api/Issue/CategorySettingList`;
    return this.dataService.get<SettingCategoryModel[]>(REGISTER_URL);
  }

  public getallissuetypes() {
    const REGISTER_URL = `/api/Issue/IssueTypeList`;
    return this.dataService.get<IssueTypeModel[]>(REGISTER_URL);
  }

  public getallissuestatus() {
    const REGISTER_URL = `/api/Issue/IssueStatusList`;
    return this.dataService.get<IssueStatusModel[]>(REGISTER_URL);
  }

  public getallissueresolutions() {
    const REGISTER_URL = `/api/Issue/IssueResolutionList`;
    return this.dataService.get<IssueResolutionModel[]>(REGISTER_URL);
  }

  public addissue(issue): Observable<IssueCreateTypeModel> {
    const REGISTER_URL = `/api/Issue/AddIssue`;
    return this.dataService.post<IssueCreateTypeModel>(REGISTER_URL, issue);
  }

  public updateissue(issue) {
    const REGISTER_URL = `/api/Issue/UpdateIssue`;
      return this.dataService.post(REGISTER_URL, issue).pipe(
          map((res) => {
              return true;
          }));
  }

  public uploadissuefile(file,params) {
    const REGISTER_URL = `/api/Issue/UploadIssueFile`;
      return this.dataService.post(REGISTER_URL, file, params).pipe(
          map((res) => {
              return true;
          }));
  }

  public getissuelist2() {
    const REGISTER_URL = `/api/Issue/GetIssueList`;
    return this.dataService.get<IssueListModel[]>(REGISTER_URL);
  }

  public getissuelist(searchParam) {
    const REGISTER_URL = `/api/Issue/GetIssueList`;
    return this.dataService.post<IssueListModel[]>(REGISTER_URL, searchParam);
  }

  public getissuebyid(searchParam) {
    const REGISTER_URL = `/api/Issue/GetIssueById`;
      return this.dataService.post<IssueListModel>(REGISTER_URL, searchParam);

  }

  public getaboutmeissuelist(searchParam) {
    const REGISTER_URL = `/api/Issue/GetAbotMeIssueList`;
    return this.dataService.post<IssueListModel[]>(REGISTER_URL, searchParam);
  }

  public abotmestatusupdate(issue) {
    const REGISTER_URL = `/api/Issue/AbotMeStatusUpdate`;
      return this.dataService.post(REGISTER_URL, issue).pipe(
          map((res) => {
              return true;
          }));
  }

  public getissueexcel(model: IssueSearchTypeModel): Observable<any> {
    const REGISTER_URL = `/api/Issue/GetIssueExcel`;
    return new Observable(obs => {
      var oReq = new XMLHttpRequest();
      oReq.open("POST", REGISTER_URL, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      oReq.send(body);
    });
  }

  public deleteAttach(attach) {
    const REGISTER_URL = `/api/Issue/DeleteAttach`;
      return this.dataService.post(REGISTER_URL, attach).pipe(
          map((res) => {
              return true;
          }));
  }

  public getattachlist(issueId): Observable<IssueAttachmentUrlModel[]> {

    const data: IssueAttachmentModel = {
      issueId: issueId
      , applicationId: undefined
      , createdDate: undefined
      , fileName: undefined
      , fileSize: undefined
      , id: undefined
      , isEnable: undefined
      , userId: undefined
      , contentType: undefined
      , attachment: undefined
    };

    const REGISTER_URL = `/api/Issue/AttachmentListUrl`;

      return this.dataService.post<IssueAttachmentUrlModel[]>(REGISTER_URL, data);
  }

  public getattachment(model: IssueAttachmentModel): Observable<any> {
    const REGISTER_URL = `/api/Issue/GetAttachment`;
    return new Observable(obs => {
      var oReq = new XMLHttpRequest();
      oReq.open("POST", REGISTER_URL, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      oReq.send(body);
    });
  }

  public gethistorylist(issueId): Observable<IssueHistoryModel[]> {

    const data: IssueHistoryModel = {
      issueId: issueId
      , applicationId: undefined
      , createdDate: undefined
      , oldValue: undefined
      , newValue: undefined
      , id: undefined
      , userName: undefined
      , fieldChanged: undefined
    };

    const REGISTER_URL = `/api/Issue/HistoryList`;

    return this.dataService.post<IssueHistoryModel[]>(REGISTER_URL, data);
  }

  public getuserwithimage(model: IssueSearchTypeModel): Observable<any> {
    const REGISTER_URL = `/api/Issue/GetUserWithPhoto`;
    return this.dataService.post<UserModel>(REGISTER_URL, model);
  }

  public getcurrentuserwithimage(): Observable<any> {
    const REGISTER_URL = `/api/Issue/GetCurrentUserWithPhoto`;
    return this.dataService.post<UserModel>(REGISTER_URL);
  }

  public updateissuedescription(issue) {
    const REGISTER_URL = `/api/Issue/UpdateIssueDescription`;
      return this.dataService.post(REGISTER_URL, issue).pipe(
          map((res) => {
              return true;
          }));
  }

  public getcommentlist(issueId): Observable<IssueCommentModel[]> {

    const data: IssueCommentModel = {
        issueId: issueId
      , applicationId: undefined
      , createdDate: undefined
      , id: undefined
      , name: undefined
      , userId: undefined
      , comment: undefined,
      email: undefined,
      profileImage: undefined,
      isUpdate: false
    };

    const REGISTER_URL = `/api/Issue/CommentList`;

    return this.dataService.post<IssueCommentModel[]>(REGISTER_URL, data);
  }

  public addcomment(commentt): Observable<IssueCommentModel> {
    const REGISTER_URL = `/api/Issue/AddComment`;
    return this.dataService.post<IssueCommentModel>(REGISTER_URL, commentt);
  }

  public updatecomment(commentt): Observable<IssueCommentModel> {
    const REGISTER_URL = `/api/Issue/UpdateComment`;
    return this.dataService.post<IssueCommentModel>(REGISTER_URL, commentt);
  }


  public addissuetype(issuetype): Observable<IssueTypeModel> {
    const REGISTER_URL = `/api/issue/AddIssueType`;
    return this.dataService.post<IssueTypeModel>(REGISTER_URL, issuetype);
  }

  public updateissuetype(issuetype) {
    const REGISTER_URL = `/api/issue/AddIssueType`;
      return this.dataService.post(REGISTER_URL, issuetype).pipe(
          map((res) => {
              return true;
          }));
  }

  public deleteissuetype(issuetype) {
    const REGISTER_URL = `/api/issue/DeleteIssueType`;
      return this.dataService.post(REGISTER_URL, issuetype).pipe(
          map((res) => {
              return true;
          }));
  }

  public addissueresolution(issueresolution): Observable<IssueResolutionModel> {
    const REGISTER_URL = `/api/issue/AddIssueResolution`;
    return this.dataService.post<IssueResolutionModel>(REGISTER_URL, issueresolution);
  }

  public updateissueresolution(issueresolution) {
    const REGISTER_URL = `/api/issue/AddIssueResolution`;
      return this.dataService.post(REGISTER_URL, issueresolution).pipe(
          map((res) => {
              return true;
          }));
  }

  public deleteissueresolution(issueresolution) {
    const REGISTER_URL = `/api/issue/DeleteIssueResolution`;
      return this.dataService.post(REGISTER_URL, issueresolution).pipe(
          map((res) => {
              return true;
          }));
  }

  public addissuestatus(issuestatus): Observable<IssueStatusModel> {
    const REGISTER_URL = `/api/issue/AddIssueStatus`;
    return this.dataService.post<IssueStatusModel>(REGISTER_URL, issuestatus);
  }

  public updateissuestatus(issuestatus) {
    const REGISTER_URL = `/api/issue/AddIssueStatus`;
      return this.dataService.post(REGISTER_URL, issuestatus).pipe(
          map((res) => {
              return true;
          }));
  }

  public deleteissuestatus(issuestatus) {
    const REGISTER_URL = `/api/issue/DeleteIssueStatus`;
      return this.dataService.post(REGISTER_URL, issuestatus).pipe(
          map((res) => {
              return true;
          }));
  }


  public addissueprocesstype(issuecategory) {
    const REGISTER_URL = `/api/issue/AddIssueProcessType`;
      return this.dataService.post(REGISTER_URL, issuecategory).pipe(
          map((res) => {
              return true;
          }));
  }

  public updateissueprocesstype(issuecategory) {
    const REGISTER_URL = `/api/issue/AddIssueProcessType`;
      return this.dataService.post(REGISTER_URL, issuecategory).pipe(
          map((res) => {
              return true;
          }));
  }

  public deleteissueprocesstype(issuecategory) {
    const REGISTER_URL = `/api/issue/DeleteIssueProcessType`;
      return this.dataService.post(REGISTER_URL, issuecategory).pipe(
          map((res) => {
              return true;
          }));
  }

  public gettimesheetslist(timesheets) {

    const REGISTER_URL = `/api/Issue/Timesheets`;
    return this.dataService.post<IssueTimesheetsModel[]>(REGISTER_URL, timesheets);
  }

  public getactivetimesheet(timesheets): Observable<IssueTimesheetsModel> {
    const REGISTER_URL = `/api/Issue/GetActiveTimesheet`;
    return this.dataService.post<IssueTimesheetsModel>(REGISTER_URL, timesheets);
  }

  public deletetimesheets(timesheets) {
    const REGISTER_URL = `/api/issue/DeleteTimesheets`;
      return this.dataService.post(REGISTER_URL, timesheets).pipe(
          map((res) => {
              return true;
          }));
  }

  public starttimesheets(timesheets) {
    const REGISTER_URL = `/api/issue/StartTimeSheet`;
      return this.dataService.post(REGISTER_URL, timesheets).pipe(
          map((res) => {
              return true;
          }));
  }

  public closetimesheets(timesheets) {
    const REGISTER_URL = `/api/issue/CloseTimeSheet`;
      return this.dataService.post(REGISTER_URL, timesheets).pipe(
          map((res) => {
              return true;
          }));
  }

  public getTimeSheetAvailability(timesheets): Observable<TimeSheetsWorkingModel[]> {
    const REGISTER_URL = `/api/Issue/GetTimeSheetAvailability`;
    return this.dataService.post<TimeSheetsWorkingModel[]>(REGISTER_URL, timesheets);
  }

  public saveTimeSheet(timesheets) {
    const REGISTER_URL = `/api/issue/SaveTimeSheet`;
      return this.dataService.post(REGISTER_URL, timesheets).pipe(
          map((res) => {
              return true;
          }));
  }

  public getworkinglist(searchParam) {
    const REGISTER_URL = `/api/Issue/GetWorkingTimeList`;
    return this.dataService.post<WorkingTimeListModel[]>(REGISTER_URL, searchParam);
  }

  public getworkinggrouplist(searchParam) {
    const REGISTER_URL = `/api/Issue/GetWorkingGroupTimeList`;
    return this.dataService.post<WorkingGroupTimeListModel[]>(REGISTER_URL, searchParam);
  }

  public getworkinggroupbydaylist(searchParam) {
    const REGISTER_URL = `/api/Issue/GetWorkingGroupTimeByDayList`;
    return this.dataService.post<WorkingGroupByDayTimeListModel[]>(REGISTER_URL, searchParam);
  }

  public getworkingtimeexcel(model: WorkingTimeListModel): Observable<any> {
    const REGISTER_URL = `/api/Issue/GetworkingTimeExcel`;
    return new Observable(obs => {
      var oReq = new XMLHttpRequest();
      oReq.open("POST", REGISTER_URL, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      oReq.send(body);
    });
    }


    public GetSelectReportProjectUserList(searchParam) {
        const REGISTER_URL = `/api/Issue/GetSelectReportProjectUserList`;
        return this.dataService.post<ReportProjectIssues[]>(REGISTER_URL, searchParam);
    }

    public GetSelectReportProjectUserListexcel(model: ReportProjectIssuesSearch): Observable<any> {
        const REGISTER_URL = `/api/Issue/GetSelectReportProjectUserListExcel`;
        return new Observable(obs => {
            var oReq = new XMLHttpRequest();
            oReq.open("POST", REGISTER_URL, true);
            oReq.setRequestHeader("content-type", "application/json");
            oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
            oReq.responseType = "arraybuffer";

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response;
                var byteArray = new Uint8Array(arrayBuffer);
                obs.next(byteArray);
            };

            const body = JSON.stringify(model);
            oReq.send(body);
        });
    }

    public GetSelectReportSummaryProjectUserList(searchParam) {
        const REGISTER_URL = `/api/Issue/GetSelectReportSummaryProjectUserList`;
        return this.dataService.post<ReportProjectIssuesSummary[]>(REGISTER_URL, searchParam);
    }

    //enes 
    public SelectReportAssignedSummaryList(searchParam) {
        const REGISTER_URL = `/api/Issue/SelectReportAssignedSummaryList`;
        return this.dataService.post<ReportProjectIssuesAssignedSummary[]>(REGISTER_URL, searchParam);
    }
    //enes

    //enes yeni
    public SelectReportAssignedUserSummaryList(searchParam) {
        const REGISTER_URL = `/api/Issue/SelectReportAssignedUserSummaryList`;
        return this.dataService.post<ReportProjectIssuesAssignedUserSummary[]>(REGISTER_URL, searchParam);
    }
    //enes yeni

    public GetSelectReportSummaryProjectUserListExcel(model: ReportProjectIssuesSearch): Observable<any> {
     const REGISTER_URL = `/api/Issue/GetSelectReportSummaryProjectUserListExcel`;
        return new Observable(obs => {
            var oReq = new XMLHttpRequest();
            oReq.open("POST", REGISTER_URL, true);
            oReq.setRequestHeader("content-type", "application/json");
            oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
            oReq.responseType = "arraybuffer";

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response;
                var byteArray = new Uint8Array(arrayBuffer);
                obs.next(byteArray);
            };

            const body = JSON.stringify(model);
            oReq.send(body);
        });
    }

    //enes
    public GetSelectReportSummaryAssignedProjectUserListExcel(model: ReportAssignedProjectIssuesSearch): Observable<any> {
        const REGISTER_URL = `/api/Issue/GetSelectReportAssignedSummaryProjectUserListExcel`;
        return new Observable(obs => {
            var oReq = new XMLHttpRequest();
            oReq.open("POST", REGISTER_URL, true);
            oReq.setRequestHeader("content-type", "application/json");
            oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
            oReq.responseType = "arraybuffer";

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response;
                var byteArray = new Uint8Array(arrayBuffer);
                obs.next(byteArray);
            };

            const body = JSON.stringify(model);
            oReq.send(body);
        });
    }
    //enes

    
    //enes yeni
    public GetSelectReportSummaryAssignedUserProjectUserListExcel(model: ReportAssignedUserProjectIssuesSearch): Observable<any> {
        const REGISTER_URL = `/api/Issue/GetSelectReportAssignedUserSummaryProjectUserListExcel`;
        return new Observable(obs => {
            var oReq = new XMLHttpRequest();
            oReq.open("POST", REGISTER_URL, true);
            oReq.setRequestHeader("content-type", "application/json");
            oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
            oReq.responseType = "arraybuffer";

            oReq.onload = function (oEvent) {
                var arrayBuffer = oReq.response;
                var byteArray = new Uint8Array(arrayBuffer);
                obs.next(byteArray);
            };

            const body = JSON.stringify(model);
            oReq.send(body);
        });
    }
    //enes yeni

}
