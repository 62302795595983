import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
    selector: 'app-form-textarea',
    styleUrls: ['form-textarea.component.scss'],
    templateUrl: 'form-textarea.component.html'
})
export class FormTextareaComponent extends FieldBaseComponent { }
