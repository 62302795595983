import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { AppSharedModule } from '../../../appshared';
import { ListModule } from '../../../../@fury/shared/list/list.module';
import { PageModule } from '../../../../@fury/shared/page/page.module';
import { BreadcrumbsModule } from '../../../../@fury/shared/breadcrumbs/breadcrumbs.module';
import { CompanyRoutingModule } from './company-all-in-one-table-routing.module';
import { CompanyCreateUpdateModule } from './company-create-update/company-create-update.module';
import { CompanyComponent } from './company.component';
import { FurySharedModule } from '../../../../@fury/fury-shared.module';
import { FuryCardModule } from '../../../../@fury/shared/card/card.module';


@NgModule({
  imports: [
    CommonModule,
    CompanyRoutingModule,
    FormsModule,
        MaterialModule,
        AppSharedModule,

    // Core
    ListModule,
    CompanyCreateUpdateModule,
    PageModule,
        BreadcrumbsModule,
        FurySharedModule,
        FuryCardModule

  ],
  declarations: [CompanyComponent],
  exports: [CompanyComponent]
})
export class CompanyModule {
}
