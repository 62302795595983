import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IssueDetailComponent } from './issue-detail.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule, MatInputModule, MatButtonModule, MatIconModule, MatRadioModule, MatSelectModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IssueTimerModule } from './issue-timer/issue-timer.module';
import { IssueTimerAddModule } from './issue-timer/issue-timer-add/issue-timer-add.module';
import { AppSharedModule } from '../../../appshared';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { ListModule } from '../../../../@fury/shared/list/list.module';
import { PageModule } from '../../../../@fury/shared/page/page.module';
import { BreadcrumbsModule } from '../../../../@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from '../../../../@fury/shared/card/card.module';
import { LoadingOverlayModule } from '../../../../@fury/shared/loading-overlay/loading-overlay.module';
import { ScrollbarModule } from '../../../../@fury/shared/scrollbar/scrollbar.module';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { IssueDetaillRoutingModule } from './issue-detail-routing';
import { FurySharedModule } from '../../../../@fury/fury-shared.module';
import { IssueAttachDisplayModule } from './issue-attach-display/issue-attach-display.module';
import { LinkyModule } from 'ngx-linky';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
        ReactiveFormsModule,
  
    // Core
        FurySharedModule,
        AppSharedModule,
   
    ListModule,
    PageModule,
    BreadcrumbsModule,
    FuryCardModule,
    LoadingOverlayModule,
    ScrollbarModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    RoundProgressModule,
    IssueTimerModule,
        IssueTimerAddModule,
        IssueDetaillRoutingModule,
        IssueAttachDisplayModule,
        LinkyModule
  ],
  declarations: [IssueDetailComponent],
  exports: [IssueDetailComponent]
})
export class IssueDetailModule {
}
