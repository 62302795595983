import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, Injector, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryModel } from '../issue-models/category.model';
import { IssueTypeModel } from '../issue-models/issuetype.model';
import { IssueStatusModel } from '../issue-models/issuestatus.model';
import { IssueResolutionModel } from '../issue-models/issueresolution.model';
import { IssueAttachmentModel } from '../issue-models/issueattachment.model';
import { IssueHistoryModel } from '../issue-models/issuehistory.model';
import { IssueDetailSearchTypeModel } from '../issue-models/issuedetailsearch.model';
import { IssueListModel } from '../issue-models/issuelist.model';
import { DateAdapter, MAT_DATE_FORMATS, MatDialog } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../issue-search/date.adapter';
import { IssueSearchTypeModel } from '../issue-models/issuesearch.model';
import { IssueDescUpdateModel } from '../issue-models/issuedescupdate.model';
import { IssueCommentModel } from '../issue-models/issuecomments.model';
import { IssueTimerAddComponent } from './issue-timer/issue-timer-add/issue-timer-add.component';
import { IssueTimesheetsModel } from '../issue-models/timesheets.model';
import { IssueAttachDisplayComponent } from './issue-attach-display/issue-attach-display.component';
import { fadeOutAnimation } from '../../../../@fury/animations/route.animation';
import { ScrollbarDirective } from '../../../../@fury/shared/scrollbar/scrollbar.directive';
import { GroupModel } from '../../../models/user-models/group.model';
import { UserModel } from '../../../models/user-models/user.model';
import { ProjectListModel } from '../../../models/project-models/projectlist.model';
import { Customer } from '../../../models/customermodels/customer.model';
import { AppService } from '../../../services/app.service';
import { IssueService } from '../../../services/issue.service';
import { ProjectService } from '../../../services/project.service';
import { CommonService } from '../../../services/common.service';
import { ToastrService } from '../../../toastr';
import { DialogModel } from '../../../models/dialog.model';
import { DemoDialogComponent } from '../../components/dialogs/dialogs.component';
import { fadeInUpStaggerAnimation, fadeInUpAnimation } from '../../../../@fury/animations/fade-in-up.animation';
import { fadeInRightAnimation, fadeInRightStaggerAnimation } from '../../../../@fury/animations/fade-in-right.animation';
import { IssueAttachmentUrlModel } from '../issue-models/issueattachmenturl.model';

@Component({
    selector: 'fury-issue-detail',
    templateUrl: './issue-detail.componentv191003.html',
    styleUrls: ['./issue-detail.componentv191003.scss'],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ],
    animations: [fadeInRightAnimation, fadeInUpAnimation],
    encapsulation: ViewEncapsulation.None
})
export class IssueDetailComponent implements OnInit, AfterViewInit {
    //@ViewChild('messagesScroll', { static: true }) messagesScroll: ScrollbarDirective;

    form: FormGroup;
    form2: FormGroup;
    form3: FormGroup;

    issueid: number;
    companyissueid: number;
    companyIdReference: string;

    issuelist: IssueListModel;

    groupsModel: GroupModel[];
    selectedGroup: number;
    userModel: UserModel[];
    tempuserModel: UserModel[];
    selectedUser: number;
    categoryModel: CategoryModel[];
    tempcategoryModel: CategoryModel[];
    selectedCategory: number;
    issueTypeModel: IssueTypeModel[];
    tempIssueTypeModel: IssueTypeModel[];
    selectedIssueType: number;
    issueStatusModel: IssueStatusModel[];
    tempStatusModel: IssueStatusModel[];
    selectedIssueStatus: number;
    issueResolutionModel: IssueResolutionModel[];
    tempResolutionModel: IssueResolutionModel[];
    selectedResolutionType: number;

    issueAttachmentsModel: IssueAttachmentUrlModel[];
    issueHistoryModel: IssueHistoryModel[];
    beforeDeleteCurrentAttach: IssueAttachmentModel;
    private _deleteresult: string;
    get deleteresult(): string {
        return this._deleteresult;
    }
    set deleteresult(val: string) {
        this._deleteresult = val;
    }

    selectedCustomDate: Date;
    customDueDate: boolean = false;
    issueStartDate: Date;
    nowDate: Date = new Date();

    selectedFile: any;
    filedetailToUpload: any;

    normal: boolean;
    urgent: boolean;
    critical: boolean;

    existingImage: any;
    existingFile: any;
    asssigneduser: UserModel;
    requesteruser: UserModel;
    currentuser: UserModel;

    projectmodel: ProjectListModel[];
    selectedprojectmodel: ProjectListModel[];

    commentlist: IssueCommentModel[];

    issueUpdateMode: boolean = false;

    replyCtrl: string;
    currenUserIsOwner: boolean = false;

    progressdata: number = 0;
    progresscolor: string = '#29b955';

    timer: any;
    counter = 0;
    tick = 1000;
    lbltimer: any;
    interval: any;
    started: boolean;
    activetimesheet: IssueTimesheetsModel;

    imageparameter: string;
    downloading: boolean;


    customerTypeModel: Customer[];
    filteredcustomerTypeModel: Customer[];
    selectedCustomerData: number;
    selectedCustomer: string;
    showCustomer: boolean = false;

    isAdminOrUp: boolean = false;

    customFiltereduserModel: UserModel[];
    customFilterSelectedUserData: number;
    customFilterSelectedUser: string;
    _userListFilter: string;

    _listFilter: string;

    get userListFilter(): string {
        return this._userListFilter;
    }

    set userListFilter(value: string) {
        this._userListFilter = value;
        this.customFiltereduserModel = this.userListFilter ? this.userformFilter(this.userListFilter) : this.userModel;
    }

    get listFilter(): string {
        return this._listFilter;
    }

    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredcustomerTypeModel = this.listFilter ? this.performFilter(this.listFilter) : this.customerTypeModel;
    }

    x() {
        console.log("asdasd")
    }

    constructor(private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private _route: ActivatedRoute,
        private _router: Router,
        private fb: FormBuilder,
        private injector: Injector,
        private appService: AppService,
        private readonly issueService: IssueService,
        private readonly projectService: ProjectService,
        private commonService: CommonService) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            issueStatusId: [null, Validators.nullValidator],
            issueResolutionId: [null, Validators.nullValidator]
        });

        this.form2 = this.fb.group({
            issueAssignedGroupId: [null, Validators.nullValidator],
            issueAssignedUserId: [null, Validators.nullValidator],
            issueTypeId: [null, Validators.nullValidator],
            issueCategoryId: [null, Validators.nullValidator],
            issueDueDate: [null, Validators.nullValidator],
            issueStartCompDate: [null, Validators.nullValidator],
            issueCustomerId: [null, Validators.nullValidator],
        });

        this.form3 = this.fb.group({
            replyCtrl: [null, Validators.nullValidator],
            issueDescription: [null, Validators.nullValidator],
            commentDescription: [null, Validators.nullValidator]
        });

        const param = this._route.snapshot.paramMap.get('id');
        if (param) {
            try {
                const id = param;
                this.issueid = parseInt(atob(id));
            } catch (e) {
                this._router.navigate(['/issuelist']);
            }
        }
        else {
            this._router.navigate(['/issuelist']);
        }

        this.existingImage = '../../../../../../assets/img/avatars/defaultProfile.jpg';
        this.existingFile = '../../../../../../assets/img/avatars/attach_file.png';

        this.getData();

    }

    ngAfterViewInit() {
        this.selectCategoryCustomDueDate(this.selectedCategory);
        this.progressDataView();
    }

    getData() {

        this.projectService.getprojectlist()
            .subscribe(response => {
                if (response) {
                    this.projectmodel = response;
                }
                else {
                }
            });

        this.projectService.getallgroups()
            .subscribe(response => {
                if (response) {
                    this.groupsModel = response;
                }
                else {
                }
            });

        this.projectService.userlistsimpleforuserdetail()
            .subscribe(response => {
                if (response) {
                    this.userModel = response;
                    this.customFiltereduserModel = response;

                    if (this.customFilterSelectedUserData && this.userModel) {
                        if (this.userModel.findIndex((user) => user.id === this.customFilterSelectedUserData) !== -1) {
                            this.userListFilter = (this.userModel.filter((type: UserModel) =>
                                (type.id === this.customFilterSelectedUserData)))[0].firstName;
                        }

                    }
                    else {

                        this.customFiltereduserModel = this.userModel;

                    }
                }
                else {
                }
            });

        this.issueService.getallcategories()
            .subscribe(response => {
                if (response) {
                    this.categoryModel = response;
                    if (this.selectedCategory) {
                        this.selectCategoryCustomDueDate(this.selectedCategory);
                    }
                }
                else {
                }
            });

        this.issueService.getallissuetypes()
            .subscribe(response => {
                if (response) {
                    this.issueTypeModel = response;
                }
                else {
                }
            });

        this.issueService.getallissuestatus()
            .subscribe(response => {
                if (response) {
                    this.issueStatusModel = response;
                }
                else {
                }
            });

        this.issueService.getallissueresolutions()
            .subscribe(response => {
                if (response) {
                    this.issueResolutionModel = response;
                }
                else {
                }
            });

        this.issueService.getcurrentuserwithimage()
            .subscribe(response => {
                if (response) {
                    this.currentuser = response;
                }
                else {
                }
            });


        this.getIssueDetail();

        this.getAttachList();

        this.getHistoryList();

        this.getCommentList();

        this.activetimesheetKontrol();

        this.isAdminOrSystemAdmin();
    }

    getIssueDetail() {
        const data: IssueDetailSearchTypeModel = {
            issueId: this.issueid
        };

        this.issueService.getissuebyid(data)
            .subscribe(response => {
                if (response) {
                    this.issuelist = response;
                    this.companyissueid = this.issuelist.companyId;
                    this.companyIdReference = this.issuelist.companyIdReference;

                    this.selectedIssueStatus = this.issuelist.issueStatusId;
                    this.selectedResolutionType = this.issuelist.resolutionId;
                    this.selectedGroup = this.issuelist.groupId;
                    this.selectedUser = this.issuelist.assignedUserId;
                    this.customFilterSelectedUserData = this.issuelist.assignedUserId;


                    if (this.customFilterSelectedUserData && this.userModel) {
                        if (this.userModel.findIndex((user) => user.id === this.customFilterSelectedUserData) !== -1) {
                            this.userListFilter = (this.userModel.filter((type: UserModel) =>
                                (type.id === this.customFilterSelectedUserData)))[0].firstName;
                        }
                    }

                    if (this.selectedUser) {
                        this.getUserInfo(this.selectedUser);
                    }

                    this.selectedIssueType = this.issuelist.issueTypeId;

                    this.selectedCategory = this.issuelist.categoryId;
                    this.selectCategoryCustomDueDate(this.selectedCategory);

                    if (this.issuelist.priorityId === 1) {
                        this.selectNormal();
                    }

                    if (this.issuelist.priorityId === 2) {
                        this.selectUrgent();
                    }

                    if (this.issuelist.priorityId === 3) {
                        this.selectCritical();
                    }

                    this.getRequesterUserWithImage(this.issuelist.createdBy);

                    //Pasif types
                    if (this.issueTypeModel) {
                        this.tempIssueTypeModel = this.issueTypeModel.filter((type: IssueTypeModel) =>
                            (type.isEnable === true) || (type.id === this.issuelist.issueTypeId));

                        this.issueTypeModel = this.tempIssueTypeModel;
                    }

                    if (this.issueResolutionModel) {
                        this.tempResolutionModel = this.issueResolutionModel.filter((type: IssueResolutionModel) =>
                            (type.isEnable === true) || (type.id === this.issuelist.resolutionId));

                        this.issueResolutionModel = this.tempResolutionModel;
                    }

                    if (this.issueStatusModel) {
                        this.tempStatusModel = this.issueStatusModel.filter((type: IssueStatusModel) =>
                            (type.isEnable === true) || (type.id === this.issuelist.issueStatusId));

                        this.issueStatusModel = this.tempStatusModel;
                    }

                    if (this.userModel) {
                        this.tempuserModel = this.userModel.filter((type: UserModel) =>
                            (type.isEnabled === true) || (type.id === this.issuelist.assignedUserId));

                        this.userModel = this.tempuserModel;
                    }

                    if (this.categoryModel) {
                        this.tempcategoryModel = this.categoryModel.filter((type: CategoryModel) =>
                            (type.isEnable === true) || (type.id === this.issuelist.categoryId));

                        this.categoryModel = this.tempcategoryModel;
                    }

                    this.selectedCustomDate = this.dateParse(this.issuelist.issueDueDate);
                    this.issueStartDate = this.dateParse(this.issuelist.issueStartDate);


                    this.selectedCustomerData = this.issuelist.issueCustomerId;
                    this.getCustomerList();

                    if (this.issuelist.projectId && this.projectmodel) {
                        this.selectedprojectmodel = this.projectmodel.filter((type: ProjectListModel) =>
                            (type.id === this.issuelist.projectId));
                        this.getuserGroupsForProject(this.selectedprojectmodel[0]);
                    }

                    this.progressDataView();

                }
                else {
                    this._router.navigate(['/issuelist']);
                }
            });
    }

    progressDataView() {
        if (this.customDueDate) {
            if (this.issuelist.overDue === '1') {
                this.progressdata = 100;
                this.progresscolor = '#ff0000ff';
            }
            else {
                this.progressdata = this.percanyageDateDiff();
                if (this.progressdata < 0) {
                    this.progressdata = 100;
                    this.progresscolor = '#ff0000ff';
                    return;
                }

                if (this.progressdata <= 50) {
                    this.progresscolor = '#29b955';
                }
                else if ((this.progressdata > 50) && (this.progressdata <= 75)) {
                    this.progresscolor = '#ffff00ff';
                }
                else {
                    this.progresscolor = '#ff8a06ff';
                }
            }

        }
    }

    percanyageDateDiff() {
        if (this.issueStartDate > this.nowDate) {
            return 0;
        }

        var diff = Math.abs((this.selectedCustomDate.getTime() + (1000 * 60 * 60 * 24)) - this.issueStartDate.getTime());
        var diffnow = Math.abs((this.selectedCustomDate.getTime() + (1000 * 60 * 60 * 24)) - (this.nowDate.getTime()));
        //var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 

        var totalminutes = Math.floor((diff / 1000) / 60);
        var remainingminutes = totalminutes - (Math.floor((diffnow / 1000) / 60));

        return Math.floor((remainingminutes / totalminutes) * 100);

    }

    dateParse(data: any) {
        if (data !== undefined && data !== '' && data !== null) {
            const str = data.split('-');
            const year = Number(str[0]);
            const month = Number(str[1]) - 1;
            const datestr = str[2].substring(0, 2);
            const date = Number(datestr);
            return new Date(year, month, date);
        }
        else {
            return this.nowDate;
        }
    }

    issuesave() {

        this.issuelist.id = this.issueid;

        if ((this.form2.controls.issueAssignedGroupId.value === undefined) || (this.form2.controls.issueAssignedGroupId.value === undefined) || (this.form2.controls.issueAssignedGroupId.value === null)) {
            this.issuelist.groupId = undefined;
        }
        else {
            this.issuelist.groupId = this.form2.controls.issueAssignedGroupId.value;
        }

        //if ((this.form2.controls.issueassigneduserid.value === undefined) || (this.form2.controls.issueassigneduserid.value === undefined) || (this.form2.controls.issueassigneduserid.value === null)) {
        //    this.issuelist.assigneduserid = undefined;
        //}
        //else {
        //    this.issuelist.assigneduserid = this.form2.controls.issueassigneduserid.value;
        //}

        if ((this.form2.controls.issueTypeId.value === undefined) || (this.form2.controls.issueTypeId.value === undefined) || (this.form2.controls.issueTypeId.value === null)) {
            this.issuelist.issueTypeId = undefined;
        }
        else {
            this.issuelist.issueTypeId = this.form2.controls.issueTypeId.value;
        }

        if ((this.form.controls.issueStatusId.value === undefined) || (this.form.controls.issueStatusId.value === undefined) || (this.form.controls.issueStatusId.value === null)) {
            this.issuelist.issueStatusId = undefined;
        }
        else {
            this.issuelist.issueStatusId = this.form.controls.issueStatusId.value;
        }

        if ((this.form.controls.issueResolutionId.value === undefined) || (this.form.controls.issueResolutionId.value === undefined) || (this.form.controls.issueResolutionId.value === null)) {
            this.issuelist.resolutionId = undefined;
        }
        else {
            this.issuelist.resolutionId = this.form.controls.issueResolutionId.value;
        }

        if ((this.form2.controls.issueCategoryId.value === undefined) || (this.form2.controls.issueCategoryId.value === undefined) || (this.form2.controls.issueCategoryId.value === null)) {
            this.issuelist.categoryId = undefined;
        }
        else {
            this.issuelist.categoryId = this.form2.controls.issueCategoryId.value;
        }

        if ((!this.customDueDate) || (this.form2.controls.issueDueDate.value === undefined) || (this.form2.controls.issueDueDate.value === undefined) || (this.form2.controls.issueDueDate.value === null)) {
            this.issuelist.issueDueDate = undefined;
        }
        else {
            const deger: Date = this.form2.controls.issueDueDate.value;

            var mm = deger.getMonth() + 1;
            var dd = deger.getDate();
            var yyyy = deger.getFullYear();
            var formattedDate = yyyy + '-' + mm + '-' + dd;

            this.issuelist.issueDueDate = formattedDate;

            const startdeger: Date = this.form2.controls.issueStartCompDate.value;
            var mm = startdeger.getMonth() + 1;
            var dd = startdeger.getDate();
            var yyyy = startdeger.getFullYear();
            const formattedDateBas: Date = new Date(yyyy, startdeger.getMonth(), dd, 23, 59, 59);

            let formattedDateBitis: Date;
            const degerbitis: Date = this.form2.controls.issueDueDate.value;
            var mm = degerbitis.getMonth() + 1;
            var dd = degerbitis.getDate();
            var yyyy = degerbitis.getFullYear();
            formattedDateBitis = new Date(yyyy, degerbitis.getMonth(), dd, 23, 59, 59);


            if ((formattedDateBas > formattedDateBitis)) {
                const message = this.appService.appData.content['app_issue_create_start_date_not_bigger_than_due_date'] || 'app_issue_create_start_date_not_bigger_than_due_date';
                this.injector.get(ToastrService).error(message);
                return;
            }

        }

        this.issuelist.issueCustomerId = this.selectedCustomerData;
        this.issuelist.assignedUserId = this.customFilterSelectedUserData;

        this.issuelist.priorityId = undefined;

        if (this.normal) {
            this.issuelist.priorityId = 1;
        }

        if (this.urgent) {
            this.issuelist.priorityId = 2;
        }

        if (this.critical) {
            this.issuelist.priorityId = 3;
        }

        this.issueService.updateissue(this.issuelist)
            .subscribe(response => {
                if (response) {
                    const message = this.appService.appData.content['app_issue_update_success'] || 'app_issue_update_success';
                    this.injector.get(ToastrService).success(message);

                    this._router.navigate(['/issuelist']);
                }
                else {
                    const message = this.appService.appData.content['app_issue_update_failed'] || 'app_issue_update_failed';
                    this.injector.get(ToastrService).error(message);
                }
            });
    }

    backtoissuelist() {
        this._router.navigate(['/issuelist']);
    }

    selectCategoryCustomDueDate(categoryid: number) {
        if (categoryid && this.categoryModel) {
            const index = this.categoryModel.findIndex((category) => category.id === categoryid);
            const cat = this.categoryModel[index];
            if (cat.isCustomDueDate === true) {
                this.customDueDate = true;
            }
            else {
                this.customDueDate = false;
            }

            if (cat.customerRelated === true) {
                this.showCustomer = true;
            }
            else {
                this.showCustomer = false;
            }

        }
        else {
            this.customDueDate = false;
            this.showCustomer = false;
        }



        this.cd.markForCheck();
    }

    selectNormal() {
        this.normal = true;
        this.urgent = false;
        this.critical = false;
    }

    selectUrgent() {
        this.normal = false;
        this.urgent = true;
        this.critical = false;
    }

    selectCritical() {
        this.normal = false;
        this.urgent = false;
        this.critical = true;
    }

    isSystemAdmin() {
        let fullRoles = atob(localStorage.getItem('1ZXYL@M'));

        if (fullRoles === null) {
            fullRoles = "User";
        }
        if (fullRoles.search("SystemAdmin") !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    isAdminOrSystemAdmin() {
        let fullRoles = atob(localStorage.getItem('1ZXYL@M'));

        if (fullRoles === null) {
            fullRoles = "User";
        }

        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {
            this.isAdminOrUp = true;
        }
        else {
            this.isAdminOrUp = false;
        }
    }


    //History List
    getHistoryList() {
        this.issueService.gethistorylist(this.issueid)
            .subscribe(response => {
                if (response) {
                    this.issueHistoryModel = response;
                }
                else {
                }
            });
    }

    //Download Upload Attachments START
    getAttachList() {
        this.issueService.getattachlist(this.issueid)
            .subscribe(response => {
                if (response) {
                    this.issueAttachmentsModel = response;
                }
                else {
                }
            });
    }

    uploadAttach() {
        let controlfile: boolean = true;

        //if (this.filedetailToUpload) {
        //  let filename: string = this.filedetailToUpload.name;
        //  filename = filename.toLocaleLowerCase();
        //  if ((filename.search('.doc') === -1) && (filename.search('.docx') === -1) &&
        //    (filename.search('.xls') === -1) && (filename.search('.xlsx') === -1) &&
        //    (filename.search('.pdf') === -1) && (filename.search('.txt') === -1) &&
        //    (filename.search('.rtf') === -1) && (filename.search('.pts') === -1) &&
        //    (filename.search('.ptsx') === -1) && (filename.search('.zip') === -1) &&
        //    (filename.search('.rar') === -1) && (filename.search('.7z') === -1) &&
        //    (filename.search('.jpeg') === -1) && (filename.search('.jpg') === -1) &&
        //    (filename.search('.png') === -1) && (filename.search('.gif') === -1) && (filename.search('.msg') === -1)) {

        //    const message = this.appService.appData.content['app_issue_create_file_type_invalid'] || 'app_issue_create_file_type_invalid';
        //      this.injector.get(ToastrService).error(message);
        //    controlfile = false;
        //  }
        //}

        if (controlfile) {
            const file = new FormData();
            file.append('file', this.filedetailToUpload);

            const options = {
                issueid: this.issueid
            };

            if (this.filedetailToUpload) {
                this.issueService.uploadissuefile(file, options)
                    .subscribe(response => {
                        if (response) {
                            this.getAttachList();
                            this.clearAttach();
                            this.getHistoryList();
                        }
                        else {

                        }
                    });

            }
            else {

            }
        }
    }

    downloadattach(attach) {
        this.downloading = true;
        this.issueService.getattachment(attach).subscribe(result => {
            this.downloadFile(result, attach.contentType, attach.fileName);
        });
    }

    downloadFile(blob: any, type: string, filename: string) {

        var binaryData = [];
        binaryData.push(blob);

        const url = window.URL.createObjectURL(new Blob(binaryData, { type: type })); // <-- work with blob directly

        // create hidden dom element (so it works in all browsers)
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);

        // create file, attach to hidden element and open hidden element
        a.href = url;
        a.download = filename;
        a.click();
        this.downloading = false;
    }


    imageControl(attach) {
        if (attach.contentType == 'image/jpg' || attach.contentType == 'image/jpeg' || attach.contentType == 'image/gif' || attach.contentType == 'image/png') {
            return attach.url;
        }
        else {
            return this.existingFile;
        }
    }

    openInput() {
        // your can use ElementRef for this later
        document.getElementById("fileissuedetail").click();

        this.downloading = false;
    }

    clearAttach() {
        this.selectedFile = "";
        this.filedetailToUpload = "";
    }

    fileChange(event: any) {
        if (event.target.files && event.target.files[0]) {
            this.filedetailToUpload = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.selectedFile = {
                    mimetype: e.target.result.split(',')[0].split(':')[1].split(';')[0],
                    url: e.target.result
                };
            };
            reader.readAsDataURL(this.filedetailToUpload);
        }
    }

    deleteAttach(attach) {

        this.issueService.deleteAttach(attach)
            .subscribe(response => {
                if (response) {
                    this.getAttachList();
                    this.clearAttach();
                }
                else {

                }
            });

    }


    beforedeleteAttach(attach) {
        this.beforeDeleteCurrentAttach = attach;

        const messageHeader = this.appService.appData.content['app_warning_dialog_header'] || 'app_warning_dialog_header';
        const messageContent = this.appService.appData.content['app_warning_dialog_delete_account'] || 'app_warning_dialog_delete_account';
        const messageYes = this.appService.appData.content['app_warning_dialog_yes'] || 'app_warning_dialog_yes';
        const messageNo = this.appService.appData.content['app_warning_dialog_no'] || 'app_warning_dialog_no';
        const dialogData: DialogModel = { header: messageHeader, content: messageContent, nobtn: messageNo, yesbtn: messageYes };

        this.commonService.dialogdata.next(dialogData);

        this.openDialog();
    }

    openDialog() {
        this.dialog.open(DemoDialogComponent, {
            disableClose: false
        }).afterClosed().subscribe(result => {
            this.deleteresult = result;
            if (this.deleteresult === 'Yes') {
                this.deleteAttach(this.beforeDeleteCurrentAttach);
            }
        });
    }

    // Download Upload Attachments END

    //Usergroup user START
    getUserList(groupid: number) {
        this.projectService.getuserlistsimplebygroupid(groupid)
            .subscribe(response => {
                if (response) {
                    //this.userModel = response;
                    this.userModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));

                    this.selectedUser = null;
                    this.asssigneduser = null;
                    this.customFilterSelectedUserData = null;
                    this.customFilterSelectedUser = null;
                    this.userListFilter = null;
                }
                else {

                }

            });
    }

    getUserInfo(assigneduserid: number) {
        if (assigneduserid) {
            this.getImage(assigneduserid);
        }
    }

    private getImage(assigneduserid: number) {
        const data: IssueSearchTypeModel = {
            issueId: this.issueid,
            endDate: undefined,
            issueAssignedGroupId: undefined,
            issueAssignedUserId: assigneduserid,
            issueAssigningUserId: undefined,
            issueCategoryId: undefined,
            issuePriorityId: undefined,
            issueResolutionId: undefined,
            issueStatusId: undefined,
            issueTitle: undefined,
            issueTypeId: undefined,
            startDate: undefined,
            isActive: undefined,
            isOverDue: undefined,
            topCount: undefined,
            issueCustomerId: undefined,
            companyIdReference: undefined,
            issueProjectId: undefined
        };
        this.existingImage = '../../../../../../assets/img/avatars/defaultProfile.jpg';

        this.issueService.getuserwithimage(data)
            .subscribe(response => {
                if (response) {
                    this.asssigneduser = new UserModel(response);
                }
                else {

                }
            });
    }

    private getRequesterUserWithImage(requesteruserid: number) {
        const data: IssueSearchTypeModel = {
            issueId: this.issueid,
            endDate: undefined,
            issueAssignedGroupId: undefined,
            issueAssignedUserId: requesteruserid,
            issueAssigningUserId: undefined,
            issueCategoryId: undefined,
            issuePriorityId: undefined,
            issueResolutionId: undefined,
            issueStatusId: undefined,
            issueTitle: undefined,
            issueTypeId: undefined,
            startDate: undefined,
            isActive: undefined,
            isOverDue: undefined,
            topCount: undefined,
            issueCustomerId: undefined,
            companyIdReference: undefined,
            issueProjectId: undefined
        };
        this.existingImage = '../../../../../../assets/img/avatars/defaultProfile.jpg';

        this.issueService.getuserwithimage(data)
            .subscribe(response => {
                if (response) {
                    this.requesteruser = new UserModel(response);

                    if (this.currentuser) {
                        if (this.requesteruser.id === this.currentuser.id) {
                            this.currenUserIsOwner = true;
                        }
                    }
                    else {
                        this.currenUserIsOwner = true;
                    }

                }
                else {

                }
            });
    }

    //Usergroup user END

    //issue description update START
    issueDescriptionUpdate() {
        const data: IssueDescUpdateModel = {
            id: this.issueid,
            applicationId: this.issuelist.applicationId,
            issueDescription: this.issuelist.issueDescription,
            issueTitle: this.issuelist.issueTitle
        };

        this.issueService.updateissuedescription(data)
            .subscribe(response => {
                if (response) {
                    const message = this.appService.appData.content['app_issue_update_success'] || 'app_issue_update_success';
                    this.injector.get(ToastrService).success(message);
                }
                else {
                    const message = this.appService.appData.content['app_issue_update_failed'] || 'app_issue_update_failed';
                    this.injector.get(ToastrService).error(message);
                }
            });

        this.issueUpdateMode = false;
    }

    cancel() {
        this.issueUpdateMode = false;
    }

    edit() {
        if (this.requesteruser.id === this.currentuser.id) {
            this.issueUpdateMode = true;
        }
    }
    //issue description update END

    //Commnets işlemleri START
    getCommentList() {
        this.issueService.getcommentlist(this.issueid)
            .subscribe(response => {
                if (response) {
                    this.commentlist = response;
                }
                else {
                }
            });
    }


    sendcomment() {
        const data: IssueCommentModel = {
            issueId: this.issueid,
            id: undefined,
            applicationId: undefined,
            comment: this.form3.controls.replyCtrl.value,
            createdDate: undefined,
            email: undefined,
            name: undefined,
            profileImage: undefined,
            userId: undefined,
            isUpdate: false
        };

        this.issueService.addcomment(data)
            .subscribe((response) => {
                this.commentlist.push(new IssueCommentModel(response));
                this.replyCtrl = null;
            });
    }

    editComment(commentt) {
        if (this.currentuser.id === commentt.userId) {
            commentt.isUpdate = true;
        }
    }

    cancelComment(commentt) {
        commentt.isUpdate = false;
    }

    updateComment(commentt) {

        this.issueService.updatecomment(commentt)
            .subscribe((response) => {
                const index = this.commentlist.findIndex((existingUser) => existingUser.id === commentt.id);
                this.commentlist[index] = new IssueCommentModel(response);
            });
    }
    //Commnets işlemleri END

    start() {

        this.interval = setInterval(() => {
            ++this.counter; this.calc(); this.started = true;
        }, 1000);

        const data: IssueTimesheetsModel = {
            id: 0,
            issueId: this.issueid
            , userId: undefined
            , applicationId: undefined
            , startDate: undefined
            , endDate: undefined
            , workingTime: undefined
        };

        this.issueService.starttimesheets(data)
            .subscribe(response => {
                if (response) {
                }
                else {
                    clearInterval(this.interval);
                    this.started = false;

                    const message = this.appService.appData.content['app_timesheet_start_failed'] || 'app_timesheet_start_failed';
                    this.injector.get(ToastrService).error(message);
                }
            });
    }

    active() {
        this.interval = setInterval(() => {
            ++this.counter; this.calc(); this.started = true;
        }, 1000);
    }

    stop() {
        this.counter = 0;

        clearInterval(this.interval);
        this.started = false;

        const data: IssueTimesheetsModel = {
            id: 0,
            issueId: this.issueid
            , userId: undefined
            , applicationId: undefined
            , startDate: undefined
            , endDate: undefined
            , workingTime: undefined
        };

        this.issueService.closetimesheets(data)
            .subscribe(response => {
                if (response) {

                }
                else {
                    const message = this.appService.appData.content['app_timesheet_close_failed'] || 'app_timesheet_close_failed';
                    this.injector.get(ToastrService).error(message);
                }
            });

    }

    calc() {
        const day: number = Math.floor(this.counter / 86400);
        let daysonuc = day * 86400;
        let daysonuc2 = this.counter - daysonuc;
        const hours: number = Math.floor(daysonuc2 / 3600);
        let hoursonuc = hours * 3600;
        let hoursonuc2 = daysonuc2 - hoursonuc;
        const minutes: number = Math.floor(hoursonuc2 / 60);
        let minutsonuc = minutes * 60;
        const second: number = Math.floor(hoursonuc2 - minutsonuc);

        //let dayDesc = '';
        //if (day > 0) {
        //  dayDesc = day + 'g ';
        //}

        //let hourDesc = '';
        //if (hours > 0) {
        //  hourDesc = hours + 's ';
        //}

        //let minuteDesc = '';
        //if (minutes > 0) {
        //  minuteDesc = minutes + 'd ';
        //}

        //let secondDesc = '';
        //if (second > 0) {
        //  secondDesc = second + 'sn';
        //}

        //this.lbltimer = dayDesc + hourDesc + minuteDesc + secondDesc;

        this.lbltimer = ('00' + day).slice(-2) + ':' + ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + second).slice(-2);


    }

    activetimesheetKontrol() {

        const data: IssueTimesheetsModel = {
            id: 0,
            issueId: this.issueid
            , userId: undefined
            , applicationId: undefined
            , startDate: undefined
            , endDate: undefined
            , workingTime: undefined
        };

        this.issueService.getactivetimesheet(data)
            .subscribe(response => {
                if (response) {
                    this.activetimesheet = response;
                    if (this.activetimesheet.workingTime !== '0') {
                        this.counter = parseInt(this.activetimesheet.workingTime, 10);
                        this.active();
                    }
                    else {
                        this.counter = 0;
                        this.started = false;
                    }
                }
                else {
                }
            });
    }

    kayitEkle() {
        const datatimesheets: IssueTimesheetsModel = {
            id: 0,
            issueId: this.issueid
            , userId: undefined
            , applicationId: undefined
            , startDate: undefined
            , endDate: undefined
            , workingTime: undefined
        };

        this.dialog.open(IssueTimerAddComponent, {
            data: datatimesheets,
            panelClass: 'custom-panel'
        });
    }


    showimageDialog(attach) {
        if (attach.contentType == 'image/jpg' || attach.contentType == 'image/jpeg' || attach.contentType == 'image/gif' || attach.contentType == 'image/png') {
            this.dialog.open(IssueAttachDisplayComponent, {
                data: attach.url,
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%'
            });
        }
        else {
            this.downloadattach(attach);
        }

    }

    //Customer

    selectCustomer(customer) {
        this.listFilter = customer.customerName;
        this.selectedCustomerData = customer.id;
        this.selectedCustomer = customer.customerName;
    }

    selectUser(user) {
        this.userListFilter = user.firstName;
        this.customFilterSelectedUserData = user.id;
        this.customFilterSelectedUser = user.firstName;
    }

    userReset() {
        this.userListFilter = undefined;
        this.customFilterSelectedUserData = undefined;
        this.customFilterSelectedUser = undefined;
    }

    customerReset() {
        this.selectedCustomerData = undefined;
        this.selectedCustomer = undefined;
        this.listFilter = undefined;
    }

    filterReset() {
        this.selectedCustomerData = undefined;
        this.selectedCustomer = undefined;
        this.customFilterSelectedUserData = undefined;
        this.customFilterSelectedUser = undefined;
    }

    userformFilter(filterBy: string): UserModel[] {
        try {
            //this.filterReset();
            const orjFilterBy = filterBy;
            filterBy = filterBy.toLocaleLowerCase();
            return this.userModel.filter((user: UserModel) =>
                (user.firstName.toLocaleLowerCase().indexOf(filterBy) !== -1));
        }
        catch (e) {
            return this.userModel;
        }

    }

    performFilter(filterBy: string): Customer[] {
        try {
            //this.filterReset();
            const orjFilterBy = filterBy;
            filterBy = filterBy.toLocaleLowerCase();
            return this.customerTypeModel.filter((customer: Customer) =>
                (customer.customerName.toLocaleLowerCase().indexOf(filterBy) !== -1));
        }
        catch (e) {
            return this.customerTypeModel;
        }

    }

    getCustomerList() {
        this.projectService.getcustomerlist2()
            .subscribe(response => {
                if (response) {

                    this.customerTypeModel = response;
                    this.filteredcustomerTypeModel = response;

                    if (this.selectedCustomerData) {
                        this.listFilter = (this.customerTypeModel.filter((type: Customer) =>
                            (type.id === this.selectedCustomerData)))[0].customerName;
                    }
                    else {

                        this.customerTypeModel = response.filter((type: Customer) =>
                            (type.isEnable === true));

                        this.filteredcustomerTypeModel = this.customerTypeModel;

                    }


                }
                else {
                }
            });
    }

    getuserGroupsForProject(project) {
        this.projectService.getallgroupsforproject(project)
            .subscribe(response => {
                if (response) {
                    this.groupsModel = response;
                }
                else {
                }
            });


        this.projectService.getallusersforproject(project)
            .subscribe(response => {
                if (response) {
                    this.userModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));
                }
                else {
                }
            });

    }
}
