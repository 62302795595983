import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';

// import { routerTransition } from './router.animations';
import { AppService, AuthService, ModalService } from '@app/services';
import { VersionCheckService } from './services/version-check.service';
import { SidenavItem } from './layout/sidenav/sidenav-item/sidenav-item.interface';

@Component({
    selector: 'fury-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private sidenavService: SidenavService,
        private iconRegistry: MatIconRegistry,
        private renderer: Renderer2,
        private themeService: ThemeService,
        @Inject(DOCUMENT) private document: Document,
        private platform: Platform,
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private title: Title,
        private meta: Meta,
        private appService: AppService,
        private modalService: ModalService,
        private versionCheckService: VersionCheckService,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(PLATFORM_ID) private platformId: string) {
        this.route.queryParamMap.pipe(
            filter(queryParamMap => queryParamMap.has('style'))
        ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

        this.iconRegistry.setDefaultFontSetClass('material-icons');
        this.themeService.theme$.subscribe(theme => {
            if (theme[0]) {
                this.renderer.removeClass(this.document.body, theme[0]);
            }

            this.renderer.addClass(this.document.body, theme[1]);
        });

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.setmenu();

        //this.sidenavService.addItems([
        //  {
        //    name: 'APPS',
        //    position: 5,
        //    type: 'subheading',
        //    customClass: 'first-subheading'
        //  },
        //  {
        //    name: 'Dashboard',
        //    routeOrFunction: '/',
        //    icon: 'dashboard',
        //    position: 10,
        //    pathMatchExact: true
        //  },
        //  {
        //    name: 'All-In-One Table',
        //    routeOrFunction: '/tables/all-in-one-table',
        //    icon: 'assignment',
        //    badge: '22',
        //    badgeColor: '#2196F3',
        //    position: 15,
        //  },
        //  {
        //    name: 'Calendar',
        //    routeOrFunction: '/apps/calendar',
        //    icon: 'date_range',
        //    position: 20
        //  },
        //  {
        //    name: 'Inbox',
        //    routeOrFunction: '/apps/inbox',
        //    icon: 'inbox',
        //    position: 25
        //  },
        //  {
        //    name: 'Chat',
        //    routeOrFunction: '/apps/chat',
        //    icon: 'chat',
        //    position: 30,
        //    badge: '14',
        //    badgeColor: '#009688'
        //  },
        //  {
        //    name: 'USER INTERFACE',
        //    type: 'subheading',
        //    position: 35
        //  },
        //  {
        //    name: 'Components',
        //    routeOrFunction: '/components',
        //    icon: 'layers',
        //    position: 40
        //  },
        //  {
        //    name: 'Forms',
        //    icon: 'description',
        //    position: 45,
        //    subItems: [
        //      {
        //        name: 'Form Elements',
        //        routeOrFunction: '/forms/form-elements',
        //        position: 10
        //      },
        //      {
        //        name: 'Form Wizard',
        //        routeOrFunction: '/forms/form-wizard',
        //        position: 15
        //      }
        //    ]
        //  },
        //  {
        //    name: 'Drag & Drop',
        //    routeOrFunction: '/drag-and-drop',
        //    icon: 'mouse',
        //    position: 55
        //  },
        //  {
        //    name: 'WYSIWYG Editor',
        //    routeOrFunction: '/editor',
        //    icon: 'format_shapes',
        //    position: 60
        //  },
        //  {
        //    name: 'PAGES',
        //    type: 'subheading',
        //    position: 65
        //  },
        //  {
        //    name: 'Authentication',
        //    icon: 'lock',
        //    position: 66,
        //    subItems: [
        //      {
        //        name: 'Login Page',
        //        routeOrFunction: '/login',
        //        position: 5
        //      },
        //      {
        //        name: 'Register Page',
        //        routeOrFunction: '/register',
        //        position: 10
        //      },
        //      {
        //        name: 'Forgot Password',
        //        routeOrFunction: '/forgot-password',
        //        position: 15
        //      }
        //    ]
        //  },
        //  {
        //    name: 'Page Layouts',
        //    icon: 'view_compact',
        //    position: 67,
        //    subItems: [
        //      {
        //        name: 'Simple',
        //        routeOrFunction: '/page-layouts/simple',
        //        position: 5
        //      },
        //      {
        //        name: 'Simple Tabbed',
        //        routeOrFunction: '/page-layouts/simple-tabbed',
        //        position: 5
        //      },
        //      {
        //        name: 'Card',
        //        routeOrFunction: '/page-layouts/card',
        //        position: 10
        //      },
        //      {
        //        name: 'Card Tabbed',
        //        routeOrFunction: '/page-layouts/card-tabbed',
        //        position: 15
        //      },
        //    ]
        //  },
        //  {
        //    name: 'Coming Soon',
        //    routeOrFunction: '/coming-soon',
        //    icon: 'watch_later',
        //    position: 68
        //  },
        //  {
        //    name: 'Blank',
        //    routeOrFunction: '/blank',
        //    icon: 'picture_in_picture',
        //    position: 69
        //  },
        //  {
        //    name: 'Maps',
        //    icon: 'map',
        //    position: 70,
        //    subItems: [
        //      {
        //        name: 'Google Maps',
        //        routeOrFunction: '/maps/google-maps',
        //        position: 0
        //      }
        //    ],
        //    badge: '3',
        //    badgeColor: '#4CAF50'
        //  },
        //  {
        //    name: 'Material Icons',
        //    routeOrFunction: '/icons',
        //    icon: 'grade',
        //    position: 75
        //  },
        //  {
        //    name: 'Multi-Level Menu',
        //    icon: 'menu',
        //    position: 85,
        //    subItems: [
        //      {
        //        name: 'Level 1',
        //        subItems: [
        //          {
        //            name: 'Level 2',
        //            subItems: [
        //              {
        //                name: 'Level 3',
        //                subItems: [
        //                  {
        //                    name: 'Level 4',
        //                    subItems: [
        //                      {
        //                        name: 'Level 5',
        //                        routeOrFunction: '/level1/level2/level3/level4/level5'
        //                      }
        //                    ]
        //                  }
        //                ]
        //              }
        //            ]
        //          }
        //        ]
        //      }
        //    ]
        //  }
        //]);
    }

    public ngOnInit() {

        this.versionCheckService.initVersionCheck(this.baseUrl + 'version.json');

        this.updateTitleAndMeta();
        if (window.location.href.indexOf('?postLogout=true') > 0) {
            this.authService.signoutRedirectCallback().then(() => {
                const url: string = this.router.url.substring(
                    0,
                    this.router.url.indexOf('?')
                );
                this.router.navigateByUrl(url);
            });
        }
        // Check cookie consent
        setTimeout(() => {
            if (this.appService.appData.cookieConsent.showConsent) {
                this.modalService.confirm({
                    title: 'Cookie consent',
                    message: 'Use this space to summarize your privacy and cookie use policy.'
                }).then(() => {
                    document.cookie = this.appService.appData.cookieConsent.cookieString;
                }, () => { });
            }
        }, 0);

    }

    public getState(outlet: any) {
        return outlet.activatedRouteData.state;
    }

    private updateTitleAndMeta() {
        this.title.setTitle(this.appService.appData.content['app_title']);
        this.meta.addTags([
            { name: 'description', content: this.appService.appData.content['app_description'] },
            { property: 'og:title', content: this.appService.appData.content['app_title'] },
            { property: 'og:description', content: this.appService.appData.content['app_description'] }
        ]);
    }

    setmenu() {

        //console.log('ana menuuuuuu');

        this.sidenavService.clear();

        const menu: SidenavItem[] = [];

        let fullRoles = atob(localStorage.getItem('1ZXYL@M'));
        if (fullRoles === null) {
            fullRoles = "User";
        }

        if ((fullRoles.search("CustomerMember") !== -1)) {
            const issuegroupname = this.appService.appData.content['app_menu_issues'] || 'app_menu_issues';

            menu.push({
                name: issuegroupname,
                icon: 'assignment',
                routeOrFunction: '/issuelist',
                position: 2
            });

            const usersandgroups = this.appService.appData.content['app_menu_admin_users_and_groups'] || 'app_menu_admin_users_and_groups';
            const users = this.appService.appData.content['app_menu_admin_users'] || 'app_menu_admin_users';
            const usergroups = this.appService.appData.content['app_menu_admin_usergroups'] || 'app_menu_admin_usergroups';

            const usersmenu = {
                name: users,
                icon: 'reorder',
                routeOrFunction: '/users',
                position: 3
            };

            const groupsmenu = {
                name: usergroups,
                icon: 'reorder',
                routeOrFunction: '/usergroups',
                position: 3
            };

            menu.push({
                name: usersandgroups,
                icon: 'supervisor_account',
                position: 3,
                subItems: [
                    usersmenu,
                    groupsmenu
                ]
            });

            this.sidenavService.clear();
            menu.forEach(item => this.sidenavService.addItem(item));

            return;
        }


        if ((fullRoles.search("TinyUser") !== -1) || (fullRoles.search("SelfUser") !== -1) || (fullRoles.search("GroupUser") !== -1)) {
            const issuegroupname = this.appService.appData.content['app_menu_issues'] || 'app_menu_issues';

            menu.push({
                name: issuegroupname,
                icon: 'assignment',
                routeOrFunction: '/issuelist',
                position: 2
            });

            this.sidenavService.clear();
            menu.forEach(item => this.sidenavService.addItem(item));

            return;
        }


        //menu.push({
        //  name: 'APPS',
        //  position: 1,
        //  type: 'subheading',
        //  customClass: 'first-subheading'
        //});

        menu.push({
            name: 'Dashboard',
            routeOrFunction: '/dashboard',
            icon: 'dashboard',
            position: 2,
            pathMatchExact: true
        });

        const aboutme = this.appService.appData.content['app_menu_about_me'] || 'app_menu_about_me';
        menu.push({
            name: aboutme,
            routeOrFunction: '/aboutme',
            icon: 'assignment_ind',
            position: 3

        });

        const issuegroupname = this.appService.appData.content['app_menu_issues'] || 'app_menu_issues';
        const issuelistname = this.appService.appData.content['app_menu_issues_active'] || 'app_menu_issues_active';
        const issuelistnameclosed = this.appService.appData.content['app_menu_issues_closed'] || 'app_menu_issues_closed';

        const issuelist = {
            name: issuelistname,
            icon: 'timer',
            routeOrFunction: '/issuelist',
            position: 5
        };

        const issuelistclose = {
            name: issuelistnameclosed,
            icon: 'sync_disabled',
            routeOrFunction: '/issuelist',
            position: 6
        };


        menu.push({
            name: issuegroupname,
            icon: 'assignment',
            routeOrFunction: '/issuelist',
            position: 3
        });


        const supportmainmenuuser = this.appService.appData.content['app_menu_support_issues'] || 'app_menu_support_issues';
        const customersupportissuelist = this.appService.appData.content['app_menu_customer_support_issue_list'] || 'app_menu_customer_support_issue_list';
        const publicsupportissuelist = this.appService.appData.content['app_menu_public_support_issue_list'] || 'app_menu_public_support_issue_list';

        const Customersupportissuelist = {
            name: customersupportissuelist,
            icon: 'reorder',
            routeOrFunction: '/support/customer-issue-list',
            position: 4
        };

        const Publicsupportissuelist = {
            name: publicsupportissuelist,
            icon: 'reorder',
            routeOrFunction: '/support/public-issue-list',
            position: 4
        };

        menu.push({
            name: supportmainmenuuser,
            icon: 'build',
            position: 4,
            subItems: [
                Publicsupportissuelist,
                Customersupportissuelist
            ]
        });

        const usersandgroups = this.appService.appData.content['app_menu_admin_users_and_groups'] || 'app_menu_admin_users_and_groups';
        const users = this.appService.appData.content['app_menu_admin_users'] || 'app_menu_admin_users';
        const usergroups = this.appService.appData.content['app_menu_admin_usergroups'] || 'app_menu_admin_usergroups';

        const usersmenu = {
            name: users,
            icon: 'reorder',
            routeOrFunction: '/users',
            position: 5
        };

        const groupsmenu = {
            name: usergroups,
            icon: 'reorder',
            routeOrFunction: '/usergroups',
            position: 5
        };

        menu.push({
            name: usersandgroups,
            icon: 'supervisor_account',
            position: 5,
            subItems: [
                usersmenu,
                groupsmenu
            ]
        });

        /////
        const directorylist = this.appService.appData.content['app_documents'] || 'app_documents';

        menu.push({
            name: directorylist,
            routeOrFunction: '/directorylist',
            icon: 'assignment_ind',
            position: 6

        });
        /////


        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {
            const managementheader = this.appService.appData.content['app_menu_admin_header'] || 'app_menu_admin_header';
            menu.push({
                name: managementheader,
                position: 6,
                type: 'subheading',
                customClass: 'first-subheading'
            });
        }

        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {

            const adminmenu = this.appService.appData.content['app_menu_admin'] || 'app_menu_admin';

            menu.push({
                name: adminmenu,
                routeOrFunction: '/settings',
                icon: 'settings',
                position: 7
            });

        }


        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {
            //Project
            const projectadmin = this.appService.appData.content['app_menu_admin_project'] || 'app_menu_admin_project';
            const projectlist = this.appService.appData.content['app_menu_admin_project_list'] || 'app_menu_admin_project_list';

            const ProjectListMenu = {
                name: projectlist,
                icon: 'reorder',
                routeOrFunction: '/projectlist',
                position: 8
            };

            menu.push({
                name: projectadmin,
                icon: 'edit',
                position: 8,
                subItems: [
                    ProjectListMenu
                ]
            });

        }


        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {
            //Customer
            const customeradmin = this.appService.appData.content['app_menu_admin_customer'] || 'app_menu_admin_customer';
            const customerlist = this.appService.appData.content['app_menu_admin_customer_list'] || 'app_menu_admin_customer_list';

            const CustomerListMenu = {
                name: customerlist,
                icon: 'reorder',
                routeOrFunction: '/customerlist',
                position: 10
            };

            menu.push({
                name: customeradmin,
                icon: 'supervised_user_circle',
                position: 10,
                subItems: [
                    CustomerListMenu
                ]
            });

        }


        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {
            //Reports
            const reportadminmenu = this.appService.appData.content['app_menu_admin_report'] || 'app_menu_admin_report';
            const workingtime = this.appService.appData.content['app_menu_admin_report_working_time'] || 'app_menu_admin_report_working_time';
            const projectissuereport = this.appService.appData.content['app_menu_admin_report_project_issue'] || 'app_menu_admin_report_project_issue';
            const projectissuereportsummary = this.appService.appData.content['app_menu_admin_report_project_issue_summary'] || 'app_menu_admin_report_project_issue_summary';
            const issuereportassignedsummary = this.appService.appData.content['app_menu_admin_report_assigned_summary'] || 'app_menu_admin_report_assigned_summary';
            const issuereportassignedusersummary = this.appService.appData.content['app_menu_admin_report_assigned_user_summary'] || 'app_menu_admin_report_assigned_user_summary';

            const reportissueentry = this.appService.appData.content['app_menu_admin_report_issue_entry_menu'] || 'app_menu_admin_report_issue_entry_menu';
            const reportissueassigned = this.appService.appData.content['app_menu_admin_report_issue_assigned_menu'] || 'app_menu_admin_report_issue_assigned_menu';

            const ReportAdminProjectIssueSummary = {
                name: projectissuereportsummary,
                icon: 'reorder',
                routeOrFunction: '/projectissuesummaryreport',
                position: 11
            };

            const ReportAdminProjectIssue = {
                name: projectissuereport,
                icon: 'reorder',
                routeOrFunction: '/projectissuereport',
                position: 12
            };

            const ReportEntryMenu = {
                name: reportissueentry,
                subItems: [ReportAdminProjectIssueSummary, ReportAdminProjectIssue]
            };

            const ReportAdminWorkingTimeMenu = {
                name: workingtime,
                icon: 'reorder',
                routeOrFunction: '/workingtime',
                position: 13
            };

            const ReportAdminAssignedIssueSummary = {
                name: issuereportassignedsummary,
                icon: 'reorder',
                routeOrFunction: '/assignedissuesummaryreport',
                position: 14
            };

            const ReportAdminAssignedUserIssueSummary = {
                name: issuereportassignedusersummary,
                icon: 'reorder',
                routeOrFunction: '/assigneduserissuesummaryreport',
                position: 15
            };

              const ReportAssignedMenu = {
                  name: reportissueassigned,
                  subItems: [ReportAdminAssignedIssueSummary, ReportAdminAssignedUserIssueSummary]
                };

            menu.push({
                name: reportadminmenu,
                icon: 'assessment',
                position: 11,
                subItems: [
                    ReportEntryMenu,
                    ReportAssignedMenu,
                    ReportAdminWorkingTimeMenu
                ]
            });
        }

        //Dok�man   
        if ((fullRoles.search("Admin") !== -1) || (fullRoles.search("SystemAdmin") !== -1)) {
            const documentmenu = this.appService.appData.content['app_document_management'] || 'app_document_management';
            const documentcategory = this.appService.appData.content['app_document_category'] || 'app_document_category';
            const documentdirectory = this.appService.appData.content['app_document_directory'] || 'app_document_directory';
            const documentsettings = this.appService.appData.content['app_document_settings'] || 'app_document_settings';

            const DocumentCategory = {
                name: documentcategory,
                icon: 'reorder',
                routeOrFunction: '/documentcategory',
                position: 11
            };

            const DocumentDirectory = {
                name: documentdirectory,
                icon: 'reorder',
                routeOrFunction: '/documentdirectory',
                position: 11
            };

            //const DocumentSettings = {
            //    name: documentsettings,
            //    icon: 'reorder',
            //    routeOrFunction: '/documentsettings',
            //    position: 11
            //};

            menu.push({
                name: documentmenu,
                icon: 'find_in_page',
                position: 11,
                subItems: [
                    DocumentCategory, DocumentDirectory, /*DocumentSettings*/
                ]
            });
        }
        //Dok�man

        const supportmainmenu = this.appService.appData.content['app_menu_support'] || 'app_menu_support';
        const supportformsetting = this.appService.appData.content['app_menu_support_form_setting'] || 'app_menu_support_form_setting';
        const supporttype = this.appService.appData.content['app_menu_support_type'] || 'app_menu_support_type';
        const publicsupportmanage = this.appService.appData.content['app_menu_public_support_manage'] || 'app_menu_public_support_manage';

        const SupportFormSetting = {
            name: supportformsetting,
            icon: 'reorder',
            routeOrFunction: '/support/formsettings',
            position: 12
        };

        const Supporttype = {
            name: supporttype,
            icon: 'reorder',
            routeOrFunction: '/support/supporttype',
            position: 13
        };

        const Publicsupportmanage = {
            name: publicsupportmanage,
            icon: 'reorder',
            routeOrFunction: '/support/publicsupportlink',
            position: 13
        };

        menu.push({
            name: supportmainmenu,
            icon: 'help',
            position: 12,
            subItems: [
                SupportFormSetting,
                Supporttype,
                Publicsupportmanage
            ]
        });



        if (fullRoles.search("SystemAdmin") !== -1) {

            const systemmanagementheader = this.appService.appData.content['app_menu_system_admin_header'] || 'app_menu_system_admin_header';
            menu.push({
                name: systemmanagementheader,
                position: 17,
                type: 'subheading',
                customClass: 'first-subheading'
            });

            const superadminmenu = this.appService.appData.content['app_menu_super_admin'] || 'app_menu_super_admin';
            const sirketuye = this.appService.appData.content['app_menu_company_management'] || 'app_menu_company_management';

            const SuperAdminApplication = {
                name: sirketuye,
                icon: 'reorder',
                routeOrFunction: '/company',
                position: 18
            };

            menu.push({
                name: superadminmenu,
                icon: 'settings_applications',
                position: 18,
                subItems: [
                    SuperAdminApplication
                ]
            });
        }

        //const login = {
        //  name: 'Login Page',
        //  routeOrFunction: '/login',
        //  position: 5
        //};

        //const register = {
        //  name: 'Register Page',
        //  routeOrFunction: '/register',
        //  position: 10
        //};

        //const forgotPassword = {
        //  name: 'Forgot Password',
        //  routeOrFunction: '/forgot-password',
        //  position: 15
        //};

        //const setPassword = {
        //  name: 'Set Password',
        //  routeOrFunction: '/set-password',
        //  position: 20
        //};

        //const updatePassword = {
        //  name: 'Update Password',
        //  routeOrFunction: '/update-password',
        //  position: 25
        //};

        //menu.push({
        //  name: 'Custom Pages',
        //  icon: 'web',
        //  position: 15,
        //  subItems: [
        //    login,
        //    register,
        //    forgotPassword,
        //    setPassword,
        //    updatePassword
        //  ]
        //});



        //menu.push({
        //  name: 'Inbox',
        //  routeOrFunction: '/apps/inbox',
        //  icon: 'inbox',
        //  position: 50,
        //  badge: '22',
        //  badgeColor: '#673ab7'
        //});

        //menu.push({
        //  name: 'Chat',
        //  routeOrFunction: '/apps/chat',
        //  icon: 'chat',
        //  position: 20,
        //  badge: '14',
        //  badgeColor: '#009688'
        //});

        //menu.push({
        //  name: 'Calendar',
        //  routeOrFunction: '/apps/calendar',
        //  icon: 'date_range',
        //  position: 25
        //});

        //menu.push({
        //  name: 'All-In-One Table',
        //  routeOrFunction: '/tables/all-in-one-table',
        //  icon: 'assignment',
        //  position: 30
        //});

        //menu.push({
        //  name: 'USER INTERFACE',
        //  type: 'subheading',
        //  position: 35
        //});

        //menu.push({
        //  name: 'Components',
        //  routeOrFunction: '/components',
        //  icon: 'layers',
        //  position: 40
        //});

        //const formElements = {
        //  name: 'Form Elements',
        //  routeOrFunction: '/forms/form-elements',
        //  position: 10
        //};

        //const formWizard = {
        //  name: 'Form Wizard',
        //  routeOrFunction: '/forms/form-wizard',
        //  position: 15
        //};

        //menu.push({
        //  name: 'Forms',
        //  icon: 'description',
        //  position: 45,
        //  subItems: [
        //    formElements,
        //    formWizard
        //  ]
        //});

        //const simpleTable = {
        //  name: 'Simple Table',
        //  routeOrFunction: '/tables/simple-table',
        //  position: 10
        //};

        //const allInOneTable = {
        //  name: 'All-In-One Table',
        //  routeOrFunction: '/tables/all-in-one-table',
        //  position: 15
        //};

        //menu.push({
        //  name: 'Tables',
        //  icon: 'format_line_spacing',
        //  position: 50,
        //  subItems: [
        //    simpleTable,
        //    allInOneTable
        //  ]
        //});

        //menu.push({
        //  name: 'Drag & Drop',
        //  routeOrFunction: '/drag-and-drop',
        //  icon: 'mouse',
        //  position: 55
        //});

        //menu.push({
        //  name: 'WYSIWYG Editor',
        //  routeOrFunction: '/editor',
        //  icon: 'format_shapes',
        //  position: 60
        //});

        //menu.push({
        //  name: 'PAGES',
        //  type: 'subheading',
        //  position: 65
        //});

        //const googleMaps = {
        //  name: 'Google Maps',
        //  routeOrFunction: '/maps/google-maps',
        //  position: 5
        //};

        //menu.push({
        //  name: 'Maps',
        //  icon: 'map',
        //  position: 70,
        //  subItems: [
        //    googleMaps
        //  ],
        //  badge: '3',
        //  badgeColor: '#4CAF50'
        //});

        //menu.push({
        //  name: 'Material Icons',
        //  routeOrFunction: '/icons',
        //  icon: 'grade',
        //  position: 75
        //});


        //const level5 = {
        //  name: 'Level 5',
        //  routeOrFunction: '/level1/level2/level3/level4/level5'
        //};

        //const level4 = {
        //  name: 'Level 4',
        //  subItems: [level5]
        //};

        //const level3 = {
        //  name: 'Level 3',
        //  subItems: [level4]
        //};

        //const level2 = {
        //  name: 'Level 2',
        //  subItems: [level3]
        //};

        //const level1 = {
        //  name: 'Level 1',
        //  subItems: [level2]
        //};

        //menu.push({
        //  name: 'Multi-Level Menu',
        //  icon: 'menu',
        //  position: 85,
        //  subItems: [level1]
        //});

        // Send all created Items to SidenavService
        this.sidenavService.clear();
        menu.forEach(item => this.sidenavService.addItem(item));
    }
}
