import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IssueListComponent } from './issue-list.component';
import { AppSharedModule } from '../../../appshared';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { ListModule } from '../../../../@fury/shared/list/list.module';
import { PageModule } from '../../../../@fury/shared/page/page.module';
import { BreadcrumbsModule } from '../../../../@fury/shared/breadcrumbs/breadcrumbs.module';
import { FurySharedModule } from '../../../../@fury/fury-shared.module';
import { FuryCardModule } from '../../../../@fury/shared/card/card.module';
import { IssueListRoutingModule } from './issue-list-routing';
import { ScrollbarModule } from '../../../../@fury/shared/scrollbar/scrollbar.module';

@NgModule({
  imports: [
        CommonModule,
        IssueListRoutingModule,
    FormsModule,
    MaterialModule,

    // Core
    ListModule,
        PageModule,
        ScrollbarModule,
        BreadcrumbsModule,
        FurySharedModule,
        AppSharedModule,
        FuryCardModule
  ],
  declarations: [IssueListComponent],
  exports: [IssueListComponent]
})
export class IssueListModule {
}
