
export class IssueCommentModel {
 id: number;
 userId: number;
 name: string;
 comment: string;
 issueId: number;
 applicationId: number;
 email: string;
 profileImage: any;
  createdDate: string;
  isUpdate: boolean;
  constructor(commentt) {
    this.id = commentt.id;
    this.userId = commentt.userId;
    this.name = commentt.name;
    this.comment = commentt.comment;
    this.issueId = commentt.issueId;
    this.applicationId = commentt.applicationId;
    this.email = commentt.email;
    this.profileImage = commentt.profileImage;
    this.createdDate = commentt.createdDate;
    this.isUpdate = commentt.isUpdate;
  }

}
