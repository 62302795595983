import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DialogModel } from "../models/dialog.model";

@Injectable()
export class CommonService {
  name: BehaviorSubject<string> = new BehaviorSubject<string>('');

  dialogdata: BehaviorSubject<DialogModel> = new BehaviorSubject<DialogModel>(null);

  newissue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
