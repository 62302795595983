import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './notfound.component';
import { NotFoundRoutingModule } from './notfound-routing.module';
import { AppSharedModule } from '../../appshared';
import { MaterialModule } from '../../../@fury/shared/material-components.module';


@NgModule({
  imports: [
    CommonModule,
    NotFoundRoutingModule,
    MaterialModule,
        ReactiveFormsModule,
        AppSharedModule
  ],
  declarations: [NotFoundComponent]
})
export class NotFoundModule {
}
