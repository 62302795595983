import { RoleModel } from "./role.model";
import { GroupModel } from "./group.model";

export class UserModel {
 id: number;
 isEnabled: boolean;
 firstName: string;
 lastName: string;
 mobile: string;
 positionId: number;
 applicationId: number;
 email: string;
 title: string;
  roleModel: RoleModel;
  groupModel: GroupModel[];
  profileImage: any;
  customerMemberId: boolean;

  constructor(user) {
    this.id = user.id;
    this.isEnabled = user.isEnabled;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.mobile = user.mobile;
    this.positionId = user.positionId;
    this.applicationId = user.applicationId;
    this.email = user.email;
    this.title = user.title;
    this.roleModel = user.roleModel;
    this.groupModel = user.groupModel;
    this.profileImage = user.profileImage;
    this.customerMemberId = user.customerMemberId;
  }

  get name() {
    let name = '';

    if (this.firstName && this.lastName) {
      name = this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      name = this.firstName;
    } else if (this.lastName) {
      name = this.lastName;
    }

    return name;
  }

  set name(value) {
  }

}
