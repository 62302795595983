import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import 'hammerjs'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';


import {  BrowserTransferStateModule } from '@angular/platform-browser';
import {  APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { PrebootModule } from 'preboot';

import { AppSharedModule } from './appshared';
import { ToastrModule } from './toastr';

//import { routes } from './app.routes';
// Components
import { FooterComponent, HeaderComponent, ModalComponent, PrivacyComponent, ModalTemplateDirective } from '@app/components';
import { HomeComponent } from './home/home.component';
// Services
import { AppService, AuthService, DataService, GlobalErrorHandler, ModalService, ModalStateService, AuthInterceptor, TimingInterceptor } from '@app/services';
import { AccountService } from './services/account.service';
import { ProjectService } from './services/project.service';
import { DocumentService } from './services/document.service';
import { CommonService } from './services/common.service';
import { IssueService } from './services/issue.service';
import { DashBoardService } from './services/dashboard.service';
import { VersionCheckService } from './services/version-check.service';
import { AuthGuardService } from './services/auth-guard.service';
import { CompanyModule } from './pages/company-pages/company/company-all-in-one-table.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IssueListModule } from './pages/issue/issue-list/issue-list.module';
import { IssueSearchModule } from './pages/issue/issue-search/issue-search.module';
import { IssueCreateModule } from './pages/issue/issue-create/issue-create.module';
import { IssueDetailModule } from './pages/issue/issue-detail/issue-detail.module';
import { NotFoundModule } from './pages/notfound/notfound.module';
import { MatProgressBarModule } from '@angular/material';

export function appServiceFactory(appService: AppService, authService: AuthService): Function {
  return () => appService.getAppData(authService);
}
@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Google Maps Module
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })


    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserTransferStateModule,
    AppSharedModule,
    NgbModule.forRoot(),
        ToastrModule.forRoot(),
        CompanyModule,
        IssueListModule,
        IssueSearchModule,
        NgMultiSelectDropDownModule,
        RoundProgressModule,
        NgxMaterialTimepickerModule,
        IssueCreateModule,
        IssueDetailModule,
        NotFoundModule,
        MatProgressBarModule,
    //RouterModule.forRoot(routes, { initialNavigation: 'enabled' }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
    ModalTemplateDirective,
    PrivacyComponent],
  bootstrap: [AppComponent],
  providers: [
	AppService,
    AuthService,
    DataService,
      ModalService,
      AuthGuardService,
      ModalStateService,
      AccountService,
      ProjectService,
      DocumentService,
      CommonService,
      IssueService,
      DashBoardService,
      VersionCheckService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appServiceFactory, deps: [AppService, AuthService], multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    }
  ],
 exports: []
})
export class AppModule {
}
