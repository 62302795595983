import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from './token.service';


@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(
		  private readonly router: Router,
      private readonly tokenService: TokenService
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.tokenService.exists()) { return true; }
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
		return false;
	}
}
