import { Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { TokenService } from "./token.service";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DocumentCategory } from "../models/document-models/document-category.model";
import { DocumentListModel } from "../models/document-models/documentlist.model";
import { DocumentDirectoryMember } from "../models/document-models/documentdirectorymember.model";
import { DocumentSettings } from "../models/document-models/document-settings.model";
import { DocumentDirectoryDetail } from "../models/document-models/documentdirectorydetail.model";
import { DocumentsList } from "../models/document-models/documentslist.model";
import { DocumentUserWrite } from "../models/document-models/documentuserwrite.model";


@Injectable()
export class DocumentService {

    constructor(private http: HttpClient,
        private readonly tokenService: TokenService,
        private readonly router: Router,
        public dataService: DataService,
        private inj: Injector) {
    }

    public getdocumentcategorylist() {
        const REGISTER_URL = `/api/document/DocumentCategoryList`;
        return this.dataService.get<DocumentCategory[]>(REGISTER_URL);
    }

    public adddocumentcategory(documentcategory): Observable<DocumentCategory> {
        const REGISTER_URL = `/api/document/AddDocumentCategory`;
        return this.dataService.post<DocumentCategory>(REGISTER_URL, documentcategory);
    }

    public updatedocumentcategory(documentcategory) {
        const REGISTER_URL = `/api/document/AddDocumentCategory`;
        return this.dataService.post(REGISTER_URL, documentcategory).pipe(
            map((res) => {
                return true;
            }));
    }

    public deletedocumentcategory(documentcategory) {
        const REGISTER_URL = `/api/document/DeleteDocumentCategory`;
        return this.dataService.post(REGISTER_URL, documentcategory).pipe(
            map((res) => {
                return true;
            }));
    }

    //////////// document directory

    public getdocumentdirectorylistfull() {
        const REGISTER_URL = `/api/document/documentlistfull`;
        return this.dataService.get<DocumentListModel[]>(REGISTER_URL);
    }

    public adddocumentdirectory(documentdirectory): Observable<DocumentListModel> {
        const REGISTER_URL = `/api/document/AddDocumentDirectory`;
        return this.dataService.post<DocumentListModel>(REGISTER_URL, documentdirectory);
    }

    public updatedocument(document) {
        const REGISTER_URL = `/api/project/AddProject`;
        return this.dataService.post(REGISTER_URL, document).pipe(
            map((res) => {
                return true;
            }));

    }

    public getdocumentdirectorydetail(documentdirectory): Observable<DocumentListModel> {
        const REGISTER_URL = `/api/document/DocumentDirectoryDetail`;
        return this.dataService.post<DocumentListModel>(REGISTER_URL, documentdirectory);
    }

    public addupdatedocumentdirectorymember(documentdirectorymember) {
        const REGISTER_URL = `/api/document/AddUpdateDocumentDirectoryMember`;
        return this.dataService.post(REGISTER_URL, documentdirectorymember).pipe(
            map((res) => {
                return true;
            }));
    }

    public deletedocumentdirectory(documentdirectory) {
        const REGISTER_URL = `/api/document/DeleteDocumentDirectory`;
        return this.dataService.post(REGISTER_URL, documentdirectory).pipe(
            map((res) => {
                return true;
            }));
    }
    public getdocumentdirectoryallmember(documentdirectory): Observable<DocumentDirectoryMember> {
        const REGISTER_URL = `/api/document/DocumentDirectoryAllMember`;
        return this.dataService.post<DocumentDirectoryMember>(REGISTER_URL, documentdirectory);
    }

    /// Document Settings

    public getdocumentsettingslist() {
        const REGISTER_URL = `/api/document/DocumentSettingsList`;
        return this.dataService.get<DocumentSettings[]>(REGISTER_URL);
    }

    public adddocumentsettings(documentsettings): Observable<DocumentSettings> {
        const REGISTER_URL = `/api/document/AddDocumentSettings`;
        return this.dataService.post<DocumentSettings>(REGISTER_URL, documentsettings);
    }

    public updatedocumentsettings(documentsettings) {
        const REGISTER_URL = `/api/document/AddDocumentSettings`;
        return this.dataService.post(REGISTER_URL, documentsettings).pipe(
            map((res) => {
                return true;
            }));
    }

    public deletedocumentsettings(documentsettings) {
        const REGISTER_URL = `/api/document/DeleteDocumentSettings`;
        return this.dataService.post(REGISTER_URL, documentsettings).pipe(
            map((res) => {
                return true;
            }));
    }

    public getdirectorydetail() {
        const REGISTER_URL = `/api/Document/DocumentDirectoryDetailList`;
        return this.dataService.post<DocumentDirectoryDetail[]>(REGISTER_URL);
    }

    public getdocumentlistdirectory(searchParam) {
        const REGISTER_URL = `/api/Document/DocumentListDirectory`;
        return this.dataService.post<DocumentsList[]>(REGISTER_URL, searchParam);
    }

    public getdocumentlistcategory(searchParam) {
        const REGISTER_URL = `/api/Document/DocumentListCategory`;
        return this.dataService.post<DocumentsList[]>(REGISTER_URL, searchParam);
    }

    public getdocumentuserwrite(searchParam) {
        const REGISTER_URL = `/api/Document/DocumentUserWrite`;
        return this.dataService.post<DocumentUserWrite[]>(REGISTER_URL, searchParam);
    }

    public uploaddocument(file, params) {
        const REGISTER_URL = `/api/Document/UploadDocument`;
        return this.dataService.post(REGISTER_URL, file, params).pipe(
            map((res) => {
                return true;
            }));
    }

    public deleteDocument(document) {
        const REGISTER_URL = `/api/Document/DeleteDocument`;
        return this.dataService.post(REGISTER_URL, document).pipe(
            map((res) => {
                return true;
            }));
    }
}