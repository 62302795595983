import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CategoryModel } from '../issue-models/category.model';
import { IssueTypeModel } from '../issue-models/issuetype.model';
import { IssueCreateTypeModel } from '../issue-models/issuecreate.model';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from './date.adapter';
import { IssueStatusModel } from '../issue-models/issuestatus.model';
import { IssueResolutionModel } from '../issue-models/issueresolution.model';
import { IssueSearchTypeModel } from '../issue-models/issuesearch.model';
import { GroupModel } from '../../../models/user-models/group.model';
import { UserModel } from '../../../models/user-models/user.model';
import { Customer } from '../../../models/customermodels/customer.model';
import { ProjectListModel } from '../../../models/project-models/projectlist.model';
import { AppService } from '../../../services/app.service';
import { ProjectService } from '../../../services/project.service';
import { IssueService } from '../../../services/issue.service';
import { CommonService } from '../../../services/common.service';



@Component({
    selector: 'fury-issue-search',
    templateUrl: './issue-search.component.html',
    styleUrls: ['./issue-search.component.scss'],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})
export class IssueSearchComponent implements OnInit {
    static id = 0;
    form: FormGroup;

    groupsModel: GroupModel[];
    selectedGroup: number;
    userModel: UserModel[];
    selectedUser: number;
    assigningUser: number;
    categoryModel: CategoryModel[];
    selectedCategory: number;
    issueTypeModel: IssueTypeModel[];
    selectedIssueType: number;

    issueStatusModel: IssueStatusModel[];
    selectedissueStatusModel: number[];

    issueResolutionModel: IssueResolutionModel[];
    selectedResolutionType: number;
    selectedResolutionTypeModel: number[];

    selectedIssueId: number;
    selectedCompanyIdReference: string;
    selectedProjectId: number;
    selectedIssueTitle: string;
    selectedStartDate: Date;
    selectedEndDate: Date;

    customerTypeModel: Customer[];
    filteredcustomerTypeModel: Customer[];
    selectedCustomerData: number;
    selectedCustomer: string;

    projectTypeModel: ProjectListModel[];
    filteredprojectTypeModel: ProjectListModel[];
    selectedProjectData: number;
    selectedProject: string;

    selectedFile: any;
    fileToUpload: any;

    normal: boolean;
    urgent: boolean;
    critical: boolean;

    onlyOverDue: boolean;

    _listFilter: string;
    _projectlistFilter: string;

    customFiltereduserModel: UserModel[];
    customFilterSelectedUserData: number;
    customFilterSelectedUser: string;
    _userListFilter: string;

    customFilteredAssignedUserModel: UserModel[];
    customFilterSelectedAssignedUserData: number;
    customFilterSelectedAssignedUser: string;
    _userListFilter2: string;

    get userListFilter(): string {
        return this._userListFilter;
    }
    set userListFilter(value: string) {
        this._userListFilter = value;
        this.customFiltereduserModel = this.userListFilter ? this.userformFilter(this.userListFilter) : this.userModel;
    }

    get userListFilter2(): string {
        return this._userListFilter2;
    }

    set userListFilter2(value: string) {
        this._userListFilter2 = value;
        this.customFilteredAssignedUserModel = this.userListFilter2 ? this.assignedUserformFilter(this.userListFilter2) : this.userModel;
    }

    get listFilter(): string {
        return this._listFilter;
    }

    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredcustomerTypeModel = this.listFilter ? this.performFilter(this.listFilter) : this.customerTypeModel;
    }

    get projectlistFilter(): string {
        return this._projectlistFilter;
    }

    set projectlistFilter(value: string) {
        this._projectlistFilter = value;
        this.filteredprojectTypeModel = this.projectlistFilter ? this.projectperformFilter(this.projectlistFilter) : this.projectTypeModel;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
        private dialogRef: MatDialogRef<IssueSearchComponent>,
        private fb: FormBuilder,
        private appService: AppService,
        private readonly projectService: ProjectService,
        private readonly issueService: IssueService,
        private commonService: CommonService) {
    }

    ngOnInit() {

        this.defaults = {} as IssueSearchTypeModel;

        this.form = this.fb.group({
            issueId: [this.defaults.issueId || ''],
            issueTitle: [this.defaults.issueTitle || ''],
            startDate: [this.defaults.startDate || ''],
            endDate: [this.defaults.endDate || ''],
            issueAssignedGroupId: [this.defaults.issueAssignedGroupId || ''],
            issueAssignedUserId: [this.defaults.issueAssignedUserId || ''],
            issueAssigningUserId: [this.defaults.issueAssigningUserId || ''],
            issueTypeId: [this.defaults.issueTypeId || ''],
            issueCategoryId: [this.defaults.issueCategoryId || ''],
            issueStatusId: [this.defaults.issueStatusId || ''],
            issueResolutionId: [this.defaults.issueResolutionId || ''],
            issueOnlyOverDue: [this.defaults.issueResolutionId || ''],
            issueCustomerId: [this.defaults.issueCustomerId || ''],
            companyIdReference: [this.defaults.companyIdReference || ''],
            issueProjectId: [this.defaults.issueProjectId || ''],
        });

        this.projectService.getallgroups()
            .subscribe(response => {
                if (response) {
                    this.groupsModel = response;
                }
                else {
                }
            });

        this.projectService.getuserlistsimple()
            .subscribe(response => {
                if (response) {
                    this.userModel = response;
                    this.customFiltereduserModel = response;
                    this.customFilteredAssignedUserModel = response;

                    if (this.customFilterSelectedUserData) {
                        this.userListFilter = (this.userModel.filter((type: UserModel) =>
                            (type.id === this.customFilterSelectedUserData)))[0].firstName;
                    }
                    else {
                        this.customFiltereduserModel = this.userModel;
                    }

                    if (this.customFilterSelectedAssignedUserData) {
                        this.userListFilter2 = (this.userModel.filter((type: UserModel) =>
                            (type.id === this.customFilterSelectedAssignedUserData)))[0].firstName;
                    }
                    else {
                        this.customFilteredAssignedUserModel = this.userModel;
                    }
                }
                else {
                }
            });

        this.issueService.getallcategories()
            .subscribe(response => {
                if (response) {
                    this.categoryModel = response;
                }
                else {
                }
            });

        this.issueService.getallissuetypes()
            .subscribe(response => {
                if (response) {
                    this.issueTypeModel = response;
                }
                else {
                }
            });

        this.issueService.getallissuestatus()
            .subscribe(response => {
                if (response) {
                    this.issueStatusModel = response;
                }
                else {
                }
            });

        this.issueService.getallissueresolutions()
            .subscribe(response => {
                if (response) {
                    this.issueResolutionModel = response;
                }
                else {
                }
            });

        this.projectService.getcustomerlist2()
            .subscribe(response => {
                if (response) {
                    this.customerTypeModel = response;
                    this.filteredcustomerTypeModel = response;

                    if (this.selectedCustomerData) {
                        this.listFilter = (this.customerTypeModel.filter((type: Customer) =>
                            (type.id === this.selectedCustomerData)))[0].customerName;
                    }

                }
                else {
                }
            });

        this.projectService.getprojectlistforauth()
            .subscribe(response => {
                if (response) {

                    this.projectTypeModel = response;
                    this.filteredprojectTypeModel = response;

                    if (this.selectedProjectData) {
                        this.projectlistFilter = (this.projectTypeModel.filter((type: ProjectListModel) =>
                            (type.id === this.selectedProjectData)))[0].projectCode;
                    }

                }
                else {
                }
            });

        this.getSearchCookie();
    }

    dateParse(data: any) {
        if (data !== undefined && data !== '') {
            const str = data.split('-');
            const year = Number(str[0]);
            const month = Number(str[1]) - 1;
            const date = Number(str[2]);
            return new Date(year, month, date);
        }
        else
            return undefined;
    }
    getSearchCookie() {
        if (localStorage.getItem('797&XYZ@L') !== null) {
            const searchDataCookie = JSON.parse(localStorage.getItem('797&XYZ@L')) as IssueSearchTypeModel;
            if (searchDataCookie) {
                this.selectedIssueId = searchDataCookie.issueId;
                this.selectedIssueTitle = searchDataCookie.issueTitle;
                this.selectedStartDate = this.dateParse(searchDataCookie.startDate);
                this.selectedEndDate = this.dateParse(searchDataCookie.endDate);
                this.selectedGroup = searchDataCookie.issueAssignedGroupId;
                this.customFilterSelectedUserData = searchDataCookie.issueAssigningUserId;
                this.customFilterSelectedAssignedUserData = searchDataCookie.issueAssignedUserId;
                this.selectedCategory = searchDataCookie.issueCategoryId;
                this.selectedResolutionTypeModel = searchDataCookie.issueResolutionId;
                this.selectedissueStatusModel = searchDataCookie.issueStatusId;
                this.selectedIssueType = searchDataCookie.issueTypeId;
                this.onlyOverDue = searchDataCookie.isOverDue;
                this.selectedCustomerData = searchDataCookie.issueCustomerId;
                this.selectedCompanyIdReference = searchDataCookie.companyIdReference;
                this.selectedProjectData = searchDataCookie.issueProjectId;


                if (searchDataCookie.issuePriorityId === 1) {
                    this.selectNormal();
                }

                if (searchDataCookie.issuePriorityId === 2) {
                    this.selectUrgent();
                }

                if (searchDataCookie.issuePriorityId === 3) {
                    this.selectCritical();
                }
            }
        }
    }

    select() {
        const issue = this.form.value;

        if ((this.form.controls.issueId.value === '') || (this.form.controls.issueId.value === undefined) || (this.form.controls.issueId.value === null)) {
            issue.issueId = undefined;
        }
        else {
            issue.issueId = this.form.controls.issueId.value;
        }

        //if ((this.form.controls.issueProjectId.value === '') || (this.form.controls.issueProjectId.value === undefined) || (this.form.controls.issueProjectId.value === null)) {
        //  issue.issueProjectId = undefined;
        //}
        //else {
        //  issue.issueProjectId = this.form.controls.issueProjectId.value;
        //}

        if ((this.form.controls.companyIdReference.value === '') || (this.form.controls.companyIdReference.value === undefined) || (this.form.controls.companyIdReference.value === null)) {
            issue.companyIdReference = undefined;
        }
        else {
            issue.companyIdReference = this.form.controls.companyIdReference.value;
        }


        if ((this.form.controls.issueTitle.value === '') || (this.form.controls.issueTitle.value === undefined) || (this.form.controls.issueTitle.value === null)) {
            issue.issueTitle = undefined;
        }
        else {
            issue.issueTitle = this.form.controls.issueTitle.value;
        }

    if ((this.form.controls.issueAssignedGroupId.value === undefined) || (this.form.controls.issueAssignedGroupId.value === undefined) || (this.form.controls.issueAssignedGroupId.value === null) ) {
      issue.issueAssignedGroupId = undefined;
    }
    else {
      issue.issueAssignedGroupId = this.form.controls.issueAssignedGroupId.value;
    }

        //if ((this.form.controls.issueAssignedUserId.value === undefined) || (this.form.controls.issueAssignedUserId.value === undefined) || (this.form.controls.issueAssignedUserId.value === null)) {
        //    issue.issueAssignedUserId = undefined;
        //}
        //else {
        //    issue.issueAssignedUserId = this.form.controls.issueAssignedUserId.value;
        //}

        //if ((this.form.controls.issueAssigningUserId.value === undefined) || (this.form.controls.issueAssigningUserId.value === undefined) || (this.form.controls.issueAssigningUserId.value === null)) {
        //    issue.issueAssigningUserId = undefined;
        //}
        //else {
        //    issue.issueAssigningUserId = this.form.controls.issueAssigningUserId.value;
        //}

        if ((this.form.controls.issueTypeId.value === undefined) || (this.form.controls.issueTypeId.value === undefined) || (this.form.controls.issueTypeId.value === null)) {
            issue.issueTypeId = undefined;
        }
        else {
            issue.issueTypeId = this.form.controls.issueTypeId.value;
        }

        const dataint: number[] = [0];
        if ((this.form.controls.issueStatusId.value === undefined) || (this.form.controls.issueStatusId.value === undefined) || (this.form.controls.issueStatusId.value === null)) {
            issue.issueStatusId = dataint;
        }
        else {
            issue.issueStatusId = this.form.controls.issueStatusId.value;
        }

        if ((this.form.controls.issueResolutionId.value === undefined) || (this.form.controls.issueResolutionId.value === undefined) || (this.form.controls.issueResolutionId.value === null)) {
            issue.issueResolutionId = dataint;
        }
        else {
            issue.issueResolutionId = this.form.controls.issueResolutionId.value;
        }

        if ((this.form.controls.issueCategoryId.value === undefined) || (this.form.controls.issueCategoryId.value === undefined) || (this.form.controls.issueCategoryId.value === null)) {
            issue.issueCategoryId = undefined;
        }
        else {
            issue.issueCategoryId = this.form.controls.issueCategoryId.value;
        }

        if ((this.form.controls.startDate.value === undefined) || (this.form.controls.startDate.value === undefined) || (this.form.controls.startDate.value === null)) {
            issue.startDate = undefined;
        }
        else {
            const deger: Date = this.form.controls.startDate.value;

            var mm = deger.getMonth() + 1;
            var dd = deger.getDate();
            var yyyy = deger.getFullYear();
            var formattedDate = yyyy + '-' + mm + '-' + dd;


            issue.startDate = formattedDate;// this.form.controls.startDate.value;
        }

        if ((this.form.controls.endDate.value === undefined) || (this.form.controls.endDate.value === undefined) || (this.form.controls.endDate.value === null)) {
            issue.endDate = undefined;
        }
        else {
            const deger: Date = this.form.controls.endDate.value;

            var mm = deger.getMonth() + 1;
            var dd = deger.getDate();
            var yyyy = deger.getFullYear();
            var formattedDate = yyyy + '-' + mm + '-' + dd;
            issue.endDate = formattedDate;//this.form.controls.endDate.value;
        }

        issue.issuePriorityId = undefined;
        issue.issueCustomerId = this.selectedCustomerData;
        issue.issueProjectId = this.selectedProjectData;
        issue.issueAssigningUserId = this.customFilterSelectedUserData;
        issue.issueAssignedUserId = this.customFilterSelectedAssignedUserData;

        if (this.normal) {
            issue.issuePriorityId = 1;
        }

        if (this.urgent) {
            issue.issuePriorityId = 2;
        }

        if (this.critical) {
            issue.issuePriorityId = 3;
        }

        if (this.onlyOverDue) {
            issue.isOverDue = true;
        }


        this.dialogRef.close(issue);
    }


    getUserList(groupid: number) {
        //Reset User
        this.selectedUser = undefined;
        this.assigningUser = undefined;

        this.projectService.getuserlistsimplebygroupid(groupid)
            .subscribe(response => {
                if (response) {
                    this.userModel = response;
                    this.customFiltereduserModel = response;
                    this.customFilteredAssignedUserModel = response;
                }
                else {

                }

            });
    }

    close() {
        this.dialogRef.close();
    }

    selectNormal() {
        this.normal = true;
        this.urgent = false;
        this.critical = false;
    }

    selectUrgent() {
        this.normal = false;
        this.urgent = true;
        this.critical = false;
    }

    selectCritical() {
        this.normal = false;
        this.urgent = false;
        this.critical = true;
    }

    clear() {
        this.selectedIssueId = undefined;
        this.selectedIssueTitle = undefined;
        this.selectedStartDate = undefined;
        this.selectedEndDate = undefined;
        this.selectedGroup = undefined;
        this.selectedUser = undefined;
        this.assigningUser = undefined;
        this.selectedCategory = undefined;
        this.selectedResolutionTypeModel = undefined;
        const dataint: number[] = [0];
        this.selectedissueStatusModel = undefined;
        this.selectedIssueType = undefined;
        this.normal = false;
        this.urgent = false;
        this.critical = false;
        this.selectedCustomerData = undefined;
        this.selectedCompanyIdReference = undefined;
        this.selectedProjectId = undefined;

        const issue = this.form.value;
        issue.issueId = undefined;
        issue.issueTitle = undefined;
        issue.issueAssignedGroupId = undefined;
        issue.issueAssignedUserId = undefined;
        issue.issueAssigningUserId = undefined;
        issue.issueTypeId = undefined;
        issue.issueStatusId = dataint;
        issue.issueResolutionId = dataint;
        issue.issueCategoryId = undefined;
        issue.startDate = undefined;
        issue.endDate = undefined;
        issue.issuePriorityId = undefined;
        issue.issueCustomerId = undefined;
        issue.companyIdReference = undefined;
        issue.issueProjectId = undefined;

        this.clearCookie();

        this.dialogRef.close(issue);
    }

    clearCookie() {
        localStorage.removeItem('797&XYZ@L');
    }

    performFilter(filterBy: string): Customer[] {
        try {
            //this.filterReset();
            const orjFilterBy = filterBy;
            filterBy = filterBy.toLocaleLowerCase();
            return this.customerTypeModel.filter((customer: Customer) =>
                (customer.customerName.toLocaleLowerCase().indexOf(filterBy) !== -1));
        }
        catch (e) {
            return this.customerTypeModel;
        }

    }

    userformFilter(filterBy: string): UserModel[] {
        try {
            //this.filterReset();
            const orjFilterBy = filterBy;
            filterBy = filterBy.toLocaleLowerCase();
            return this.userModel.filter((user: UserModel) =>
                (user.firstName.toLocaleLowerCase().indexOf(filterBy) !== -1));
        }
        catch (e) {
            return this.userModel;
        }
    }

    assignedUserformFilter(filterBy: string): UserModel[] {
        try {
            //this.filterReset();
            const orjFilterBy = filterBy;
            filterBy = filterBy.toLocaleLowerCase();
            return this.userModel.filter((user: UserModel) =>
                (user.firstName.toLocaleLowerCase().indexOf(filterBy) !== -1));
        }
        catch (e) {
            return this.userModel;
        }
    }

    projectperformFilter(filterBy: string): ProjectListModel[] {
        try {
            //this.filterReset();
            const orjFilterBy = filterBy;
            filterBy = filterBy.toLocaleLowerCase();
            return this.projectTypeModel.filter((project: ProjectListModel) =>
                (project.projectCode.toLocaleLowerCase().indexOf(filterBy) !== -1));
        }
        catch (e) {
            return this.projectTypeModel;
        }

    }

    selectCustomer(customer) {
        this.listFilter = customer.customerName;
        this.selectedCustomerData = customer.id;
        this.selectedCustomer = customer.customerName;
    }

    customerReset() {
        this.selectedCustomerData = undefined;
        this.selectedCustomer = undefined;
        this.listFilter = undefined;
    }

    selectUser(user) {
        this.userListFilter = user.firstName;
        this.customFilterSelectedUserData = user.id;
        this.customFilterSelectedUser = user.firstName;
    }

    userReset() {
        this.userListFilter = undefined;
        this.customFilterSelectedUserData = undefined;
        this.customFilterSelectedUser = undefined;
    }

    selectAssignedUser(user) {
        this.userListFilter2 = user.firstName;
        this.customFilterSelectedAssignedUserData = user.id;
        this.customFilterSelectedAssignedUser = user.firstName;
    }

    userAssignedReset() {
        this.userListFilter2 = undefined;
        this.customFilterSelectedAssignedUserData = undefined;
        this.customFilterSelectedAssignedUser = undefined;
    }

    filterReset() {
        this.selectedCustomerData = undefined;
        this.selectedCustomer = undefined;
        this.customFilterSelectedUserData = undefined;
        this.customFilterSelectedUser = undefined;
        this.customFilterSelectedAssignedUserData = undefined;
        this.customFilterSelectedAssignedUser = undefined;
    }

    selectProject(project) {
        this.projectlistFilter = project.projectCode;
        this.selectedProjectData = project.id;
        this.selectedProject = project.projectCode;

        this.selectedUser = undefined;
        this.selectedGroup = undefined;
        this.assigningUser = undefined

        this.getuserGroupsForProject(project);
    }

    projectReset() {
        this.selectedProjectData = undefined;
        this.selectedProject = undefined;
        this.projectlistFilter = undefined;

        this.selectedUser = undefined;
        this.selectedGroup = undefined;
        this.assigningUser = undefined;
        this.getUserAndGroups();
    }

    projectfilterReset() {
        this.selectedProjectData = undefined;
        this.selectedProject = undefined;

        this.selectedUser = undefined;
        this.selectedGroup = undefined;
        this.assigningUser = undefined;
        this.getUserAndGroups();
    }

    getuserGroupsForProject(project) {
        this.projectService.getallgroupsforproject(project)
            .subscribe(response => {
                if (response) {
                    this.groupsModel = response;
                }
                else {
                }
            });


        this.projectService.getallusersforproject(project)
            .subscribe(response => {
                if (response) {
                    this.userModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));

                    this.customFiltereduserModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));

                    this.customFilteredAssignedUserModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));
                }
                else {
                }
            });

    }

    getUserAndGroups() {
        this.projectService.getallgroups()
            .subscribe(response => {
                if (response) {
                    this.groupsModel = response;
                }
                else {
                }
            });


        this.projectService.getuserlistsimple()
            .subscribe(response => {
                if (response) {
                    this.userModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));

                    this.customFiltereduserModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));

                    this.customFilteredAssignedUserModel = response.filter((type: UserModel) =>
                        (type.isEnabled === true));
                }
                else {
                }
            });
    }

}
