import { ChangeDetectorRef, Component, OnInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { RegisterModel } from '../../models/authentication.model';
import { AccountService } from '../../services/account.service';
import { AppService } from '../../services';


@Component({
  selector: 'flowi-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent implements OnInit {

  
  constructor(private router: Router,
              private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private readonly accountService: AccountService,
      private injector: Injector,
    private appService: AppService
  ) { }

  ngOnInit() {
  
  }

}
