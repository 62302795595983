import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NotFoundComponent } from './pages/notfound/notfound.component';


const routes: Routes = [
    { path: 'signalr', loadChildren: () => import('./+examples/examples/signalr/signalr.module').then(m => m.SignalrModule), data: { displayText: 'SignalR' } },
    {
        path: 'login',
        loadChildren: './pages/authentication/login/login.module#LoginModule',
    },
    {
        path: 'login2',
        loadChildren: './pages/authentication/login2/login2.module#Login2Module',
    },
    {
        path: 'forgot-password',
        loadChildren: './pages/authentication/forgot-password/forgot-password.module#ForgotPasswordModule',
    },
    {
        path: 'reset-password',
        loadChildren: './pages/authentication/reset-password/reset-password.module#ResetPasswordModule',
    },
    {
        path: 'set-password',
        loadChildren: './pages/authentication/set-password/set-password.module#SetPasswordModule',
    },
    {
        path: 'set-password/:id',
        loadChildren: './pages/authentication/set-password/set-password.module#SetPasswordModule',
    },
    {
        path: 'confirm-email',
        loadChildren: './pages/authentication/confirm-email/confirm-email.module#ConfirmEmailModule',
    },
    {
        path: 'register',
        loadChildren: './pages/authentication/register/register.module#RegisterModule',
    },
    {
        path: 'coming-soon',
        loadChildren: './pages/coming-soon/coming-soon.module#ComingSoonModule',
    },
    {
        path: 'public-support/:id',
        loadChildren: './pages/support/public-support-entry/public-support-entry.module#PublicSupportEntryModule',
    },
    {
        path: 'customer-support/:id',
        loadChildren: './pages/support/customer-support-entry/customer-support-entry.module#CustomerSupportEntryModule',
    },
    {
        path: '',
        component: LayoutComponent,
        children: [

            {
                path: 'company',
                loadChildren: './pages/company-pages/company/company-all-in-one-table.module#CompanyModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'issuelist',
                //loadChildren: './pages/company-pages/company/company-all-in-one-table.module#CompanyModule',
                loadChildren: './pages/issue/issue-list/issue-list.module#IssueListModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'issuedetail/:id',
                loadChildren: './pages/issue/issue-detail/issue-detail.module#IssueDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'issuedetail',
                loadChildren: './pages/issue/issue-detail/issue-detail.module#IssueDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'profile',
                loadChildren: './pages/profile/profile.module#ProfileModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'notificationsettings',
                loadChildren: './pages/notificationsettings/notificationsettings.module#NotificationsettingsModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'update-password',
                loadChildren: './pages/authentication/update-password/update-password.module#UpdatePasswordModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'users',
                loadChildren: './pages/users/users.module#UsersModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'usergroups',
                loadChildren: './pages/usergroups/usergroups.module#UsersGroupsModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/customer-issue-list',
                loadChildren: './pages/support/customer-issue/customer-issue.module#CustomerIssueModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/customer-issue-detail/:id',
                loadChildren: './pages/support/customer-issue/customer-issue-detail/customer-issue-detail.module#CustomerIssueDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/public-issue-list',
                loadChildren: './pages/support/public-issue/public-issue.module#PublicIssueModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/public-issue-detail/:id',
                loadChildren: './pages/support/public-issue/public-issue-detail/public-issue-detail.module#PublicIssueDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/formsettings',
                loadChildren: './pages/support/support-form-setting/support-form-setting.module#SupportFormSettingModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/supporttype',
                loadChildren: './pages/support/support-type/support-type.module#SupportTypeModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'support/publicsupportlink',
                loadChildren: './pages/support/public-support-manage/public-support-manage.module#PublicSupportManageModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'customerlist',
                loadChildren: './pages/customer/customer.module#CustomerModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'customerdetail/:id',
                loadChildren: './pages/customer/customerdetail/customer-detail.module#CustomerDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'customerdetail',
                loadChildren: './pages/customer/customerdetail/customer-detail.module#CustomerDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'projectlist',
                loadChildren: './pages/projectmodule/projectlist.module#ProjectListModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'projectdetail/:id',
                loadChildren: './pages/projectmodule/project-detail/project-detail.module#ProjectDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'projectdetail',
                loadChildren: './pages/projectmodule/project-detail/project-detail.module#ProjectDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'workingtime',
                loadChildren: './pages/reports/workingtime/working-time.module#WorkingTimeModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'projectissuereport',
                loadChildren: './pages/reports/issuereports/issue-project.module#IssueProjectModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'projectissuesummaryreport',
                loadChildren: './pages/reports/issuereportsummary/issue-project-summary.module#IssueProjectSummaryModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'assignedissuesummaryreport',
                loadChildren: './pages/reports/issuereportassignedsummary/issue-assigned-summary.module#IssueAssignedSummaryModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'assigneduserissuesummaryreport',
                loadChildren: './pages/reports/issuereportassignedusersummary/issue-assigned-user-summary.module#IssueAssignedUserSummaryModule',
                canActivate: [AuthGuardService]
            },

            //dok�man
            {
                path: 'documentcategory',
                loadChildren: './pages/documents/category/document-category.module#DocumentCategoryModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'documentdirectory',
                loadChildren: './pages/documents/directory/document-directory.module#DocumentDirectoryModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'documentsettings',
                loadChildren: './pages/documents/settings/document-settings.module#DocumentSettingsModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'directorylist',
                loadChildren: './pages/documents/directorylist/directorylist.module#DirectoryListModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'documentlist/:searchType/:documentDirectory/:documentCategory/:documentName',
                loadChildren: './pages/documents/documentlist/documentlist.module#DocumentListModule',
                canActivate: [AuthGuardService]
            },

            //dok�man 

            {
                path: 'documentdirectorydetail/:id',
                loadChildren: './pages/documents/directory/document-directory-detail/document-directory-detail.module#DocumentDirectoryDetailModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'documentdirectorydetail',
                loadChildren: './pages/documents/directory/document-directory-detail/document-directory-detail.module#DocumentDirectoryDetailModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'settings',
                loadChildren: './pages/projectsettings/projectsettings.module#ProjectSettingsModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'aboutme',
                loadChildren: './pages/banaozel/banaozel.module#BanaOzelModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'dashboard',
                loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
                canActivate: [AuthGuardService]
            },
            {
                path: '',
                pathMatch: 'full',
                loadChildren: './pages/issue/issue-list/issue-list.module#IssueListModule',
                canActivate: [AuthGuardService]
            },

            {
                path: 'apps/inbox',
                loadChildren: './pages/apps/inbox/inbox.module#InboxModule',
            },
            {
                path: 'apps/calendar',
                loadChildren: './pages/apps/calendar/calendar.module#CalendarAppModule',
            },
            {
                path: 'apps/chat',
                loadChildren: './pages/apps/chat/chat.module#ChatModule',
            },
            {
                path: 'components',
                loadChildren: './pages/components/components.module#ComponentsModule',
            },
            {
                path: 'forms/form-elements',
                loadChildren: './pages/forms/form-elements/form-elements.module#FormElementsModule',
            },
            {
                path: 'forms/form-wizard',
                loadChildren: './pages/forms/form-wizard/form-wizard.module#FormWizardModule',
            },
            {
                path: 'icons',
                loadChildren: './pages/icons/icons.module#IconsModule',
            },
            {
                path: 'page-layouts',
                loadChildren: './pages/page-layouts/page-layouts.module#PageLayoutsModule',
            },
            {
                path: 'maps/google-maps',
                loadChildren: './pages/maps/google-maps/google-maps.module#GoogleMapsModule',
            },
            {
                path: 'tables/all-in-one-table',
                loadChildren: './pages/tables/all-in-one-table/all-in-one-table.module#AllInOneTableModule',
            },
            {
                path: 'drag-and-drop',
                loadChildren: './pages/drag-and-drop/drag-and-drop.module#DragAndDropModule'
            },
            {
                path: 'editor',
                loadChildren: './pages/editor/editor.module#EditorModule',
            },
            {
                path: 'blank',
                loadChildren: './pages/blank/blank.module#BlankModule',
            },
            {
                path: 'level1/level2/level3/level4/level5',
                loadChildren: './pages/level5/level5.module#Level5Module',
            },
        ]
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
