import { Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { TokenService } from "./token.service";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { ProjectListModel } from "../models/project-models/projectlist.model";
import { ProjectMember } from "../models/project-models/projectmember.model";
import { Company_ } from "../models/company_.model";
import { ZoneModel } from "../models/zone.model";
import { UserModel } from "../models/user-models/user.model";
import { RoleModel } from "../models/user-models/role.model";
import { GroupModel } from "../models/user-models/group.model";
import { CustomerIssueModel } from "../models/support-models/customerissue.model";
import { Customer } from "../models/customermodels/customer.model";
import { CustomerMember } from "../models/customermodels/customer-member.model";
import { FormSetting } from "../models/support-models/formsetting.model";
import { SupportType } from "../models/support-models/supporttype.model";
import { PublicSupportEntry } from "../models/support-models/publicsupportentry.model";
import { SupportPublicLink } from "../models/support-models/supportpubliclink.model";
import { SupportCustomerLink } from "../models/support-models/supportcustomerlink.model";
import { CustomerSupportEntry } from "../models/support-models/customersupportentry.model";
import { PublicIssueModel } from "../models/support-models/publicissue.model";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { NotificationSettingsModel } from "../pages/notificationsettings/model/notificationsettings.model";

@Injectable()
export class ProjectService {

  constructor(private http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    public dataService: DataService,
    private inj: Injector) {

  }

  public getprojectlist() {
      const REGISTER_URL = `/api/project/projectlistforauth`;
      return this.dataService.get<ProjectListModel[]>(REGISTER_URL);
    }

    public getprojectlistfull() {
        const REGISTER_URL = `/api/project/projectlistfull`;
        return this.dataService.get<ProjectListModel[]>(REGISTER_URL);
    }

    public getprojectlistforauth() {
        const REGISTER_URL = `/api/project/projectlistforauth`;
        return this.dataService.get<ProjectListModel[]>(REGISTER_URL);
    }

  public addproject(project): Observable<ProjectListModel> {
    const REGISTER_URL = `/api/project/AddProject`;
    return this.dataService.post<ProjectListModel>(REGISTER_URL, project);
  }

  public updateproject(project) {
    const REGISTER_URL = `/api/project/AddProject`;
      return this.dataService.post(REGISTER_URL, project).pipe(
          map((res) => {
              return true;
          }));

  }

  public getprojectdetail(project): Observable<ProjectListModel> {
    const REGISTER_URL = `/api/project/ProjectDetail`;
    return this.dataService.post<ProjectListModel>(REGISTER_URL, project);
  }

  public getprojectallmember(project): Observable<ProjectMember> {
    const REGISTER_URL = `/api/project/ProjectAllMember`;
    return this.dataService.post<ProjectMember>(REGISTER_URL, project);
  }


  public addupdateprojectmember(projectmember) {
    const REGISTER_URL = `/api/project/AddUpdateProjectMember`;
      return this.dataService.post(REGISTER_URL, projectmember).pipe(
          map((res) => {
              return true;
          }));
  }

  public deleteproject(project) {
    const REGISTER_URL = `/api/project/DeleteProject`;
      return this.dataService.post(REGISTER_URL, project).pipe(
          map((res) => {
              return true;
          }));

  }



  public addcompany(company): Observable<Company_> {
    const REGISTER_URL = `/api/project/AddCompany`;
    return this.dataService.post<Company_>(REGISTER_URL, company);
  }

  public updatecompany(company) {
    const REGISTER_URL = `/api/project/AddCompany`;
      return this.dataService.post(REGISTER_URL, company).pipe(
          map((res) => {
              return true;
          }));

  }

  public deletecompany(company) {
    const REGISTER_URL = `/api/project/DeleteCompany`;
      return this.dataService.post(REGISTER_URL, company).pipe(
          map((res) => {
              return true;
          }));

  }

  public getcompanylist() {
    const REGISTER_URL = `/api/project/CompanyList`;
    return this.dataService.get<Company_[]>(REGISTER_URL);
  }

  public getzonelist() {
    const REGISTER_URL = `/api/project/ZoneList`;
    return this.dataService.get<ZoneModel[]>(REGISTER_URL);
  }

  public getuserlist() {
    const REGISTER_URL = `/api/project/UserList`;
    return this.dataService.get<UserModel[]>(REGISTER_URL);
  }

  public getuserlist2() {
    const REGISTER_URL = `/api/project/UserList2`;
    return this.dataService.get<UserModel[]>(REGISTER_URL);
  }

  public getuserlistsimple() {
    const REGISTER_URL = `/api/project/UserListSimple`;
    return this.dataService.get<UserModel[]>(REGISTER_URL);
  }

  public userlistsimpleforuserdetail() {
      const REGISTER_URL = `/api/project/UserListSimpleForUserDetail`;
        return this.dataService.get<UserModel[]>(REGISTER_URL);
   }

  public getuserinlistsimple() {
    const REGISTER_URL = `/api/project/UserInListSimple`;
    return this.dataService.get<UserModel[]>(REGISTER_URL);
  }

  public getuserlistsimpleforcustomer() {
    const REGISTER_URL = `/api/project/UserListSimpleForCustomer`;
    return this.dataService.get<UserModel[]>(REGISTER_URL);
  }


  public getuserlistsimplebygroupid(group) {
    const REGISTER_URL = `/api/project/UserListSimpleByGroupId`;
    return this.dataService.post<UserModel[]>(REGISTER_URL, group);
  }

  public getuserprofile() {
    const REGISTER_URL = `/api/project/getuserprofile`;
    return this.dataService.get<UserModel>(REGISTER_URL);
  }

  public getrolelist() {
    const REGISTER_URL = `/api/project/RoleList`;
    return this.dataService.get<string[]>(REGISTER_URL);
  }

  public getallroles() {
    const REGISTER_URL = `/api/project/GetAllRoles`;
    return this.dataService.get<RoleModel[]>(REGISTER_URL);
  }

  public getuserrole(user): Observable<RoleModel> {
    const REGISTER_URL = `/api/project/GetUserRole`;
    return this.dataService.get<RoleModel>(REGISTER_URL, user);
  }

  public adduser(user): Observable<UserModel> {
    const REGISTER_URL = `/api/project/AddUser`;
    return this.dataService.post<UserModel>(REGISTER_URL, user);
  }

  public updateuser(user): Observable<UserModel> {
    const REGISTER_URL = `/api/project/AddUser`;
    return this.dataService.post<UserModel>(REGISTER_URL, user);
  }

  public deleteuser(user) {
    const REGISTER_URL = `/api/project/DeleteUser`;
      return this.dataService.post(REGISTER_URL, user).pipe(
          map((res) => {
              return true;
          }));

  }

  public getallgroups() {
    const REGISTER_URL = `/api/project/GetAllGroups`;
    return this.dataService.get<GroupModel[]>(REGISTER_URL);
  }

  public getallgroupsforproject(project) {
    const REGISTER_URL = `/api/project/GetAllGroupsForProject`;
    return this.dataService.post<GroupModel[]>(REGISTER_URL,project);
  }

  public getallusersforproject(project) {
    const REGISTER_URL = `/api/project/GetAllUsersForProject`;
    return this.dataService.post<UserModel[]>(REGISTER_URL, project);
  }

  public getallgroupswithusers() {
    const REGISTER_URL = `/api/project/GetAllGroupsWithUsers`;
    return this.dataService.get<GroupModel[]>(REGISTER_URL);
  }

  public uploadprofilephoto(file) {
    const REGISTER_URL = `/api/project/UploadPhoto`;
      return this.dataService.post(REGISTER_URL, file).pipe(
          map((res) => {
              return true;
          }));
  }

  public getImage(): Observable<any> {
    const PHOTO_URL = `/api/project/GetPhoto`;
    return Observable.create((observer: any) => {
      const req = new XMLHttpRequest();
      req.open('get', PHOTO_URL);
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          observer.next(req.response);
          observer.complete();
        }
      };
      req.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      req.send();
    });
  }

  public updateprofile(user) {
    const REGISTER_URL = `/api/project/updateprofile`;
      return this.dataService.post(REGISTER_URL, user).pipe(
          map((res) => {
              return true;
          }));
  }

  public addgroup(group): Observable<GroupModel> {
    const REGISTER_URL = `/api/project/AddGroup`;
    return this.dataService.post<GroupModel>(REGISTER_URL, group);
  }

  public updategroup(group): Observable<GroupModel> {
    const REGISTER_URL = `/api/project/AddGroup`;
    return this.dataService.post<GroupModel>(REGISTER_URL, group);
  }

  public deletegroup(group) {
    const REGISTER_URL = `/api/project/DeleteGroup`;
      return this.dataService.post(REGISTER_URL, group).pipe(
          map((res) => {
              return true;
          }));

  }


  public addcustomer(customer): Observable<Customer> {
    const REGISTER_URL = `/api/customer/AddCustomer`;
    return this.dataService.post<Customer>(REGISTER_URL, customer);
  }

  public updatecustomer(customer) {
    const REGISTER_URL = `/api/customer/AddCustomer`;
      return this.dataService.post(REGISTER_URL, customer).pipe(
          map((res) => {
              return true;
          }));

  }

  public deletecustomer(company) {
    const REGISTER_URL = `/api/customer/DeleteCustomer`;
      return this.dataService.post(REGISTER_URL, company).pipe(
          map((res) => {
              return true;
          }));

  }

  public getcustomerlist() {
    const REGISTER_URL = `/api/customer/CustomerList`;
    return this.dataService.get<Customer[]>(REGISTER_URL);
  }

  public getcustomerlist2(): Observable<Customer[]>{
    const REGISTER_URL = `/api/customer/CustomerList`;
    return this.dataService.get<Customer[]>(REGISTER_URL);
  }

  public getcustomerdetail(customer): Observable<Customer> {
    const REGISTER_URL = `/api/customer/CustomerDetail`;
    return this.dataService.post<Customer>(REGISTER_URL, customer);
  }

  public addmember(member): Observable<CustomerMember> {
    const REGISTER_URL = `/api/customer/AddCustomerMember`;
    return this.dataService.post<CustomerMember>(REGISTER_URL, member);
  }

  public getmemberlist(customer) {
    const REGISTER_URL = `/api/customer/CustomerMemberList`;
    return this.dataService.post<CustomerMember[]>(REGISTER_URL, customer);
  }

  public deletemember(member) {
    const REGISTER_URL = `/api/customer/DeleteCustomerMember`;
      return this.dataService.post(REGISTER_URL, member).pipe(
          map((res) => {
              return true;
          }));
  }

  public getsupportsettings() {
    const REGISTER_URL = `/api/customer/getsupportformsetting`;
    return this.dataService.get<FormSetting>(REGISTER_URL);
  }

  public getsupportsettingsnonesecure(data) {
    const REGISTER_URL = `/api/customer/getsupportformsettingnonesecure`;
    return this.dataService.post<FormSetting>(REGISTER_URL, data);
  }

  public getcustomersupportformsettingnonesecure(data) {
    const REGISTER_URL = `/api/customer/getcustomersupportformsettingnonesecure`;
    return this.dataService.post<FormSetting>(REGISTER_URL, data);
  }

  public getsupportlogo(): Observable<any> {
    const PHOTO_URL = `/api/customer/getlogophoto`;
    return Observable.create((observer: any) => {
      const req = new XMLHttpRequest();
      req.open('get', PHOTO_URL);
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          observer.next(req.response);
          observer.complete();
        }
      };
      req.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      req.send();
    });
  }

  public getsupportlogononesecure(data): Observable<any> {
    const PHOTO_URL = `/api/customer/getlogophotononesecure`;
    return Observable.create((observer: any) => {
      const req = new XMLHttpRequest();
      req.open('post', PHOTO_URL );
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          observer.next(req.response);
          observer.complete();
        }
      };
      req.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      req.send(JSON.stringify(data));
    });
  }

  public getsupportlogocustomernonesecure(data): Observable<any> {
    const PHOTO_URL = `/api/customer/getlogophotocustomernonesecure`;
    return Observable.create((observer: any) => {
      const req = new XMLHttpRequest();
      req.open('post', PHOTO_URL);
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          observer.next(req.response);
          observer.complete();
        }
      };
      req.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      req.send(JSON.stringify(data));
    });
  }

  public updatesupportformsetting(user) {
    const REGISTER_URL = `/api/customer/updatesupportformsetting`;
      return this.dataService.post(REGISTER_URL, user).pipe(
          map((res) => {
              return true;
          }));
  }

  public uploadlogophoto(file) {
    const REGISTER_URL = `/api/customer/uploadlogophoto`;
      return this.dataService.post(REGISTER_URL, file).pipe(
          map((res) => {
              return true;
          }));
  }


  public getallsupporttypes() {
    const REGISTER_URL = `/api/customer/supporttypelist`;
    return this.dataService.get<SupportType[]>(REGISTER_URL);
  }

  public getallsupporttypes2(): Observable<SupportType[]> {
    const REGISTER_URL = `/api/customer/supporttypelist`;
    return this.dataService.get<SupportType[]>(REGISTER_URL);
  }

  public getallsupporttypesnonesecure(data): Observable<SupportType[]> {
    const REGISTER_URL = `/api/customer/supporttypelistnonesecure`;
    return this.dataService.post<SupportType[]>(REGISTER_URL,data);
  }

  public getallsupporttypescustomernonesecure(data): Observable<SupportType[]> {
    const REGISTER_URL = `/api/customer/supporttypelistcustomernonesecure`;
    return this.dataService.post<SupportType[]>(REGISTER_URL, data);
  }

  

  public getcustomermembersnonesecure(data): Observable<CustomerMember[]> {
    const REGISTER_URL = `/api/customer/getcustomermembersnonesecure`;
    return this.dataService.post<CustomerMember[]>(REGISTER_URL, data);
  }

  public updatesupporttype(supporttype) {
    const REGISTER_URL = `/api/customer/AddSupportType`;
      return this.dataService.post(REGISTER_URL, supporttype).pipe(
          map((res) => {
              return true;
          }));
  }

  public deletesupporttype(issuetype) {
    const REGISTER_URL = `/api/customer/DeleteSupportType`;
      return this.dataService.post(REGISTER_URL, issuetype).pipe(
          map((res) => {
              return true;
          }));
  }

  public supportpubliclink(): Observable<SupportPublicLink> {
    const REGISTER_URL = `/api/customer/supportpubliclink`;
    return this.dataService.get<SupportPublicLink>(REGISTER_URL);
  }

  public supportpubliclinknonesecure(data): Observable<SupportPublicLink> {
    const REGISTER_URL = `/api/customer/supportpubliclinknonesecure`;
    return this.dataService.post<SupportPublicLink>(REGISTER_URL,data);
  }

  public updatesupportpubliclink(publiclink): Observable<SupportPublicLink> {
    const REGISTER_URL = `/api/customer/AddSupportPublicLink`;
    return this.dataService.post<SupportPublicLink>(REGISTER_URL, publiclink);
  }

  public supportcustomerlink(customer): Observable<SupportCustomerLink> {
    const REGISTER_URL = `/api/customer/supportcustomerlink`;
    return this.dataService.post<SupportCustomerLink>(REGISTER_URL, customer);
  }

  public updatesupportcustomerlink(customerlink): Observable<SupportCustomerLink> {
    const REGISTER_URL = `/api/customer/AddSupportCustomerLink`;
    return this.dataService.post<SupportCustomerLink>(REGISTER_URL, customerlink);
  }

  public addpublicsupportentry(publicsupport): Observable<PublicSupportEntry> {
    const REGISTER_URL = `/api/customer/addpublicsupportentry`;
    return this.dataService.post<PublicSupportEntry>(REGISTER_URL, publicsupport);
  }

  public updatepublicsupportissue(publicsupport): Observable<PublicSupportEntry> {
    const REGISTER_URL = `/api/customer/UpdatePublicSupportIssue`;
    return this.dataService.post<PublicSupportEntry>(REGISTER_URL, publicsupport);
  }

  public updatecustomersupportissue(publicsupport): Observable<CustomerSupportEntry> {
    const REGISTER_URL = `/api/customer/UpdateCustomerSupportIssue`;
    return this.dataService.post<CustomerSupportEntry>(REGISTER_URL, publicsupport);
  }

  public addcustomersupportentry(customersupport): Observable<CustomerSupportEntry> {
    const REGISTER_URL = `/api/customer/addcustomersupportentry`;
    return this.dataService.post<CustomerSupportEntry>(REGISTER_URL, customersupport);
  }


  public getpublicissuelist(searchParam) {
    const REGISTER_URL = `/api/customer/GetPublicSupportList`;
    return this.dataService.post<PublicIssueModel[]>(REGISTER_URL, searchParam);
  }

  public getpublicissuedetail(searchParam): Observable<PublicIssueModel> {
    const REGISTER_URL = `/api/customer/GetPublicSupportDetail`;
    return this.dataService.post<PublicIssueModel>(REGISTER_URL, searchParam);
  }

  public getcustomerissuedetail(searchParam): Observable<CustomerIssueModel> {
    const REGISTER_URL = `/api/customer/GetCustomerSupportDetail`;
    return this.dataService.post<CustomerIssueModel>(REGISTER_URL, searchParam);
  }


  public getpublicissueexcel(model: PublicIssueModel): Observable<any> {
    const REGISTER_URL = `/api/customer/GetPublicIssueExcel`;
    return new Observable(obs => {
      var oReq = new XMLHttpRequest();
      oReq.open("POST", REGISTER_URL, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      oReq.send(body);
    });
  }

  public getcustomerissueexcel(model: CustomerIssueModel): Observable<any> {
    const REGISTER_URL = `/api/customer/GetCustomerIssueExcel`;
    return new Observable(obs => {
      var oReq = new XMLHttpRequest();
      oReq.open("POST", REGISTER_URL, true);
      oReq.setRequestHeader("content-type", "application/json");
      oReq.setRequestHeader('Authorization', `Bearer ${this.inj.get(TokenService).get()}`);
      oReq.responseType = "arraybuffer";

      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response;
        var byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };

      const body = JSON.stringify(model);
      oReq.send(body);
    });
  }

  public getcustomerissuelist(searchParam) {
    const REGISTER_URL = `/api/customer/GetCustomerSupportList`;
    return this.dataService.post<CustomerIssueModel[]>(REGISTER_URL, searchParam);
    }

    public getnotificationsettings() {
        const REGISTER_URL = `/api/project/GetNotificationSettings`;
        return this.dataService.get<NotificationSettingsModel>(REGISTER_URL);
    }

    public setnotificationsettings(searchParam): Observable<NotificationSettingsModel> {
        const REGISTER_URL = `/api/project/SetNotificationSettings`;
        return this.dataService.post<NotificationSettingsModel>(REGISTER_URL, searchParam);
    }
}
