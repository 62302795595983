import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
    selector: 'app-form-input',
    styleUrls: ['form-input.component.scss'],
    templateUrl: 'form-input.component.html'
})
export class FormInputComponent extends FieldBaseComponent { }
