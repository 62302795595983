import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
    selector: 'app-form-date',
    styleUrls: ['form-date.component.scss'],
    templateUrl: 'form-date.component.html',
    providers: []
})
export class FormDateComponent extends FieldBaseComponent { }
