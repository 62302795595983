export class Companyy {

  id: number;
  applicationName: string;
  description: string;
  useOrganizationScheme: boolean;
  maxUserCount: number;
  zoneId: string;

  constructor(company) {
    this.id = company.id;
    this.applicationName = company.applicationName;
    this.description = company.description;
    this.useOrganizationScheme = company.useOrganizationScheme;
    this.maxUserCount = company.maxUserCount;
    this.zoneId = company.zoneId;
  }

}
