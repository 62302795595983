import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatDatepickerModule
} from '@angular/material';
import { IssueCreateComponent } from './issue-create.component';
import { AppSharedModule } from '../../../appshared';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { ComponentsModule } from '../../components/components.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    ComponentsModule,
 AppSharedModule
  ],
  declarations: [IssueCreateComponent],
  entryComponents: [IssueCreateComponent],
  exports: [IssueCreateComponent]
})
export class IssueCreateModule {
}
