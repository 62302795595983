import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { TokenService } from "./token.service";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { ConfirmEmailModel } from "../models/authentication.model";
import { Observable } from "rxjs/internal/Observable";
import 'rxjs/Rx';
import { map } from "rxjs/operators";

@Injectable()
export class AccountService {

  constructor(private http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    public dataService: DataService) {

  }

  public token: string = "";
  private tokenExpiration: Date;

  public get loginRequired(): boolean {
    return this.token.length == 0 || this.tokenExpiration > new Date();
  }

  public login(creds) {
    const LOGIN_URL = `/api/account/createtoken`;
    return this.dataService.post(LOGIN_URL, creds)
        .pipe(map((response: any) => {
        this.tokenService.set(response.token);
        this.token = response.token;
        this.tokenExpiration = response.expiration;
        localStorage.setItem('1ZXYL@M', response.roles);
        return true;
      }));
  }

  public register(creds) {
    const REGISTER_URL = `/api/account/register`;
      return this.dataService.post(REGISTER_URL, creds).pipe(
          map((res) => {
              return true;
          }));
  }

  public forgotpassword(creds) {
    const REGISTER_URL = `/api/account/ForgotPassword`;
      return this.dataService.post(REGISTER_URL, creds).pipe(
          map((res) => {
              return true;
          }));
  }

  public resetpassword(creds) {
    const REGISTER_URL = `/api/account/resetpassword`;
      return this.dataService.post(REGISTER_URL, creds).pipe(
          map((res) => {
              return true;
          }));
  }

  public updatepassword(creds) {
    const REGISTER_URL = `/api/account/changepassword`;
      return this.dataService.post(REGISTER_URL, creds).pipe(
          map((res) => {
              return true;
          }));
    }

    public updateuserpassword(creds) {
        const REGISTER_URL = `/api/account/changeuserpassword`;
      return this.dataService.post(REGISTER_URL, creds).pipe(
          map((res) => {
              return true;
          }));
  }

  public setpassword(creds) {
    const REGISTER_URL = `/api/account/setpassword`;
      return this.dataService.post(REGISTER_URL, creds).pipe(
          map((res) => {
              return true;
          }));
  }

  public confirmemail(creds): Observable<ConfirmEmailModel> {
    const REGISTER_URL = `/api/account/ConfirmEmail`;
    return this.dataService.post<ConfirmEmailModel>(REGISTER_URL, creds);
  }


  isAuthenticated(): boolean {
    return this.tokenService.exists();
  }

  logout() {

    if (this.isAuthenticated()) {
      const LOGOUT_URL = `/api/account/Logout`;
      this.dataService.post(LOGOUT_URL).subscribe();
    }
    sessionStorage.removeItem('8PXYZ@L');
    sessionStorage.removeItem('7LXYZ@L');

    this.tokenService.clear();
    this.router.navigate(["/login"]);
  }


}
