import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProjectService } from '../../../../services/project.service';
import { ZoneModel } from '../../../../models/zone.model';
import { Companyy } from '../../../../models/companyy.model';


@Component({
  selector: 'fury-company-create-update',
  templateUrl: './company-create-update.component.html',
  styleUrls: ['./company-create-update.component.scss']
})
export class CompanyCreateUpdateComponent implements OnInit {
  static id = 0;
  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  zonelist: ZoneModel[];
  selectedZone: string;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<CompanyCreateUpdateComponent>,
    private fb: FormBuilder,
    private readonly projectService: ProjectService) {
  }

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as Companyy;
    }

    this.form = this.fb.group({
      id: [CompanyCreateUpdateComponent.id],
      applicationName: [this.defaults.applicationName || '',],
      description: [this.defaults.description || ''],
      useOrganizationScheme: [this.defaults.useOrganizationScheme || ''],
      maxUserCount: [this.defaults.maxUserCount || ''],
      zoneId: [this.defaults.zoneId || ''],
    });

    this.getzonelist();

    if (this.mode === 'create') {
      this.selectedZone = "Turkey Standard Time";
    }
    else {
      this.selectedZone = this.defaults.zoneId;
    }
   
  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    customer.id = 0;

    if (customer.useOrganizationScheme) {
      customer.useOrganizationScheme = true;
    } else {
      customer.useOrganizationScheme = false;
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;
    customer.id = this.defaults.id;

    if (customer.useOrganizationScheme) {
      customer.useOrganizationScheme = true;
    } else {
      customer.useOrganizationScheme = false;
    }
    

    this.dialogRef.close(customer);
  }

  getzonelist() {
    this.projectService.getzonelist()
      .subscribe(response => {
        if (response) {
          this.zonelist = response;
        }

      });
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  close() {
    this.dialogRef.close(null);
  }
}
