import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatDatepickerModule
} from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IssueSearchComponent } from './issue-search.component';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { FormElementsModule } from '../../forms/form-elements/form-elements.module';
import { FormElementsRoutingModule } from '../../forms/form-elements/form-elements-routing.module';
import { HighlightModule } from '../../../../@fury/shared/highlightjs/highlight.module';
import { FuryCardModule } from '../../../../@fury/shared/card/card.module';
import { PageModule } from '../../../../@fury/shared/page/page.module';
import { AppSharedModule } from '../../../appshared';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    FormElementsModule,
    FormElementsRoutingModule,
    HighlightModule,
    FuryCardModule,
        PageModule,
        AppSharedModule
  ],
  declarations: [IssueSearchComponent],
  entryComponents: [IssueSearchComponent],
  exports: [IssueSearchComponent]
})
export class IssueSearchModule {
}
