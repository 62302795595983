import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fury-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

 

    @Input() current: string;
    @Input() one: string;
    @Input() crumbs: any[] = [];

    @Input() main: string;
    @Input() mainlink: string;

    constructor(private _router: Router) {
       
  }

    ngOnInit() {

    }

    navigate() {
        this._router.navigate([this.mainlink]);
    }

}
