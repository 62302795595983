import { Component, OnInit } from '@angular/core';
import { Theme, ThemeService } from '../../../@fury/services/theme.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SidenavService } from '../sidenav/sidenav.service';
import { map } from 'rxjs/operators';
import { ThemeConfigModel } from '../../models/themeconfig.model';

@Component({
  selector: 'fury-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent implements OnInit {

  activeTheme$ = this.themeService.activeTheme$;
  navigation$ = this.themeService.config$.pipe(map(config => config.navigation));
  sidenavOpen$ = this.sidenavService.open$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));
  footerVisible$ = this.themeService.config$.pipe(map(config => config.footerVisible));
  footerPosition$ = this.themeService.config$.pipe(map(config => config.footerPosition));


  _themeconfig: ThemeConfigModel;

  constructor(private themeService: ThemeService,
              private sidenavService: SidenavService) { }

    ngOnInit() {

        this.themeService.setSidenavUserVisible(false);

        if (localStorage.getItem('797&XYZ@ZZ') !== null) {
            const searchDataCookie = JSON.parse(localStorage.getItem('797&XYZ@ZZ')) as ThemeConfigModel;

            this.themeService.setTheme(searchDataCookie.theme);

            if (searchDataCookie.navigationside) {
                this.themeService.setNavigation('side');
            }
            else {
                this.themeService.setNavigation('top');
            }

            if (searchDataCookie.sidenavOpen) {
                this.sidenavService.open();
            }
            else {
                this.sidenavService.close();
            }

            this.sidenavService.setCollapsed(searchDataCookie.sidenavCollapsed);

            this.themeService.setToolbarPosition(searchDataCookie.toolbarPosition);

            this.themeService.setToolbarVisible(searchDataCookie.toolbarVisible);

            this._themeconfig = searchDataCookie;
        }
        else {
            const data: ThemeConfigModel = {
                navigationside: true,
                sidenavCollapsed: false,
                sidenavOpen: true,
                theme: 'fury-default',
                toolbarPosition: 'fixed',
                toolbarVisible: true
            };

            localStorage.setItem('797&XYZ@ZZ', JSON.stringify(data));

            this._themeconfig = data;
        }
  }

  setActiveTheme(theme: Theme) {
      this.themeService.setTheme(theme);
      this._themeconfig.theme = theme;
      localStorage.setItem('797&XYZ@ZZ', JSON.stringify(this._themeconfig));

      
  }

  navigationChange(change: MatRadioChange) {
      this.themeService.setNavigation(change.value);

     
      if (change.value === 'top') {
          this._themeconfig.navigationside = false;
      }
      else {
          this._themeconfig.navigationside = true;
      }
      
      localStorage.setItem('797&XYZ@ZZ', JSON.stringify(this._themeconfig));
  }

  sidenavOpenChange(change: MatSlideToggleChange) {
      change.checked ? this.sidenavService.open() : this.sidenavService.close();

      this._themeconfig.sidenavOpen = change.checked;
      localStorage.setItem('797&XYZ@ZZ', JSON.stringify(this._themeconfig));
  }

  sidenavCollapsedChange(change: MatCheckboxChange) {
      this.sidenavService.setCollapsed(change.checked);

      this._themeconfig.sidenavCollapsed = change.checked;
      localStorage.setItem('797&XYZ@ZZ', JSON.stringify(this._themeconfig));
  }

  sidenavUserChange(change: MatCheckboxChange) {
    this.themeService.setSidenavUserVisible(change.checked);
  }

  toolbarVisibleChange(change: MatSlideToggleChange) {
      this.themeService.setToolbarVisible(change.checked);

      this._themeconfig.toolbarVisible = change.checked;
      localStorage.setItem('797&XYZ@ZZ', JSON.stringify(this._themeconfig));
  }

  toolbarPositionChange(change: MatRadioChange) {
      this.themeService.setToolbarPosition(change.value);

      this._themeconfig.toolbarPosition = change.value;
      localStorage.setItem('797&XYZ@ZZ', JSON.stringify(this._themeconfig));
  }

  footerVisibleChange(change: MatSlideToggleChange) {
    this.themeService.setFooterVisible(change.checked);
  }

  footerPositionChange(change: MatRadioChange) {
    this.themeService.setFooterPosition(change.value);
  }
}
